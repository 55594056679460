export default {

    // COMMON
    "description": "Description",
    "cancel": "Annuler",
    "save": "Sauver",
    "search": "Recherche",
    "list": "Liste",
    "new": "Nouveau",
    "delete": "Supprimer",
    "title" : "Titre",
    "other_title" : "Autre titre / Dédicaces",
    "nb_archive": "Archive",
    "archive": "Archive",
    "date": "Date",
    "annee": "Année",
    "year": "Année",
    "creation_year": "Année de création",
    "date_debut" : "Date de début",
    "date_fin" : "Date de fin",
    "nom" : "Nom",
    "prenom" : "Prénom",
    "adresse" : "Adresse",
    "adresse_2" : "Adresse 2",
    "code_postal" : "Code postal",
    "ville" : "Ville",
    "pays" : "Pays",
    "phone" : "Téléphone",
    "email" : "Email",
    "prive" : "Privé",
    "size" : "Taille",
    "type" : "Type",
    "alerte_change_page" : "Vous n'avez pas sauvegardé les modifications. Etes-vous certain de vouloir quitter cette page ?",
    "add_artwork" : "Ajouter une oeuvre",
    "add_oeuvre" : "Ajouter une oeuvre",
    "add_expo" : "Ajouter une expo",
    "add_biblio" : "Ajouter une biblio",
    "add_media" : "Ajouter un media",
    "add_photo" : "Ajouter une photo",
    "add_dessin" : "Ajouter un dessin",
    "add_caisse": "Ajouter une caisse",
    "add_localisation": "Ajouter une localisation",
    "add_estimation": "Ajouter une estimation",
    "add_enchere": "Ajouter une enchère",
    "add_prix": "Ajouter un prix",
    "add_assurance" : "Ajouter une assurance",
    "add_exemplaire" : "Ajouter un exemplaire",

    "reserve": "Réservé",
    "back": "Retour",
    "annee_creation" : "Année de création",
    "modifier" : "Modifier",
    "changer" : "Changer",
    "changer_de_photo" : "Changer de photo",

    "remarques" : "Remarques",
    "observations" : "Observations",
    "copyright": "Copyright",
    "mots_clefs": "Mots clefs",
    "devise": "Devise",
    "valeur": "Valeur",
    "interlocuteur" : "Interlocuteur",
    "estimation_basse" : "Estimation basse",
    "estimation_haute" : "Estimation haute",

    "date_de_creation_fiche" : "Date de création fiche",
    "date_modification_fiche": "Dernière modification",
    "exemplaire": "Exemplaire",

    // ARTWORK
    "artwork": "Oeuvre",
    "artworks": "Oeuvres",
    "oeuvre": "Oeuvre",
    "oeuvres": "Oeuvres",
    "annee_de_realisation": "Réalisation",
    "type_doeuvre": "Type d'oeuvre",
    "familles": "Familles", 
    "couleurs": "Couleurs",
    "materiaux": "Matériaux",
    "elements": "Eléments",
    "nb_tes": "Nb de tes",
    "nb_varillas": "Nb de tiges",
    "nb_tacos": "Nb de tacos",
    "oeuvre non identifiee" : "Oeuvre non identifiée",
    "criteres": "Critères",
    "caracteristiques_techniques_au_montage": "Caractéristiques techniques au montage",
    "details_techniques": "Techniques",
    "contraintes_dinstallation": "Contraintes d'installation (dimensions, poids)",
    "materiel_et_equipes_necessaires": "Matériel et équipes nécessaires",
    "autres_informations_techniques": "Autres informations techniques",
    "caracteristiques_techniques": "Caractéristiques techniques",

    "largeur": "Largeur",
    "hauteur": "Hauteur",
    "profondeur": "Profondeur",
    "surface": "Surface",
    "diametre": "Diamètre",
    "poids": "Poids",
    "dimensions_HT": "Dimensions HT",
    "proprietaire_actuel": "Propriétaire actuel",
    "localisation_actuelle": "Localisation actuelle",
    "proprietaire": "Propriétaire",
    "derniere_localisation": "Localisation",
    "emplacement_actuel" : "Emplacement actuel",
    "multiple": "Multiple",
    "multiples": "Multiples",
    "galerie": "Galerie",
    "etat_administratif" : "Etat administratif",
    "etat_de_conservation" : "Etat de conservation",
    "numero_exemplaire" : "Numéro d'exemplaire",
    "numero" : "Numéro",
    "filtrer" : "Filtrer",
    "non_signe" : "Non signé",
    "non_numerote" : "Non numéroté",
    "edition" : "Edition",
    "consignation_en_cours" : "Consignation en cours",
    "localisation": "Localisation",
    "global_localisation": "Localisation globale",
    "emplacement": "Emplacement",
    "etat_de_la_caisse": "Etat de la caisse",
    "caisse_de_transport": "Caisse de transport",
    "conservation": "Conservation/Restauration",
    "date_ouverture_caisse" : "Date ouverture de la caisse",
    "date_ouverture": "Date d'ouverture",
    "date_dernier_constat": "Date dernier constat",
    "realise_par": "Réalisé par",
    "date_restauration": "Date de restauration",
    "restauration": "Restauration",
    "oeuvres_soeurs": "Oeuvres soeurs",
    "fabrication": "Fabrication",
    "autres": "Autres",
    "historique": "Provenance",
    "add_owner" : "Ajouter un propriétaire",
    "assurance": "Assurance",
    "prix": "Prix",
    "authentification": "Authentification",
    "ajouter_une_demande" : "Ajouter une demande",
    "fabrique_par": "Fabriqué par",
    "passeport" : "Passeport",
    "a_restaurer" : "A restaurer",
    "detruit" : "Détruit",
    "nonrestaurable" : "Non restaurable",
    "cadre_de_voyage" : "Cadre de voyage",
    "a_refaire" : "A refaire",
    "ouverture_verticale" : "Ouverture verticale",
    "tyvek_ok" : "Tyvek OK",
    "peinte" : "Peinte",
    "nimp15" : "NIMP 15",
    "cales_ok" : "Cales OK",

    "editeur": "Editeur",
    "serie": "Série",
    "tirage": "Tirage",
    "code": "Code",
    "plan": "Plan",
    "fiche_technique": "Fiche technique",
    "date_fiche_technique": "Date fiche technique",

    "code_comite" : "Code comité",
    "ft_verifie_par_le_comite": "Fiche technique vérifiée par le comité",

    "etat_de_la_fiche": "Etat de la fiche",
    "doublon": "Doublon",
    "inventaire": "Inventaire",
    "disponible_a_la_vente": "Disponible à la vente",
    "alerte_sur_loeuvre": "Alerte sur l'oeuvre",
    "previous_owner": "Ancien propriétaire",
    "new_owner": "Nouveau propriétaire",
    "document": "Document",
    "en_cours": "En cours",
    "confirme": "Confirmé",
    "assureur": "Assureur",
    "auction": "Enchère",
    "prix_au_marteau": "Prix au marteau",
    "prix_de_vente": "Prix de vente",
    "lot": "Lot",
    "estimation" : "Estimation",

    "contrat_envoye" : "Contrat envoyé",
    "contrat_recu" : "Contrat reçu",
    "paiement" : "Paiement",
    "document_didentite" : "Document d'identité",
    "arrivee_oeuvre" : "Arrivée oeuvre",
    "retour_oeuvre" : "Retour oeuvre",
    "certificat" : "Certificat",
    "signature" : "Signature",
    "contrat_signe" : "Contrat signé",
    "envoi_certificat" : "Envoi Certificat",
    "reception_certificat" : "Réception Certificat",
    "certificat_signe" : "Certificat signé",
    "date_photo_hd" : "Photo HD",
    "caisse" : "Caisse",
    

    // BIBLIO
    "biblio": "Biblio/Presse",
    "biblio_presse": "Biblios/Presse",
    "biblios": "Biblios",
    "section": "Section",
    "exemplaires": "Exemplaires",
    "type_de_document" : "Type de document",
    "type_de_texte": "Type de texte",
    "nouvelle_info" : "Nouvelle information",
    "rangement": "Rangement",
    "auteur": "Auteur",
    "auteurs": "Auteurs",
    "bibliographie": "Bibliographie",
    "oeuvre_non_identifiee": "Oeuvre non identifiée",
    "texte_de_soto": "Texte de Soto",
    "texte_sur_soto": "Texte sur Soto",
    "entretien_avec_soto": "Entretien avec Soto",
    "transcription" : "Transcription",
    "source_internet" : "Source Internet",
    "page" : "Page",
    "reproduction_mention" : "Reproduction / Mention",

    // CONSIGNATION
    "consignation": "Consignation",
    "consignations": "Consignations",
    "motif_de_consignation" : "Motif de consignation",
    "emprunteur" : "Emprunteur",
    "transport" : "Transport",
    "contact" : "Contact",
    "toutes": "Toutes",
    "terminees" : "Terminées",
    "contrat" : "Contrat",
    "vendu" : "Vendu",
    "pret" : "Prêt",
    "retour" : "Retour",
    "depart" : "Départ",
    "retour_valide" : "Retour validé",
    "depart_valide" : "Départ validé",
    "consignation_terminee" : "Consignation terminée",

    // CONTACT
    "structure": "Structure",
    "fonction": "Fonction",
    "genre_du_contact": "Genre du contact",
    "tel_perso": "Téléphone perso",
    "email_perso": "Email perso",
    "tel_pro": "Téléphone pro",
    "email_pro": "Email pro",
    "tel_autre": "Téléphone autre",
    "email_autre": "Email autre",
    "site_web": "Site web",
    "oeuvres_detenues" : "Oeuvres détenues",
    "oeuvres_detenues_passe" : "Oeuvres passées",
    "multiples_detenus" : "Multiples détenues",
    "multiples_passes" : "Multiples passés",
    "create_new_contact" : "Créer un nouveau contact",
    "invitation": "Invitation",
    "date_acquisition" : "Date d'acquisition",
    // DESSIN
    "dessin": "Dessin",
    "dessins": "Dessins",


    // EXPO
    "expo": "Expo",
    "expos": "Expos",
    "annee_debut": "Année de début",
    "annee_fin": "Année de fin",
    "date_approximative": "Date approx.",
    "lieu" : "Lieu",
    "type_expo": "Type d'expo",
    "dossier_dexpo" : "Dossier d'expo",
    "participants": "Participants",
    "commissaire_curator" : "Commissaire/Curator",

    // MEDIA
    "media": "Media",
    "medias": "Medias",
    "source": "Source",
    "format": "Format",
    "genre": "Genre",
    "langue": "Langue",
    "langue_son" : "Langue / son",
    "sous_titre": "Sous-titre",
    "langue_soustitre": "Langue du sous-titre",
    "duree": "Durée",
    "support_original": "Support original",
    "date_de_parution" : "Date de parution",
    "timecode" : "Timecode",

    // PHOTO
    "photo": "Photo",
    "photos": "Photos",
    "photographe": "Photographe",
    "localisation du support original": "Localisation du support original",
    "identification personnes / oeuvres": "Identification personnes / oeuvres",
    "localisation_exposition" : "Localisation / Expo",
    "code_photo" : "Code photo",
    "nb_exemplaire" : "Nb d'exemplaires",
}
