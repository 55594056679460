import * as types from '../constants';

export default function reducer(state = {}, actions) {
    switch (actions.type) {

        case types.SET_AUTH_TOKEN:
            return {
                ...state,
                token: actions.token
            }
        case types.SET_AUTH_USER:
            return {
                ...state,
                user: actions.user,
            }
        case types.CLEAR_AUTH:
            return {}

        default:
            return state
    }
}
