import React, { useEffect, useState } from 'react';


import {
    CircularProgress
} from '@material-ui/core'

import PageLayout from 'layouts/Helpers/PageLayout'
import { getAllContacts } from 'Api';

import Layout from './layout'

export default ({ actions }) => {

    const [ contacts, setContacts ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getAllContacts().then(contacts => {
            setContacts(contacts.contacts || []);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <CircularProgress />
    }

    return (
        <PageLayout title={"Contacts"} actions={(
            <div>
            </div>
        )}>
            <Layout data={contacts} actions={actions} onChange={setContacts}/>
        </PageLayout>
    )
}