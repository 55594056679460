import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import {
    Grid,
    Paper
} from '@material-ui/core';

import Form from 'components/Form';

import ArtworksPanel from './Artworks/';

import { updateConsignation } from 'Api'

const FormContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const Box = styled(Paper)`
    padding:20px;
`

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)

export default ({ data, reloadData = () => {}, onChange = () => {}, layout = 'editcreate' }) => {

    const { t } = useTranslation();

    const layoutIsSearch = layout === 'search';
    const layoutIsEdit = layout === 'edit';

    const form_left = [
        { label: t("motif_de_consignation"), attr: "motif", type: "text", size: { label: 150, field:300 }},
        { label: t("emprunteur"), attr: "emprunteur", type: "text", size: { label: 150, field:300 }},
        { label: t("contact"), attr: "contact", type: "text", size: { label: 150, field:300 }},
        
        ...(!layoutIsSearch ? [
            { label: t("date_debut"), attr: "date_debut", type: "dateselector", size: { label: 150, field:150 }},
        ] : [
            [{ label: t("date_debut"), attr: "date_debut", type: "dateselector", size: { label: 150, field:150 }},
            { label: " - ", attr: "date_debut_max", type: "dateselector", size: { label: 20, field:150 }},]
        ]),

        ...(!layoutIsSearch ? [
            { label: t("date_fin"), attr: "date_fin", type: "dateselector", size: { label: 150, field:150 }},
        ] : [
            [{ label: t("date_fin"), attr: "date_fin", type: "dateselector", size: { label: 150, field:150 }},
            { label: " - ", attr: "date_fin_max", type: "dateselector", size: { label: 20, field:150 }},]
        ]),

        { label: t("remarques"), attr: "remarques", type: "text", multi:'5', size: { label: 150, field:300 } },

    ]

   const form_right = [

        { label: t("transport"), attr: "transport", type: "text", multi:'5', size: { label: 150, field:300 } },
        { label: t("adresse"), attr: "adresse", type: "text", size: { label: 150, field:300 } },
        { label: t("code_postal"), attr: "code_postal", type: "text", size: { label: 150, field:100 } },
        { label: t("ville"), attr: "ville", type: "text", size: { label: 150, field:300 } },
        {
            label: t("pays"), attr: 'pays', type: "select",
            selectType: 'countries', size: { label: 150, field: 300 }
        },
        { label: t("phone"), attr: "telephone", type: "text", size: { label: 150, field:300 } },
        { label: t("email"), attr: "email", type: "text", size: { label: 150, field:300 } },

    ]

  //  console.log("oeuvre in consignation : ", data.oeuvre_consignes)

    async function updateConsignationAsync(data) {
        await updateConsignation({ id: data.objectid, data });
        reloadData(data)
    }

    return (

        <GridContainer>

            <Grid item xs={6}>
                <Box elevation={1}>
                    <FormContainer>
                        <Form form={form_left} data={data} onChange={onChange}/>
                    </FormContainer>
                </Box>
            </Grid>

            <Grid item xs={6}>
                <Box elevation={1}>
                    <FormContainer>
                        <Form form={form_right} data={data} onChange={onChange}/>
                    </FormContainer>
                </Box>
            </Grid>

            {!layoutIsSearch && layoutIsEdit && <Grid item xs={12}>
                <ArtworksPanel reloadData={reloadData} data={data} onChange={updateConsignationAsync}/>
             </Grid>}


        </GridContainer>

    )
}