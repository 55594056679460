import React, { useState } from 'react';
import DataTable from 'components/DataTable'
import TransportModal from 'pages/Avila/Modals/Transport'
import { useTranslation } from 'react-i18next'

import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@material-ui/icons'

import {
    Button
} from '@material-ui/core';

import { updateOeuvre } from 'Api/artworks'

export default ({ data, isMultiple, reloadData = () => {}, actions, onChange = () => {}, onClickEdit }) => {

    const [ editTransport, setEditTransport ] = useState(null);
    const { t } = useTranslation();
   
    async function updateOeuvreAsync(data) {
        await updateOeuvre({ id: data.objectid, data });
        onChange(data)
    }

    return (

        <>
            {editTransport &&
                <TransportModal
                    data={editTransport}
                    oeuvre={data}
                    oeuvreid={data.objectid}
                    isMultiple={isMultiple}
                    onClose={() => {
                        setEditTransport(false)
                    }}
                    onSave={() => reloadData()}
                />
            }

            <DataTable
                data={data.caisse_transports}
                headerActions={[
                    <Button key="add" onClick={() => setEditTransport({})} variant="contained">{t("add_caisse")}</Button>
                ]}
                config={{
                    cells: [
                        { title: t('size'), key: 'size' },
                        { title: "NIMP15", key: 'nimp15' },
                        { title: t('poids'), key: 'poids' },
                        { title: t('etat_de_la_caisse'), key: 'etat' },
                        ...(isMultiple ? [
                                { title: t('exemplaire'), key: 'multiple' },
                        ] : [] ),
                    ],
                    actions: actions || [
                        { icon: <EditIcon />, title: "Edit", onClick: (id, transport) =>  setEditTransport(transport)},
                        { icon: <DeleteIcon />, title: "Delete", onClick: (id, transport) => {  
                            if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                updateOeuvreAsync({
                                    ...data,
                                   caisse_transports: data.caisse_transports.filter(e => e.objectid !== transport.objectid)
                                })
                            }
                        }}
                    ]
                }}
                getCell={(item, attr_name) => {
                    if ( attr_name === 'size' ) {
                        var string = ""

                        if ( item["largeur"] ) {
                            string = item["largeur"] + " x "
                        } else {
                            string = "largeur x "
                        }
                        if ( item["profondeur"] ) {
                            string = string + item["profondeur"] + " x "
                        } else {
                            string = string + "profondeur x "
                        }
                        if ( item["hauteur"] ) {
                            string = string + item["hauteur"]
                        } else {
                            string = string + "hauteur"
                        }
                        return string
                    
                    } else if ( attr_name === 'nimp15' ) {
                    
                        return !item[attr_name] ? "" : "✔"

                    } else {
                        return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                    }

                }}
            />
        </>
    )
}