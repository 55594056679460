// @flow
import React, { useState } from 'react';
import Form from 'components/Form';
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core';

import {
    Add as AddIcon
} from '@material-ui/icons'

import ContactModal from 'pages/Avila/Modals/AddContact'
import CreateContactModal from 'pages/Avila/Modals/CreateContact'
import { listeDesExemplaires } from 'Util/OeuvreMultiple'
import { getContactById } from "Api/contacts"


type Props = {
    data: any,
    isMultiple: any,
    onChange: any => any
}
export default ({ data, isMultiple, multiples, onChange }: Props) => {

    const { t } = useTranslation();
    const [ addOldContactModal, setAddOldContactModal ] = useState(null);
    const [ addNewContactModal, setAddNewContactModal ] = useState(null);
    const [ createOldContactModal, setCreateOldContactModal ] = useState(null);
    const [ createNewContactModal, setCreateNewContactModal ] = useState(null);

    const onClickAddOldContact = () => {
        setAddOldContactModal(true);
    }

    const onClickAddNewContact = () => {
        setAddNewContactModal(true);
    }

    const onClickCreateOldContact = () => {
        setCreateOldContactModal(true);
    }

    const onClickCreateNewContact = () => {
        setCreateNewContactModal(true);
    }

    const onClickOldInconnu = () => {
        data.ancien_proprio = null
        onChange({
            ...data
        })
    }

    const onClickNewInconnu = () => {
        data.nouveau_proprio = null
        onChange({
            ...data
        })
    }

    const loadOldContact = async (id) => {

        const contact = await getContactById({ id })
         
        data.ancien_proprio = [contact['contact']]
        onChange({
            ...data
        })
        setCreateOldContactModal(false);
    }

    const loadNewContact = async (id) => {

        const contact = await getContactById({ id })
         
        data.nouveau_proprio = [contact['contact']]
        onChange({
            ...data
        })
        setCreateNewContactModal(false);
    }

    if ( data.ancien_proprio && data.ancien_proprio.length > 0 ) {
        data.ancien_proprio = data.ancien_proprio[0]
    }
    if ( data.nouveau_proprio && data.nouveau_proprio.length > 0 ) {
        data.nouveau_proprio = data.nouveau_proprio[0]
    }

    const listeExemplaires = listeDesExemplaires(multiples)

 //   console.log("historique in Historique form: ", data)

    return (
        <React.Fragment>

            {addOldContactModal &&
                <ContactModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, contact) => {

                                data.ancien_proprio = [contact]
                                onChange({
                                    ...data
                                })
                                setAddOldContactModal(false);
                            }
                        }
                    ]}
                    filterData={data.contacts}
                    onClose={() => {
                        setAddOldContactModal(false)
                    }}
                />
            }

            {addNewContactModal &&
                <ContactModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, contact) => {

                                data.nouveau_proprio = [contact]
                                onChange({
                                    ...data
                                })
                                setAddNewContactModal(false);
                            }
                        }
                    ]}
                    filterData={data.contacts}
                    onClose={() => {
                        setAddNewContactModal(false)
                    }}
                />
            }

            {createOldContactModal &&
                <CreateContactModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, contact) => {

                                data.ancien_proprio = contact
                                onChange({
                                    ...data
                                })
                                setCreateNewContactModal(false);
                            }
                        }
                    ]}
                    filterData={data.contacts}
                    onSave={(contactData) => {
                        loadOldContact(contactData['objectId'])
                    }}
                    onClose={() => {
                        setCreateOldContactModal(false)
                    }}
                />
            }

            {createNewContactModal &&
                <CreateContactModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, contact) => {

                                data.nouveau_proprio = contact
                                onChange({
                                    ...data
                                })
                                setCreateNewContactModal(false);
                            }
                        }
                    ]}
                    filterData={data.contacts}
                    onSave={(contactData) => {
                        loadNewContact(contactData['objectId'])
                    }}
                    onClose={() => {
                        setCreateNewContactModal(false)
                    }}
                />
            }

            <Form
                form={[
                    { label: t("date"), attr: "date_historique", type: "dateselector", size: { label: 150, field: 150 }},
                    { label: t("type"), attr: 'type', type: "select", options : [
                        { label: "Vente", value: "vente"},
                        { label: "Don", value: "don"},
                        { label: "Echange", value: "echange"},
                        { label: "Enchère", value: "enchere"},
                        { label: "Héritage", value: "heritage"},
                        { label: "Autre", value: "autre"}], size: { label: 150, field: 200 }
                    },

                    ...(isMultiple ? [
                        { label: t("numero_exemplaire"), attr: "multiple", type: "autocomplete", autocomplete: {
                            type: 'free',
                            options: listeExemplaires
                            }
                            , size: { label: 150, field:200 }
                        },
                    ] : [] ),
                    {
                        label: t("previous_owner"), attr: "old_owner_name", type: "display",
                        getValue: (data) => {

                            var ownerString = "Inconnu"
                            if ( data.ancien_proprio ) {
                                if ( data.ancien_proprio.structure ) {
                                   ownerString = data.ancien_proprio.structure + " - "
                                }
                                if ( data.ancien_proprio.nom ) {
                                    ownerString = ownerString + data.ancien_proprio.nom
                                }
                                if ( data.ancien_proprio.prenom ) {
                                   ownerString = ownerString + " " + data.ancien_proprio.prenom
                                }


                            }
                            return (
                                <span>{ownerString}</span> 
                            )
                        },
                        size: { label: 150, field: 400 }
                    },

                    { label: " ", attr:"old_owner", type: "display",
                        getValue: (data) => {
                            return (
                                <>
                                    <Button variant="contained" color="primary" style={{marginRight:'20px'}} onClick={onClickAddOldContact}>{data.ancien_proprio ? "Changer" : "Sélectionner" }</Button>
                                    <Button variant="contained" color="primary" style={{marginRight:'20px'}} onClick={onClickCreateOldContact}>Créer un nouveau contact</Button>
                                    <Button variant="contained" color="primary" onClick={onClickOldInconnu}>Propriétaire inconnu</Button>

                                </>
                            )
                        }, size: { label: 150 }
                    },


                    {
                        label: t("new_owner"), attr: "new_owner_name", type: "display",
                        getValue: (data) => {
                        

                            var newOwnerString = "Inconnu"
                            if ( data.nouveau_proprio ) {
                                if ( data.nouveau_proprio.structure ) {
                                   newOwnerString = data.nouveau_proprio.structure + " - "
                                }
                                if ( data.nouveau_proprio.nom ) {
                                    newOwnerString = newOwnerString + data.nouveau_proprio.nom
                                }
                                if ( data.nouveau_proprio.prenom ) {
                                   newOwnerString = newOwnerString + " " + data.nouveau_proprio.prenom
                                }

                            }
                            return (
                                <span>{newOwnerString}</span> 
                            )
                        },
                        size: { label: 150, field: 400 }
                    },

                    { label: " ", attr:"new_owner", type: "display",
                        getValue: (data) => {
                            return (
                                <>
                                    <Button variant="contained" color="primary" style={{marginRight:'20px'}} onClick={onClickAddNewContact}>{data.nouveau_proprio ? "Changer" : "Sélectionner"}</Button>
                                    <Button variant="contained" color="primary" style={{marginRight:'20px'}} onClick={onClickCreateNewContact}>Créer un nouveau contact</Button>
                                    <Button variant="contained" color="primary" onClick={onClickNewInconnu}>Propriétaire inconnu</Button>
                                </>
                            )
                        }, size: { label: 150 }
                    },
                    { label: t("remarques"), attr: "remarques", type: "text", size: { label: 150, field: 400 }},
                ]}
                data={data}
                onChange={onChange}
            />

        </React.Fragment>

    )
}
