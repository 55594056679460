import Query from './Query';
import ApiCall from './ApiCall';

export default async ({ data }) => {
    const query = new Query();

    //
    //  left
    //
    if (data.archive) {
        query.contains({
            fieldName: 'archive',
            value: data.archive.replaceAll("-", " ")
        })
    }
    
    if (data.type_document) {
        query.contains({
            fieldName: 'type_document',
            value: data.type_document
        })
    } 

    if (data.rangement) {
        query.contains({
            fieldName: 'rangement',
            value: data.rangement
        })
    }
    if (data.emplacement) {
        query.matchTextExact({
            fieldName: 'emplacement',
            value: data.emplacement
        })
    }
    if (data.bibliographie_search) {
        query.contains({
            fieldName: 'bibliographie',
            value: data.bibliographie_search
        })
    }

    if (data.auteurs) {
        query.contains({
            fieldName: 'auteurs',
            value: data.auteurs
        })
    }

    if (data.titre) {
        query.contains({
            fieldName: 'titre',
            value: data.titre
        })
    }


    if ( data.annee ) {

        if ( data.annee_max ) {

            query.matchNumberBetween({
                fieldName: 'annee',
                value_min: data.annee,
                value_max: data.annee_max
            })
        } else {
            query.matchNumber({
                fieldName: 'annee',
                value: data.annee
            })
        }
    }

    if ( data.circa ) {
        query.matchNumber({
            fieldName: 'circa',
            value: data.circa
        })
    }

    if (data.editeur) {
        query.contains({
            fieldName: 'editeur',
            value: data.editeur
        })
    }
    if (data.ville) {
        query.contains({
            fieldName: 'ville',
            value: data.ville
        })
    }
    if (data.pays) {
        query.contains({
            fieldName: 'pays',
            value: data.pays
        })
    }
    if (data.isbn) {
        query.contains({
            fieldName: 'isbn',
            value: data.isbn
        })
    }
    if (data.nb_exemplaires) {
        query.contains({
            fieldName: 'nb_exemplaires',
            value: data.nb_exemplaires
        })
    }


    //
    //  right
    //
    if (data.texte_de_soto) {
        query.matchNumber({
            fieldName: 'texte_de_soto',
            value: data.texte_de_soto
        })
    }
    if (data.texte_sur_soto) {
        query.matchNumber({
            fieldName: 'texte_sur_soto',
            value: data.texte_sur_soto
        })
    }
    if (data.entretien_avec_soto) {
        query.matchNumber({
            fieldName: 'entretien_avec_soto',
            value: data.entretien_avec_soto
        })
    }

    if (data.ville) {
        query.contains({
            fieldName: 'ville',
            value: data.ville
        })
    }
    if (data.pays) {
        query.contains({
            fieldName: 'pays',
            value: data.pays
        })
    }
    if (data.localisation) {
        query.contains({
            fieldName: 'localisation',
            value: data.localisation
        })
    }
    if (data.bibliographie) {
        query.contains({
            fieldName: 'bibliographie',
            value: data.bibliographie
        })
    }
    if (data.mots_clefs) {
        query.contains({
            fieldName: 'mots_clefs',
            value: data.mots_clefs
        })
    }

    if (data.nouvelle_info) {
        query.matchNumber({
            fieldName: 'nouvelle_info',
            value: data.nouvelle_info
        })
    }
    if (data.oeuvre_non_identifiee) {
        query.matchNumber({
            fieldName: 'oeuvre_non_identifiee',
            value: data.oeuvre_non_identifiee
        })
    }

    if (data.remarques) {
        query.contains({
            fieldName: 'remarques',
            value: data.remarques
        })
    }

    if (data.transcription) {
        query.contains({
            fieldName: 'transcription',
            value: data.transcription
        })
    }

    console.log("query Biblio : ", query);


    return await ApiCall({ type: 'biblio', query })
}