import Query from './Query';
import ApiCall from './ApiCall';
import { langueStringFromArray } from 'Util/MediaLangues'
import { langueSTStringFromArray } from 'Util/MediaLanguesST'
import { formatStringFromArray } from 'Util/MediaFormats'


export default async ({ data }) => {
    const query = new Query();

    if (data.archive) {
        query.contains({
            fieldName: 'archive',
            value: data.archive.replaceAll("-", " ")
        })
    }

    if (data.titre) {
        query.contains({
            fieldName: 'titre',
            value: data.titre
        })
    }

    if ( data.date_parution ) {

        if ( data.date_parution_max ) {

            query.matchDateBetween({
                fieldName: 'date_parution',
                date_min: data.date_parution,
                date_max: data.date_parution_max
            })
        } else {
            query.matchDate({
                fieldName: 'date_parution',
                value: data.date_parution
            })
        }
    }


    if ( data.minutes_min ) {

        if ( data.minutes_max ) {

            query.matchNumberBetween({
                fieldName: 'duree',
                value_min: parseInt(data.minutes_min)*60,
                value_max: parseInt(data.minutes_max)*60
            })
        } else {
            query.matchNumber({
                fieldName: 'duree',
                value: parseInt(data.minutes_min)*60
            })
        }
    }

    if (data.genre) {
        query.contains({
            fieldName: 'genre',
            value: data.genre
        })
    }

    if (data.criteres_array) {
        data.criteres_array.forEach( critere => {
            query.contains({
                fieldName: 'criteres',
                value: critere["value"]
            })          
        })
    }

    if (data.langue_array) {

        const langueString = langueStringFromArray(data.langue_array)
        const langueArray = langueString.split(",")

        langueArray.forEach( langue => {    
            query.contains({
                fieldName: 'langue',
                value: langue
            })
        })
    }

    if (data.langue_sous_titre_array) {

        const langueString = langueSTStringFromArray(data.langue_sous_titre_array)
        const langueArray = langueString.split(",")

        langueArray.forEach( langue => {    
            query.contains({
                fieldName: 'langue_sous_titre',
                value: langue
            })
        })
    }


    if (data.mots_clefs) {
        query.contains({
            fieldName: 'mots_clefs',
            value: data.mots_clefs
        })
    }

    if (data.remarques) {
        query.contains({
            fieldName: 'remarques',
            value: data.remarques
        })
    }

    if (data.source) {
        query.contains({
            fieldName: 'source',
            value: data.source
        })
    }

    if (data.site_web) {
        query.contains({
            fieldName: 'site_web',
            value: data.site_web
        })
    }

    if (data.support_original) {
        query.contains({
            fieldName: 'support_original',
            value: data.support_original
        })
    }

    if (data.format_array) {
        const formatString = formatStringFromArray(data.format_array)
        const formatArray = formatString.split(",")

        formatArray.forEach( format => {    
            query.contains({
                fieldName: 'format',
                value: format
            })
        })
    }

    if (data.rangement) {
        query.contains({
            fieldName: 'rangement',
            value: data.rangement
        })
    }

    if (data.emplacement) {
        query.matchTextExact({
            fieldName: 'emplacement',
            value: data.emplacement
        })
    }

    if (data.transport) {
        query.contains({
            fieldName: 'transport',
            value: data.transport
        })
    }

    if (data.fonction) {
        query.contains({
            fieldName: 'fonction',
            value: data.fonction
        })
    }

    if (data.nom) {
        query.contains({
            fieldName: 'nom',
            value: data.nom
        })
    }

    if (data.prenom) {
        query.contains({
            fieldName: 'prenom',
            value: data.prenom
        })
    }


    if (data.email) {
        query.contains({
            fieldName: 'email',
            value: data.email
        })
    }
    if (data.telephone) {
        query.contains({
            fieldName: 'telephone',
            value: data.telephone
        })
    }

    if (data.mobile) {
        query.contains({
            fieldName: 'mobile',
            value: data.mobile
        })
    }

    if (data.adresse) {
        query.contains({
            fieldName: 'adresse',
            value: data.adresse
        })
    }

    if (data.vilcode_postalle) {
        query.matchNumber({
            fieldName: 'code_postal',
            value: data.code_postal
        })
    }
    if (data.ville) {
        query.contains({
            fieldName: 'ville',
            value: data.ville
        })
    }

    if (data.pays) {
        query.contains({
            fieldName: 'pays',
            value: data.pays
        })
    }

    if (data.transcription) {
        query.contains({
            fieldName: 'transcription',
            value: data.transcription
        })
    }

    console.log("query Media", query);

    return await ApiCall({ type: 'media', query })
}