import Query from './Query';
import ApiCall from './ApiCall';

export default async ({ data }) => {
    const query = new Query();

    if (data.archive) {

        query.contains({
            fieldName: 'archive',
            value: data.archive.replaceAll("-", " ")
        })
    }

    if (data.annee_min && data.annee_max) {

        query.matchNumberBetween({
            fieldName: 'annee',
            value_min: data.annee_min,
            value_max: data.annee_max
        })
    } else if (data.annee_min && !data.annee_max) {

        query.matchNumber({
            fieldName: 'annee',
            value: data.annee_min,
        })
    }

    if (data.photographe) {
        query.contains({
            fieldName: 'photographe',
            value: data.photographe
        })
    }

    if (data.copyright) {
        query.contains({
            fieldName: 'copyright',
            value: data.copyright
        })
    }
    

    if (data.localisation_original) {
        query.contains({
            fieldName: 'localisation_original',
            value: data.localisation_original
        })
    }

    if (data.caracteristique) {
        query.contains({
            fieldName: 'caracteristique',
            value: data.caracteristique
        })
    }

    if (data.identification) {
        query.contains({
            fieldName: 'identification',
            value: data.identification
        })
    }

    if (data.localisation) {
        query.contains({
            fieldName: 'localisation',
            value: data.localisation
        })
    }


    if (data.nb_exemplaire) {
        query.contains({
            fieldName: 'nb_exemplaire',
            value: data.nb_exemplaire
        })
    }

    if (data.rangement) {
        query.contains({
            fieldName: 'rangement',
            value: data.rangement
        })
    }

    if (data.emplacement) {
        query.matchTextExact({
            fieldName: 'emplacement',
            value: data.emplacement
        })
    }


    if (data.code_photo) {
        query.contains({
            fieldName: 'code_photo',
            value: data.code_photo
        })
    }

 /*   ['criteres'].forEach(attrName => {
        if (data[attrName] && data[attrName].length > 0) {
            query.matchSubobjectList({
                fieldName: attrName,
                ids: data[attrName]
            })
        }
    })*/

    if (data.criteres_array) {
        data.criteres_array.forEach( critere => {
            query.contains({
                fieldName: 'criteres_string',
                value: critere["value"]
            })          
        })
    }

    console.log("query Photo : ", query)

    return await ApiCall({ type: 'photo', query })
}