



export const getMediaLangues = () => {

    return [
    	{label: "Allemand", value: "allemand"},
    	{label: "Anglais", value: "anglais"},
    	{label: "Basque", value: "basque"},
    	{label: "Espagnol", value: "espagnol"},
        {label: "Français", value: "francais"},
        {label: "Japonais", value: "japonais"},
    	{label: "Musique", value: "musique"},
        {label: "Portugais", value: "portugais"},
        {label: "Sans son", value: "sans_son"},
        {label: "Son ambiant", value: "son_ambiant"},
    	{label: "Autre", value: "autre"},
    ]
}


export const langueStringFromArray = (array) => {

	var string = ""
	var count = 0

	if ( array != null ) {
		array.forEach( item => {
			string = string + item.value
			count = count + 1
			if ( count !== array.length ) {
				string = string + ","
			}
		})
	}

	return string
}


export const langueArrayFromString = (string) => {

	var items = []

	if ( string != null ) {
		var strings = string.split(',');

		strings.forEach( tag => {

			var found = langueItem(tag)

			if ( found !== null ) {
				items.push(found)
			}
		})
	}

	return items
}


export const langueItem = (tag) => {

	var found = null
	const langues = getMediaLangues()

	langues.forEach( item => {

		if ( item["value"] === tag ) {
			found = item
		}
	})	

	return found
}

