



export const getMediaLangueSTs = () => {

    return [
    	{label: "Allemand", value: "allemand"},
    	{label: "Anglais", value: "anglais"},
    	{label: "Espagnol", value: "espagnol"},
        {label: "Français", value: "francais"},
        {label: "Portugais", value: "portugais"},
    	{label: "Autre", value: "autre"},
    ]
}


export const langueSTStringFromArray = (array) => {

	var string = ""
	var count = 0

	if ( array != null ) {
		array.forEach( item => {
			string = string + item.value
			count = count + 1
			if ( count !== array.length ) {
				string = string + ","
			}
		})
	}

	return string
}


export const langueSTArrayFromString = (string) => {

	var items = []

	if ( string != null ) {
		var strings = string.split(',');

		strings.forEach( tag => {

			var found = langueSTItem(tag)

			if ( found !== null ) {
				items.push(found)
			}
		})
	}

	return items
}


export const langueSTItem = (tag) => {

	var found = null
	const langues = getMediaLangueSTs()

	if ( langues != null ) {

		langues.forEach( item => {

			if ( item["value"] === tag ) {
				found = item
			}
		})	
	}

	return found
}

