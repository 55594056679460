// @flow
import React, { useState } from 'react';
import styled from 'styled-components'

import {
    Tabs, Tab as MuiTab, AppBar
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

export { TabsContainer, TabsPanels } from './helpers'

const Tab = styled(MuiTab)`
    > span {
        align-items: flex-start;
    }
`

const TabsContainerHorizontal = props => (
    <AppBar position="static" color="default" {...props} />
)

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

type Props = {
    tabs: Array<{
        label: any,
        panel: any
    }>,
    initialIndex?: number,
    orientation?: 'horizontal' | 'vertical'
}
export default ({ tabs, initialIndex = 0, orientation = 'horizontal', ...props }: Props) => {

    const classes = useStyles();
    const [index, setIndex] = useState(initialIndex);

    const handleChange = (event, newValue) => {
        setIndex(newValue);
    }

    let ContainerComponent = TabsContainerHorizontal;
    if (orientation === 'vertical') {
        ContainerComponent = React.Fragment;
    }

    return (

        <>
            <ContainerComponent>
                <Tabs
                    {...props}
                    orientation={orientation}
                    value={index}
                    onChange={handleChange}
                    className={classes.tabs}
                >
                    {tabs.map((tab, index) => (
                        <Tab key={index+"_"} label={tab.label} />
                    ))}
                </Tabs>
            </ContainerComponent>

            {tabs[index] && tabs[index].panel}

        </>
    )
}