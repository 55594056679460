import { apiCall, constructUrl, getResult, fetchApi as fetch } from './ApiCall';
import { getPhotoCriteres, critereArrayFromString, critereStringFromArray } from 'Util/PhotoCriteres';


const constructPhoto = photo => ({
    ...photo,
    criteres: (photo.criteres || []).map(item => item.objectid),
    criteres_array: critereArrayFromString(photo.criteres_string, getPhotoCriteres()),
})


export const getAllPhotos = async ({ cursor, limit } = {}) => {
    return await apiCall("getallphotos")
}

export const getPhotoById = async ({ id }) => {
    const rs = await apiCall("getphoto", "?photo_id=" + id)
    if (rs.photo && rs.photo.length > 0) {
        return {
            photo: constructPhoto(rs.photo[0])
        }
    }

    return null;
}

export const deletePhoto = async ({ photo }) => {

    await apiCall("deletephoto", "?photo_id=" + photo.objectid)

}

export const createPhoto = async ({ data }) => {
    const url = constructUrl("createphoto");

    var formdata = new FormData();
    formdata.append("photo", JSON.stringify({photoData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createphoto');
}

export const updatePhoto = async ({ id, data }) => {
    const url = constructUrl("updatephoto");

    [ 'oeuvres', 'biblios', 'medias','expositions' ].forEach(attr => {
        if (data[attr]) {
            data[attr] = data[attr].map(item => item.objectid);
        }
    })

    data['criteres_string'] = critereStringFromArray(data['criteres_array'], getPhotoCriteres())

    var formdata = new FormData();
    formdata.append("photo_id", id);

    formdata.append("photo", JSON.stringify({photoData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updatephoto');
}


export const getAllCriteres = () => apiCall("getallcriteres");
