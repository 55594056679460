// @flow
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

type Props = {
    options: Array<string>,
    value: string,
    inputProps: Object,
    onChange: (value: string) => any
}
export default ({
    options,
    value,
    onChange,
    inputProps = {},
    ...props
}: Props) => {
    return (
        <Autocomplete
            {...props}
            freeSolo
            value={value || ""}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            //to get the value on every input change
            onInputChange = {(event, value) => {
           //     console.log("onInputChange : ", event)
                if ( event != null ) {
                    onChange(value);
                }
            }}
            options={options}
            renderInput={(params) => (
                <TextField {...params} {...inputProps} margin="normal" variant="outlined" />
            )}
        />
    );
}