import React from 'react';
import DataTable from 'components/DataTable'
import {
    Edit as EditIcon
} from '@material-ui/icons'

import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'
import { sortPhotos } from 'Util/Sorting'

import Image from 'components/Img'
import Photos from 'data/Photos';


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Photos, actions, onClickEdit, headerActions }) => {

    const { t } = useTranslation();
    
    data.sort(function (a, b) {
        return sortPhotos(a["archive"], b["archive"], true)
    });
    
    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                config={{
                    cells: [
                        { title: t('archive'), key: 'archive', bold:true },
                        { title: t('photo'), key: 'filename' },
                        { title: t('annee'), key: 'annee' },
                        { title: t('photographe'), key: 'photographe' },
                        { title: t('copyright'), key: 'copyright' },
                        { title: t('localisation-exposition'), key: 'localisation' },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, photo) => {
                                window.open("/photo/" + photo.objectid)
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === "filename") {
                        return (
                            <Image
                                imageWidth={60}
                            style={{justifyContent: 'flex-start'}}
                                alt="" filename={item.filename}
                            />
                        )
                    }

                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}