import React from 'react';
import Expositions from 'data/Expos';

import DataTable from 'components/DataTableWithHeader'

import {
    Edit as EditIcon
} from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'
import { country } from 'Util/Listes'

import { sortArchive, sortAlpha } from 'Util/Sorting'


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Expositions, actions, onChange, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();
    
    
    function sorting ( category, order ) {

        if ( category === "archive" ) {
            data.sort(function (a, b) {
                return sortArchive(a[category], b[category], order)
            });
        } else {
            data.sort(function (a, b) {
                return sortAlpha(a[category], b[category], order)
            });
        }
    }
    
    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: (expo, index) => {
                        history.push("/expo/" + expo.objectid, {
                            context: "search_result",
                            search: {
                                index
                            }
                        });
                    },
                    cells: [
                        { title: t('archive'), key: 'archive', bold:true, width:"120px" },
                        { title: t('title'), key: 'titre', width:"750px" },
                        { title: t('lieu'), key: 'lieu', width:"300px", sort:false },
                        { title: t('ville'), key: 'ville', width:"150px", sort:false },
                        { title: t('pays'), key: 'pays', width:"150px", sort:false },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, expo) => {
                                window.open("/expo/" + expo.objectid)
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name) => {
                    if ( attr_name === 'pays' ) {
                        return country(item[attr_name])
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}