import React, { useEffect, useState } from 'react';

import {
    CircularProgress,
} from '@material-ui/core'

import { getAllPhotos } from 'Api';

import PageLayout from 'layouts/Helpers/PageLayout'
import Layout from './layout'


export default ({ actions }) => {


    const [ photos, setPhotos ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getAllPhotos().then(photos => {
            setPhotos(photos.photos || []);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <CircularProgress />
    }

    return (
        <PageLayout title={"Photos"} actions={(
            <div>
            </div>
        )}>
            <Layout data={photos} actions={actions} onChange={setPhotos} />
        </PageLayout>
    )
}