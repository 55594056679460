import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import {
    Grid,
    Paper
} from '@material-ui/core';

import Image from 'components/Img'

import Form from 'components/Form';

import ArtworksPanel from './Artworks/'
import PhotosPanel from './Photos/'
import BibliosPanel from '../../../Biblio/SubPanel/index_expo'
import MediasPanel from '../../../Media/SubPanel/'

import { expoVirtuelle } from 'Util/Expos';
import { getExpoTypes } from 'Util/ExpoCriteres';
import { updateExpo } from 'Api/expos'


import Tabs, {
    TabsContainer, TabsPanels as OriginalTabsPanels
} from 'components/Tabs'

const TabsPanels = styled(OriginalTabsPanels)`
    padding:20px;
    padding-top: 0px;
`

const Box = styled(Paper)`
    padding:20px;
`

const FormContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)


export default ({ data = {}, reloadData = () => {}, onChange = () => {}, layout = 'editcreate' }) => {

    const { t } = useTranslation();

    const layoutIsSearch = layout === 'search';
    const layoutIsEdit = layout === 'edit';

    const isExpoVirtuelle = expoVirtuelle(data.type_expos)
    const isStartDateInvalid = !data["circa_debut"]?1:0
    const isEndDateInvalid = !data["circa_fin"]?1:0
    
    const form = [

        { label: t("archive"), attr: "archive", type: "text", size: { label: 150, field:100 }},
        { label: t("title"), attr: "titre", type: "text", size: { label: 150, field:500 }},

        ...(!layoutIsSearch ? [

            [
                { label: t("date_debut"), attr: "date_debut", type: "dateselector", size: { label: 150, field:150 }},
                { label: "Circa", attr: "circa_debut", type: "checkbox", size: { label: 10, left:30 } },
                ...(!isStartDateInvalid ? [ { label: "", attr: "date_debut_invalid", type: "text", size: { label: 10, field:150 }},]:[])
            ],
            [
                { label: t("date_fin"), attr: "date_fin", type: "dateselector", size: { label: 150, field:150 }},
                { label: "Circa", attr: "circa_fin", type: "checkbox", size: { label: 10, left:30 } },
                ...(!isEndDateInvalid ? [ { label: "", attr: "date_fin_invalid", type: "text", size: { label: 10, field:150 }},]:[])
            ]
        ]:[
            [
                { label: t("annee_debut"), attr: "annee_debut", type: "text", size: { label: 150, field:50 }},
                { label: t("circa"), attr: "circa_debut", type: "checkbox", size: { label: 10, left:30 } },
            ],
            [
                { label: t("annee_fin"), attr: "annee_fin", type: "text", size: { label: 150, field:50 }},
                { label: t("circa"), attr: "circa_fin", type: "checkbox", size: { label: 10, left:30 } },
            ]
        ]),


        { label: t("lieu"), attr: "lieu", type: "text", size: { label: 150, field:500 }},
        { label: t("ville"), attr: "ville", type: "text", size: { label: 150, field:500 }},
        {
            label: t("pays"), attr: 'pays', type: "select",
            selectType: 'countries', size: { label: 150, field: 500 }
        },
      
        { label: t("type_expo"), attr: "typeexpo_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getExpoTypes()
            },
            size: { label: 150, field: 500 }
        },

        ...(!isExpoVirtuelle ? [

        { label: t("site_web"), attr: "website", type: "text", size: { label: 150, field:400 }},
        ] : []),

        { label: t("participants"), attr: "artistes", type: "text", multi: 5, size: { label: 150, field:500 }},
        { label: t("commissaire_curator"), attr: "commissaire", type: "text", size: { label: 150, field:500 }},


    ]

    var photo_items = data.photo_items
    var photo_main = null
    let photo_filename = null;
    let exportFilename = null;

    if (data.photo_items && photo_items.length > 0) {
        photo_main = photo_items[0]
        photo_filename = photo_main.filename
        exportFilename = "Expo " + data.archive + ".jpg"
    }


    async function updateExpoAsync(data) {
        await updateExpo({ id: data.objectid, data });
        reloadData(data)
    }


    return (

        <GridContainer>

            <Grid item xs={layoutIsSearch ? 12 : 8}>
                <Box elevation={1} style={{position: 'relative', height: '100%'}}>
                    <FormContainer>
                        <Form form={form} data={data} onChange={onChange} />
                    </FormContainer>

                    {layoutIsSearch && <FormContainer>
                        <Form form={[
                            { label: t("remarques"), attr: "remarques", type: "text", multi:5, size: { label: 150, field:500 }},
                            { label: t("dossier_dexpo"), attr: "dossierdexpo", type: "text", multi:5, size: { label: 150, field:500 }},
                        ]}
                        data={data}
                        onChange={onChange} />
                    </FormContainer>}
                </Box>
            </Grid>

            {!layoutIsSearch && <Grid item xs={4}>
                <Box elevation={1} style={{position: 'relative', height: '100%'}}>

                    <Image
                        onClickDelete={() => {
                            if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {

                                const [ main_item, ...photo_items ] = data.photo_items
                                if (!main_item) {
                                    return;
                                }
                                onChange({
                                    ...data,
                                    photo_items
                                })
                            }
                        }}
                        onChange={filename => {
                            const photo_items = data.photo_items.slice(1);
                            onChange({
                                ...data,
                                photo_items: [
                                    {
                                        filename,
                                        private: 0,
                                        type: "20"
                                    },
                                    ...photo_items
                                ]
                            })
                        }}
                        alt="test"
                        subdir="photos"
                        downloadFile={exportFilename}
                        filename={photo_filename}
                        style={{width: '100%'}}
                        imgStyle={{maxWidth: '100%', maxHeight: '500px'}}
                        actionPosition='outside'
                    />
                <FormContainer>
                    <Form form={[
                        { label: t("remarques"), attr: "remarques", type: "text", multi:5, size: { label: 80, field:280 }},
                    ]} data={data} onChange={onChange} reloadData={reloadData}/>
                </FormContainer>
                </Box>

            </Grid>}


            {layoutIsEdit  && <Grid item xs={12}>

                <TabsContainer orientation={"horizontal"}>

                    <Tabs
                        orientation={"horizontal"}
                        variant="scrollable"
                        tabs={[

                            {
                                label: t("photos"),
                                panel: (
                                    <TabsPanels>
                                        <PhotosPanel layout={layout} data={data} reloadData={reloadData} onChange={updateExpoAsync} />
                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("oeuvres"),
                                panel: (
                                    <TabsPanels>
                                        <ArtworksPanel layout={layout} data={data} reloadData={reloadData} onChange={updateExpoAsync}/>
                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("biblios"),
                                panel: (
                                    <TabsPanels>
                                        <BibliosPanel layout={layout} data={data} reloadData={reloadData} onChange={updateExpoAsync} />
                                    </TabsPanels>
                                )
                            },
                            {
                                label: t("medias"),
                                panel: (
                                    <TabsPanels>
                                        <MediasPanel layout={layout} data={data} reloadData={reloadData} onChange={updateExpoAsync} />

                                    </TabsPanels>
                                )
                            },
                            {
                                label: t("dossier_dexpo"),
                                panel: (
                                    <TabsPanels>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Form form={[
                                                    { label: t("dossier_dexpo"), attr: "dossierdexpo", type: "text", multi: 15, size: { label: 150, field:800 } }
                                                ]} data={data} reloadData={reloadData}  onChange={onChange} />
                                            </Grid>
                                        </Grid>

                                    </TabsPanels>
                                )
                            },

                        ]}
                    />

                </TabsContainer>

            </Grid>}

        </GridContainer>
    )
}