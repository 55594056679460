

const config_dev_local = {

  // API_BASE_URL: "https://aviladev-api.tappublisher.com",
   // SEARCH_BASE_URL: "https://aviladev-search.tappublisher.com",*/

    API_BASE_URL: "https://avilaprod-api.tappublisher.com",
    SEARCH_BASE_URL: "https://avilaprod-search.tappublisher.com",

    UPLOAD_URL: "https://avilaprod-medias.tappublisher.com/upload",
    MEDIA_URL: "https://avilaprod-medias.tappublisher.com/media"

}

const config_dev = {
    API_BASE_URL: "https://aviladev-api.knx-lab.com",
    SEARCH_BASE_URL: "https://aviladev-search.knx-lab.com",
    UPLOAD_URL: "https://aviladev-medias.knx-lab.com/upload",
    MEDIA_URL: "https://aviladev-medias.knx-lab.com/media"
}

const config_prod = {
    API_BASE_URL: "https://avilaprod-api.tappublisher.com",
    SEARCH_BASE_URL: "https://avilaprod-search.tappublisher.com",
    UPLOAD_URL: "https://avilaprod-medias.tappublisher.com/upload",
    MEDIA_URL: "https://avilaprod-medias.tappublisher.com/media"
}

/*    ...config_dev,
//   API_BASE_URL: "https://avila-api.knx-lab.com/proxy",
}
*/


export default () => {
    switch (window.location.hostname) {
        case 'localhost':
            return config_dev_local;
        case 'avila.netlify.app':
            return config_prod;
        case 'avila.knx-lab.com':
        case 'avila-prod.knx-lab.com':
            return config_prod;
        case 'avila-dev.knx-lab.com':
            return config_dev;

        case 'avila-prod.tappublisher.com':
        case 'avila.tappublisher.com':
            return {
                API_BASE_URL: "https://avilaprod-api.tappublisher.com",
                SEARCH_BASE_URL: "https://avilaprod-search.tappublisher.com",
                UPLOAD_URL: "https://avilaprod-medias.tappublisher.com/upload",
                MEDIA_URL: "https://avilaprod-medias.tappublisher.com/media"
            };
        case 'avila-dev.tappublisher.com':
            return {
                API_BASE_URL: "https://aviladev-api.tappublisher.com",
                SEARCH_BASE_URL: "https://aviladev-search.tappublisher.com",
                UPLOAD_URL: "https://aviladev-medias.tappublisher.com/upload",
                MEDIA_URL: "https://aviladev-medias.tappublisher.com/media"
            };
        default:
            return config_dev;
    }
}