// @flow
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'

type Props = {
    data: any,
    onChange: any => any
}
export default ({ data, onChange }: Props) => {
    
    const { t } = useTranslation();

    return (
        <React.Fragment>

            <Form
                form={[
                    { label: t("structure"), attr: "structure", type: "text"},
                    { label: t("nom"), attr: "nom", type: "text"},
                    { label: t("prenom"), attr: "prenom", type: "text"},
                ]}
                data={data}
                onChange={onChange}
            />

        </React.Fragment>

    )
}
