import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Edit as EditIcon
} from '@material-ui/icons'

import Search from 'Api/Search/contact';
import DescriptionPanel from '../EditCreate/Description';
import ListLayout from '../List/layout_search'
import SearchLayout from 'layouts/SearchPage'
import { saveAs } from 'file-saver'
import { country } from 'Util/Listes'

export default () => {

    const history = useHistory();
    const objectType = 'contact';

    async function exportContact(selectedList, contacts) {

        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()

        ws.columns = [
            { header: 'Structure', key: 'structure', width: 30 },
            { header: 'Titre', key: 'titre', width: 10 },
            { header: 'Nom', key: 'nom', width: 30 },
            { header: 'Prénom', key: 'prenom', width: 30 },
            { header: 'Adresse', key: 'adresse', width: 50 },
            { header: 'Copde postal', key: 'code_postal', width: 10 },
            { header: 'Ville', key: 'ville', width: 30 },
            { header: 'Pays', key: 'pays', width: 20 },
            { header: 'Mail', key: 'email', width: 30 },
        ];
 
        contacts.forEach(contact => {
            const fullCountry = country(contact.pays)

            const row = ws.addRow([contact.structure,
                    contact.titre,
                    contact.nom,
                    contact.prenom,
                    contact.adresse,
                    contact.code_postal,
                    contact.ville,
                    fullCountry,
                    contact.email_perso])
            row.bold = false
        })


        const buf = await wb.xlsx.writeBuffer()

        saveAs(new Blob([buf]), 'Contacts.xlsx')
    }

    return (
        <SearchLayout
            exportList={[{"value":"contacts", "name":"Liste contacts"},]}
            onClickExport={exportContact}
            objectType={objectType}
            title={"Rechercher un contact"}
            search={Search}
            SearchFormComponent={props => (
                <DescriptionPanel layout="search" {...props} />
            )}
            ResultListComponent={({ data }) => (
                <ListLayout
                    data={data}
                    actions={[
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, object, index) => {
                                history.push("/"+objectType+"/" + object.objectid, {
                                    context: "search_result",
                                    search: {
                                        index
                                    }
                                });
                            }
                        }
                    ]}
                />
            )}

            onFeelingLucky={oeuvre => history.push("/"+objectType+"/"+oeuvre.objectid)}
        />
    )
}