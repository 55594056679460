import React from 'react';
import { useHistory } from 'react-router-dom'
import { createPhoto } from 'Api'
import PhotoLayout from './index';

export default () => {

    const history = useHistory();

    return (
        <PhotoLayout
            layout="create"
            saveData={async data => {

                const result = await createPhoto({ data });
                return result;
            }}
            onSaved={photo => {
                history.push("/photo/" + photo.objectId);
            }}
        />
    )
}