import React from 'react';
import DataTable from 'components/DataTableWithHeader'
import {
    Edit as EditIcon
} from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import Image from 'components/Img'
import Dessins from 'data/Dessins';
import { sortAlphaNum, sortAlpha } from 'Util/Sorting'

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Dessins, actions, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();
   

    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if ( (category === "archive")) {

                return sortAlphaNum(a[category], b[category], order)
            }
            
            return sortAlpha(a[category], b[category], order)
        });
    }

    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: (dessin, index) => {
                        history.push("/dessin/" + dessin.objectid, {
                            context: "search_result",
                            search: {
                                index
                            }
                        });

                    },
                    cells: [
                        { title: t('archive'), key: 'archive', width:150, bold:true },
                        { title: t('photo'), key: 'filename', width:150, sort:false },
                        { title: t('annee'), key: 'annee', width:150, sort:false },
                        { title: t('oeuvres'), key: 'oeuvres', width:1000 },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, dessin) => {
                                window.open("/dessin/" + dessin.objectid)
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === "filename") {
                        return (
                            <Image
                                imageWidth={60}
                            style={{justifyContent: 'flex-start'}}
                                alt="" filename={item.filename}
                            />
                        )
                    }
                    if (attr_name === "oeuvres") {
                        var stringArchives = ""
                        const oeuvre_archives = item["oeuvre_archive"]

                        if ( oeuvre_archives ) {

                            oeuvre_archives.forEach(archive => {
                                stringArchives = stringArchives + "#"  + archive + ", "
                            })
                        }
                        return stringArchives
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}