import { constructUrl, getResult, fetchApi as fetch } from './ApiCall';


export const createPhotoItem = async ({ data }) => {
    const url = constructUrl("createphotoitem");

    var formdata = new FormData();
    formdata.append("photo_item", JSON.stringify({photo_itemData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createphoto_item');
}
