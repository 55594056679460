import { SET_AUTH_TOKEN, CLEAR_AUTH, SET_AUTH_USER } from 'redux/constants';
import { login as loginApi, loginWithCode as loginWithCodeApi, getUserById } from 'Api'
import validator from 'validator'
import Cookies from 'js-cookie';

const setCurrentUser = user => (dispatch, getState) => {
    dispatch({
        type: SET_AUTH_USER,
        user
    })
}
const setToken = token => (dispatch, getState) => {
    dispatch({
        type: SET_AUTH_TOKEN,
        token
    })
}
const clearToken = token => (dispatch, getState) => {
    dispatch({
        type: CLEAR_AUTH
    })
}

export const login = email => async (dispatch, getState) => {
    if (!validator.isEmail(email)) {
        throw new Error("Email mal formaté")
    }

    return await loginApi({ email });
}

export const loginWithCode = (email, code) => async (dispatch, getState) => {

    const result = await loginWithCodeApi({ email, code });

    await dispatch(onAuth(result.token, result.user_id))
}

export const logout = () => async (dispatch) => {
    Cookies.remove('token');
    Cookies.remove('user_id');
    dispatch(clearToken())
}

export const onAuth = (token, user_id) => async (dispatch, getState) => {

    if (!token || !user_id) {
        return false;
    }

    dispatch(setToken(token))
    Cookies.set('token', token);
    Cookies.set('token-timeout', String(((new Date()).getTime() / 1000) + (3600)) )
 //   console.log("set cookies onAuth")
    Cookies.set('user_id', user_id);

    try {
        const responseJson = await getUserById({ id: user_id });
        dispatch(setCurrentUser(responseJson))
    } catch (e) {
        dispatch(logout());
        throw e;
    }





    return true;
}