import { SET_TYPE_EXPOS as SET_TYPE } from 'redux/constants';
import { getAllTypeExpos as getAll } from 'Api'

const setData = data => ({ type: SET_TYPE, data })

export const loadType_Expos = () => async (dispatch, getState) => {
    const data = await getAll();

    if (data.type_expos) {
        return dispatch(setData(data.type_expos));
    }
}
