import React from 'react';
import DataTable from 'components/DataTable'

import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@material-ui/icons'

import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';

import Consignations from 'data/Consignations';
import Moment from 'moment';
import { country } from 'Util/Listes'
import { useCurrentPermissionProfile } from 'redux/hooks';
import { deleteConsignation } from 'Api'
import { useTranslation } from 'react-i18next'

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Consignations, actions, onChange = () => {}, onClickEdit, headerActions }) => {

    const permissionProfile = useCurrentPermissionProfile();
    const canDelete = permissionProfile === 'comite' || permissionProfile === 'admin'  || permissionProfile === 'avila' || permissionProfile === null;
    const { t } = useTranslation();


    async function deleteConsignationAsync(consignation) {

        await deleteConsignation({ consignation });
        onChange(data.filter(e => e.objectid !== consignation.objectid))
    }
    
    console.log("oeuvre dans consignation:", data)
    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}

            <DataTable
                data={data}
                config={{
                    onClickRow: consignation => {
                        window.open("/consignation/" + consignation.id_consignation);

                    },
                    cells: [
                        { title: t('emprunteur'), key: 'emprunteur' },
                        { title: t('date_debut'), key: 'date_debut', width:'100px'  },
                        { title: t('date_fin'), key: 'date_fin', width:'100px'  },
                        { title: t('depart_valide'), key: 'depart', width:'100px'  },
                        { title: t('retour_valide'), key: 'retour', width:'100px'  },
                        { title: t('ville'), key: 'ville', width:'120px'  },
                        { title: t('pays'), key: 'pays', width:'100px' },
                        { title: t('motif'), key: 'motif' },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, consignation) => {
                                window.open("/consignation/" + consignation.id_consignation)
                            }
                        },
                        ...(canDelete ? [
                        {   
                            icon: <DeleteIcon />, title: "Delete",
                            onClick: (id, consignation) => {  
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                    deleteConsignationAsync(consignation)
                                }
                            }
                        } ] : [])
                    ]
                }}
                getCell={(item, attr_name) => {

                    if ( attr_name === "date_debut") {
                        if ( item['date_debut'] ) {
                            return Moment(item['date_debut']).format('DD-MM-YYYY')
                        }
                        return ""

                    } else if ( attr_name === "date_fin") {
                        if ( item['date_fin'] ) {
                            return Moment(item['date_fin']).format('DD-MM-YYYY')
                        }
                        return ""
            
                    } else if ( attr_name === "depart") {
                        return item["depart"] === 1 ? "Oui" : "Non"

                    } else if ( attr_name === "retour") {
                        return item["retour"] === 1 ? "Oui" : "Non"

                   } else if ( attr_name === "pays" ) {
                        return country(item[attr_name])
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}