import React from 'react';
import { Redirect } from 'react-router-dom'
import {
  Aperture,
  Home,
  Users,
  Box,
  Calendar,
  BookOpen,
  Image
} from "react-feather";

import ArtWorkCreate from 'pages/Avila/ArtWork/EditCreate/Create'
import ArtWorkEdit from 'pages/Avila/ArtWork/EditCreate/Edit'
import ArtWorkList from 'pages/Avila/ArtWork/List'
import ArtWorkSearch from 'pages/Avila/ArtWork/Search'

import ContactCreate from 'pages/Avila/Contact/EditCreate/Create'
import ContactEdit from 'pages/Avila/Contact/EditCreate/Edit'
import ContactList from 'pages/Avila/Contact/List'
import ContactSearch from 'pages/Avila/Contact/Search'

import ConsignationCreate from 'pages/Avila/Consignation/EditCreate/Create'
import ConsignationEdit from 'pages/Avila/Consignation/EditCreate/Edit'
import ConsignationList from 'pages/Avila/Consignation/List'
import ConsignationSearch from 'pages/Avila/Consignation/Search'

import ExpoCreate from 'pages/Avila/Expo/EditCreate/Create'
import ExpoEdit from 'pages/Avila/Expo/EditCreate/Edit'
import ExpoList from 'pages/Avila/Expo/List'
import ExpoSearch from 'pages/Avila/Expo/Search'

import BiblioList from 'pages/Avila/Biblio/List'
import BiblioEdit from 'pages/Avila/Biblio/EditCreate/Edit'
import BiblioCreate from 'pages/Avila/Biblio/EditCreate/Create'
import BiblioSearch from 'pages/Avila/Biblio/Search'

import MediaList from 'pages/Avila/Media/List'
import MediaEdit from 'pages/Avila/Media/EditCreate/Edit'
import MediaCreate from 'pages/Avila/Media/EditCreate/Create'
import MediaSearch from 'pages/Avila/Media/Search'

import PhotoList from 'pages/Avila/Photo/List'
import PhotoEdit from 'pages/Avila/Photo/EditCreate/Edit'
import PhotoCreate from 'pages/Avila/Photo/EditCreate/Create'
import PhotoSearch from 'pages/Avila/Photo/Search'

import DessinList from 'pages/Avila/Dessin/List'
import DessinEdit from 'pages/Avila/Dessin/EditCreate/Edit'
import DessinCreate from 'pages/Avila/Dessin/EditCreate/Create'
import DessinSearch from 'pages/Avila/Dessin/Search'

import UploadExperiment from 'pages/Avila/Experiments/upload'

import UsersList from 'pages/Avila/Users/List'

//import OptionsEdit from 'pages/Avila/Options/EditCreate/'

import Maintenance from 'pages/Avila/Maintenance/EditCreate/'

//import TestUnitaire from 'pages/Avila/TestUnitaire/EditCreate/'

const homeRoute = {
  id: "Home",
  path: "/",
  icon: <Home />,
  header: "Avila",
  component: () => (<Redirect to="/artwork"/>),
  children: null
};

const experimentUploadRoute = {
  id: "upload",
  path: "/experiments/upload",
  icon: <Home />,
  header: "Avila",
  component: UploadExperiment,
  children: null,
  sidebar: false,
}

const usersRoute = {
  id: "users",
  name: t => t("Utilisateurs"),
  path: "/users",
  icon: <Home />,
  sidebar: true,
  noMenu: true,
  children: [
    {
      path: "/users",
      sidebar: true,
      name: t => t("list"),
      component: UsersList
    },
  ]
}

const artWorksRoutes = {
  id: "Oeuvres",
  name: t => t("artworks"),
  path: "/artwork",
  icon: <Aperture />,
  alwaysOpen: true,
  noMenu: false,
  children: [
    {
      path: "/artwork",
      sidebar: true,
      name: t => t("list"),
      component: ArtWorkList
    },
    {
      path: "/artwork/create",
      sidebar: true,
      name: t => t("new"),
      component: ArtWorkCreate
    },
    {
      path: "/artwork/search",
      sidebar: true,
      name: t => t("search"),
      component: ArtWorkSearch
    },
    {
      path: "/artwork/:id",
      name: "Edit art work",
      component: ArtWorkEdit
    }
  ]
};



const contactsRoutes = {
  id: "Contacts",
  path: "/contact",
  icon: <Users />,
  alwaysOpen: false,
  noMenu: false,
  children: [
    {
      path: "/contact",
      sidebar: true,
      name: t => t("list"),
      component: ContactList
    },
    {
      path: "/contact/create",
      sidebar: true,
      name: t => t("new"),
      component: ContactCreate
    },
    {
      path: "/contact/search",
      sidebar: true,
      name: t => t("search"),
      component: ContactSearch
    },
    {
      path: "/contact/:id",
      name: "Edit contact",
      component: ContactEdit
    }
  ]
};

const consignationRoutes = {
  id: "Consignations",
  path: "/consignation",
  icon: <Box />,
  alwaysOpen: false,
  noMenu: false,
  children: [
    {
      path: "/consignation",
      sidebar: true,
      name: t => t("list"),
        component: ConsignationList
    },
    {
      path: "/consignation/create",
      sidebar: true,
      name: t => t("new"),
      component: ConsignationCreate
    },
    {
      path: "/consignation/search",
      sidebar: true,
      name: t => t("search"),
      component: ConsignationSearch
    },
    {
      path: "/consignation/:id",
      name: "Edit consignation",
      component: ConsignationEdit
    }
  ]
};

const exposRoutes = {
  id: "Expos",
  path: "/expo",
  icon: <Calendar />,
  alwaysOpen: false,
  noMenu: false,

  children: [
    {
      path: "/expo",
      sidebar: true,
      name: t => t("list"),
      component: ExpoList
    },
    {
      path: "/expo/create",
      sidebar: true,
      name: t => t("new"),
      component: ExpoCreate
    },
    {
      path: "/expo/search",
      sidebar: true,
      name: t => t("search"),
      component: ExpoSearch
    },
    {
      path: "/expo/:id",
      name: "Edit expo",
      component: ExpoEdit
    }
  ]
};

const biblioRoutes = {
  id: "Biblios/Presse",
  path: "/biblio",
  icon: <BookOpen />,
  alwaysOpen: false,
  noMenu: false,
  children: [
    {
      path: "/biblio",
      sidebar: true,
      name: t => t("list"),
      component: BiblioList
    },
    {
      path: "/biblio/create",
      sidebar: true,
      name: t => t("new"),
      component: BiblioCreate
    },
    {
      path: "/biblio/search",
      sidebar: true,
      name: t => t("search"),
      component: BiblioSearch
    },
    {
      path: "/biblio/:id",
      name: "Edit biblio",
      component: BiblioEdit
    }
  ]
};

const mediasRoutes = {
  id: "Medias",
  path: "/media",
  icon: <Aperture />,
  alwaysOpen: false,
  noMenu: false,
  children: [
    {
      path: "/media",
      sidebar: true,
      name: t => t("list"),
      component: MediaList
    },
    {
      path: "/media/create",
      sidebar: true,
      name: t => t("new"),
      component: MediaCreate
    },
    {
      path: "/media/search",
      sidebar: true,
      name: t => t("search"),
      component: MediaSearch
    },
    {
      path: "/media/:id",
      name: "Edit media",
      component: MediaEdit
    }
  ]
};


const photosRoutes = {
  id: "Photos",
  path: "/photo",
  icon: <Image />,
  alwaysOpen: false,
  noMenu: false,
  children: [
    {
      path: "/photo",
      sidebar: true,
      name: t => t("list"),
      component: PhotoList
    },
    {
      path: "/photo/create",
      sidebar: true,
      name: t => t("new"),
      component: PhotoCreate
    },
    {
      path: "/photo/search",
      sidebar: true,
      name: t => t("search"),
      component: PhotoSearch
    },
    {
      path: "/photo/:id",
      name: "Edit photo",
      component: PhotoEdit
    }
  ]
};

const dessinsRoutes = {
  id: "Dessins",
  path: "/dessin",
  icon: <Image />,
  alwaysOpen: false,
  noMenu: false,
  children: [
    {
      path: "/dessin",
      sidebar: true,
      name: t => t("list"),
      component: DessinList
    },
    {
      path: "/dessin/create",
      sidebar: true,
      name: t => t("new"),
      component: DessinCreate
    },
    {
      path: "/dessin/search",
      sidebar: true,
      name: t => t("search"),
      component: DessinSearch
    },
    {
      path: "/dessin/:id",
      name: "Edit dessin",
      component: DessinEdit
    }
  ]
};

/*const optionsRoutes = {
  id: "Options",
  path: "/options",
  icon: <Image />,
  alwaysOpen: true,
  noMenu: true,
  children: [
    {
      path: "/options",
      sidebar: true,
      name: t => t("list"),
      component: OptionsEdit
    }
  ]
};*/


const maintenanceRoutes = {
  id: "Maintenance",
  path: "/maintenance",
  icon: <Image />,
  alwaysOpen: true,
  noMenu: true,
  children: [
    {
      path: "/maintenance",
      sidebar: true,
      name: t => t("list"),
      component: Maintenance
    }
  ]
};

/*const testUnitaireRoutes = {
  id: "testUnitaire",
  path: "/testUnitaire",
  icon: <Image />,
  alwaysOpen: true,
  noMenu: true,
  children: [
    {
      path: "/testUnitaire",
      sidebar: true,
      name: t => t("list"),
      component: TestUnitaire
    }
  ]
};*/

export const routes = [
  homeRoute,
  artWorksRoutes,
  exposRoutes,
  biblioRoutes,
  consignationRoutes,
  photosRoutes,
  mediasRoutes,
  contactsRoutes,
  dessinsRoutes,
  maintenanceRoutes,
  usersRoute,

  experimentUploadRoute
];