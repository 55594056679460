



export const getMediaFormats = () => {

    return [

    	{label: "16 mm", value: "16_mm"},
    	{label: "Audio", value: "audio"},
    	{label: "Bande magnétique audio", value: "bande_magnetique_audio"},
    	{label: "DVD", value: "dvd"},
    	{label: "Film", value: "film"},
        {label: "Internet", value: "internet"},
    	{label: "MP3", value: "mp3"},
    	{label: "MP4", value: "mp4"},
    	{label: "Musique", value: "musique"},
    	{label: "Vidéo", value: "video"},
        {label: "VHS", value: "VHS"},
        {label: "VOD", value: "vod"},
    ]
}


export const formatStringFromArray = (array) => {

	var string = ""
	var count = 0

	if ( array != null ) {
		array.forEach( item => {
			string = string + item["value"]
			count = count + 1
			if ( count !== array.length ) {
				string = string + ","
			}
		})
	}

	return string
}


export const formatArrayFromString = (string) => {

	var items = []

	if ( string != null ) {
		var strings = string.split(',');

		strings.forEach( tag => {

			var found = formatItem(tag)

			if ( found !== null ) {
				items.push(found)
			}
		})
	}

	return items
}


export const formatItem = (tag) => {

	var found = null
	const formats = getMediaFormats()

	formats.forEach( item => {

		if ( item["value"] === tag ) {
			found = item
		}
	})	

	return found
}

