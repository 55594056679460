// @flow
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'


type Props = {
    data: any,
    onChange: any => any
}

export default ({ data, biblioId, onChange }: Props) => {
    
    const { t } = useTranslation();

   return (
        <React.Fragment>

            <Form
                form={[
                
                    { label: t("archive"), attr: "archive", type: "display", size: { label: 150, field:400 }},
                    { label: t("titre"), attr: "titre", type: "display", size: { label: 150, field:400 }},

                    { label: t("reproduction_mention"), attr: "tirage", type: "select",
                        options: [
                            { label: "Reproduction noir et blanc", value: "noir_et_blanc"},
                            { label: "Reproduction couleur", value: "couleur"},
                            { label: "Mention", value: "mention"},]
                        , size: { label: 150, field:400 }
                    },
                    { label: t("page"), attr: "page", type: "text", size: { label: 150, field:100 }},
                    { label: t("remarques"), attr: "remarques", type: "text", size: { label: 150, field:400 }},

                ]}
                data={data}
                onChange={onChange}
            />

        </React.Fragment>

    )
}
