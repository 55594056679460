import Query from './Query';
import ApiCall from './ApiCall';

export default async ({ data }) => {
    const query = new Query();

    if (data.archive) {
        query.contains({
            fieldName: 'archive',
            value: data.archive.replaceAll("-", " ")
        })
    }

    if (data.annee_min && data.annee_max) {

        query.matchNumberBetween({
            fieldName: 'annee',
            value_min: data.annee_min,
            value_max: data.annee_max
        })
    } else if (data.annee_min && !data.annee_max) {

        query.matchNumber({
            fieldName: 'annee',
            value: data.annee_min,
        })
    }


    if (data.copyright) {
        query.contains({
            fieldName: 'copyright',
            value: data.copyright
        })
    }
    
    if (data.photographe) {
        query.contains({
            fieldName: 'photographe',
            value: data.photographe
        })
    }

    if (data.localisation_original) {
        query.contains({
            fieldName: 'localisation_original',
            value: data.localisation_original
        })
    }

    if (data.caracteristique) {
        query.contains({
            fieldName: 'caracteristique',
            value: data.caracteristique
        })
    }

    if (data.identification) {
        query.contains({
            fieldName: 'identification',
            value: data.identification
        })
    }

    if (data.localisation) {
        query.contains({
            fieldName: 'localisation',
            value: data.localisation
        })
    }

    if (data.oeuvre_non_identifiee) {
        query.matchNumber({
            fieldName: 'oeuvre_non_identifiee',
            value: data.oeuvre_non_identifiee
        })
    }

    if (data.hauteur && data.hauteur_max) {

        query.matchNumberBetween({
            fieldName: 'hauteur',
            value_min: data.hauteur,
            value_max: data.hauteur_max
        })
    } else if (data.hauteur && !data.hauteur_max) {

        query.matchNumber({
            fieldName: 'hauteur',
            value: data.hauteur,
        })
    }
    if (data.hauteur && data.hauteur_max) {

        query.matchNumberBetween({
            fieldName: 'hauteur',
            value_min: data.hauteur,
            value_max: data.hauteur_max
        })
    } else if (data.hauteur && !data.hauteur_max) {

        query.matchNumber({
            fieldName: 'hauteur',
            value: data.hauteur,
        })
    }

    if (data.largeur && data.largeur_max) {

        query.matchNumberBetween({
            fieldName: 'largeur',
            value_min: data.largeur,
            value_max: data.largeur_max
        })
    } else if (data.largeur && !data.largeur_max) {

        query.matchNumber({
            fieldName: 'largeur',
            value: data.largeur,
        })
    }

    if (data.profondeur && data.profondeur_max) {

        query.matchNumberBetween({
            fieldName: 'profondeur',
            value_min: data.profondeur,
            value_max: data.profondeur_max
        })
    } else if (data.profondeur && !data.profondeur_max) {

        query.matchNumber({
            fieldName: 'profondeur',
            value: data.profondeur,
        })
    }

    if (data.diametre && data.diametre_max) {

        query.matchNumberBetween({
            fieldName: 'diametre',
            value_min: data.diametre,
            value_max: data.diametre_max
        })
    } else if (data.diametre && !data.diametre_max) {

        query.matchNumber({
            fieldName: 'diametre',
            value: data.diametre,
        })
    }

    if (data.nb_tacos && data.nb_tacos_max) {

        query.matchNumberBetween({
            fieldName: 'nb_tacos',
            value_min: data.nb_tacos,
            value_max: data.nb_tacos_max
        })
    } else if (data.nb_tacos && !data.nb_tacos_max) {

        query.matchNumber({
            fieldName: 'nb_tacos',
            value: data.nb_tacos,
        })
    }

    if (data.nb_varillas && data.nb_varillas_max) {

        query.matchNumberBetween({
            fieldName: 'nb_varillas',
            value_min: data.nb_varillas,
            value_max: data.nb_varillas_max
        })
    } else if (data.nb_varillas && !data.nb_varillas_max) {

        query.matchNumber({
            fieldName: 'nb_varillas',
            value: data.nb_varillas,
        })
    }

    if (data.nb_tes && data.nb_tes_max) {

        query.matchNumberBetween({
            fieldName: 'nb_tes',
            value_min: data.nb_tes,
            value_max: data.nb_tes_max
        })
    } else if (data.nb_tes && !data.nb_tes_max) {

        query.matchNumber({
            fieldName: 'nb_tes',
            value: data.nb_tes,
        })
    }

   /* ['couleurs', 'elements', 'familles', 'materiaux'].forEach(attrName => {
        if (data[attrName] && data[attrName].length > 0) {
            query.matchSubobjectList({
                fieldName: attrName,
                ids: data[attrName]
            })
        }
    })*/

    if (data.couleurs_array) {
        data.couleurs_array.forEach( couleur => {
            query.contains({
                fieldName: 'couleurs_string',
                value: couleur["value"]
            })          
        })
    }
    if (data.elements_array) {
        data.elements_array.forEach( element => {
            query.contains({
                fieldName: 'elements_string',
                value: element["value"]
            })          
        })
    }
    if (data.familles_array) {
        data.familles_array.forEach( famille => {
            query.contains({
                fieldName: 'familles_string',
                value: famille["value"]
            })          
        })
    }
    if (data.materiaux_array) {
        data.materiaux_array.forEach( materiau => {
            query.contains({
                fieldName: 'materiaux_string',
                value: materiau["value"]
            })          
        })
    }

    return await ApiCall({ type: 'dessin', query })
}