import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Edit as EditIcon
} from '@material-ui/icons'

import Search from 'Api/Search/biblio';
import DescriptionPanel from '../EditCreate/Description';
import ListLayout from '../List/layout_search'
import SearchLayout from 'layouts/SearchPage'

export default () => {

    const history = useHistory();
    const objectType = 'biblio';

    return (
        <SearchLayout
            objectType={objectType}
            title={"Rechercher une biblio"}
            search={Search}
            SearchFormComponent={props => (
                <DescriptionPanel layout="search" {...props} />
            )}
            ResultListComponent={({ data }) => (
                <ListLayout
                    data={data}
                    actions={[
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, object, index) => {
                                history.push("/"+objectType+"/" + object.objectid, {
                                    context: "search_result",
                                    search: {
                                        index
                                    }
                                });
                            }
                        }
                    ]}
                />
            )}

            onFeelingLucky={oeuvre => history.push("/"+objectType+"/"+oeuvre.objectid)}
        />
    )
}