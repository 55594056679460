// @flow
import React from 'react';

import { search } from 'Api'

import Layout from 'pages/Avila/Expo/List/layout_find'

import SearchModal from './SearchModal';
import type { Props as SearchModalProps } from './SearchModal'

type Props = {
    onClose: any => any,
    actions: Array<any>,
    filterData: Array<any>
}
export default ({
    onClose,
    actions = [],
    filterData = [],
}: Props & SearchModalProps) => {

    return (
        <SearchModal
            title={"Ajouter une expo"} onClose={onClose}
            actions={actions}
            filterData={filterData}
            searchFunction={async query => {

                const expos = await search({
                    type: 'exposition',
                    query
                });

                return expos;
            }}
            renderList={({ data, actions }) => {

                return (
                    <Layout data={data} filterData={filterData} actions={actions} />
                )
            }}
        />
    )
}