import React from 'react';
import DataTable from 'components/DataTableWithHeader'
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@material-ui/icons'

import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import Expositions from 'data/Expos';

import { useTranslation } from 'react-i18next'
import { country } from 'Util/Listes'

import { useCurrentPermissionProfile } from 'redux/hooks';
import { deleteExpo } from 'Api'

import { sortAlpha, sortArchive } from 'Util/Sorting'


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Expositions, actions, onChange, onClickEdit, headerActions }) => {

    const { t } = useTranslation();
    const permissionProfile = useCurrentPermissionProfile();
    const canDelete = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'avila' || permissionProfile === null;
    const canEdit = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;


    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if ( category === "archive" ) {

                return sortArchive(a[category], b[category], order)
                
            } else if ( category === "titre" ) {

                return sortAlpha(a[category], b[category], order)

            }
            return -1
        });
    }

    async function deleteExpoAsync(expo) {

        await deleteExpo({ expo });
        onChange(data.filter(e => e.objectid !== expo.objectid))
    }


    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: expo => {
                        window.open("/expo/" + expo.id_expo)
                    },
                    cells: [
                        { title: t('archive'), key: 'archive', bold:true, width:100 },
                        { title: t('exemplaire'), key: 'multiple', width:100, sort:false },
                        { title: t('title'), key: 'titre', width:600 },
                        { title: t('lieu'), key: 'lieu', width:200, sort:false },
                        { title: t('ville'), key: 'ville', width:120, sort:false },
                        { title: t('pays'), key: 'pays', width:120, sort:false },
                    ],
                    actions: actions || [
                        ...(canEdit ? [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, expo) => {
                                window.open("/expo/" + expo.objectid)
                            }
                        }, ] : []),
                        ...(canDelete ? [
                        {   
                            icon: <DeleteIcon />, title: "Delete",
                            onClick: (id, expo) => {  
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                    deleteExpoAsync(expo)
                                }
                            }
                        } ] : [])
                    ]
                }}
                getCell={(item, attr_name) => {

                    if ( attr_name === 'pays' ) {
                        return country(item[attr_name])
                    } else if ( attr_name === 'genre' ) {
                        var genres = ""

                        item["type_expos"].forEach( type_expo => {
                            genres = genres + type_expo.name + ", "
                        })
                        return genres
                    }

                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}