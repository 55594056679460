

import { getExpoTypes, critereArrayFromString } from 'Util/ExpoCriteres';

export const expoVirtuelle = (type_expos) => {

    var found = 0;

    if ( type_expos ) {
        type_expos.forEach(expo => {
            if ( expo === "authentifie") {
                found = 1
            }
        })
    }

    return found
}


export const typesExpoString = (typeexpo_string) => {

	var typeOeuvreString = ""
    var typeexpo_array = critereArrayFromString(typeexpo_string, getExpoTypes())

	if ( typeexpo_array != null ) {
		typeexpo_array.forEach( type => {

            typeOeuvreString = typeOeuvreString + type["label"] + ", "

		})
	}
	return typeOeuvreString
}

