import React from 'react';
import {
  Divider as MuiDivider,
  Typography as MuiTypography,
  Paper
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

export const Divider = styled(MuiDivider)`
    margin-bottom: 20px !important;
    margin-top: 20px !important;
`;



export const Typography = styled(MuiTypography)(spacing);

export const Title = (props) => (
    <Typography variant="h1" gutterBottom display="inline" {...props} />
)

export const SubTitle = (props) => (
    <Typography variant="h3" display="inline" {...props} />
)

///// CONTAINER
export const Container = styled(Paper)`
    padding: 10px;
`

///// TABS
export const TabsContainer = styled.div`
    width: 100%;
`