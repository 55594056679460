import React from 'react';
import { useHistory } from 'react-router-dom'
import { createMedia } from 'Api'
import MediaLayout from './index';

export default () => {

    const history = useHistory();

    return (
        <MediaLayout
            layout={"create"}
            saveData={async data => {

              //  console.log("createMedia : ", data)
                const result = await createMedia({ data });
                return result;
            }}
            onSaved={media => {
                history.push("/media/" + media.objectId);
            }}
        />
    )
}