import React, { useState } from 'react';
import {
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next';

import {
    Button
} from '@material-ui/core';

import BiblioModal from 'pages/Avila/Modals/AddBiblio'
import BiblioListLayout from 'pages/Avila/Biblio/List/layout_expo'

import { saveAs } from 'file-saver'

export default ({ data, actions, onChange = () => {}, onClickEdit }) => {

    const [ addBiblioModal, setAddBiblioModal ] = useState(null);
    const { t } = useTranslation()

    const onClickAddBiblio = () => {
        setAddBiblioModal(true);
    }


    const onClickExportBiblio = () => {
        saveAsExcel();
    }

    async function saveAsExcel() {
        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()

        ws.columns = [
            { header: 'Archive', key: 'Archive', width: 10 },
            { header: 'Genre', key: 'Genre', width: 10 },
            { header: 'Bibliographie', key: 'Bibliographie', width: 100 },
        ];
 
        const biblios = data.biblios

        biblios.forEach(biblio => {

            const row = ws.addRow([biblio.archive, biblio.type_document, biblio.titre])
            row.font = { bold: false }

        })
        const buf = await wb.xlsx.writeBuffer()
        const filename = "Exposition " + data.archive + " Bibliographie.xlsx"

        saveAs(new Blob([buf]), filename)
    }
    
    console.log("biblio : ", data)
    return (

        <>
            {addBiblioModal &&
                <BiblioModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, biblio) => {
                                onChange({
                                    ...data,
                                    biblios: [
                                        ...data.biblios,
                                        biblio
                                    ]
                                })

                                setAddBiblioModal(false);
                            }
                        }
                    ]}
                    filterData={data.biblios}
                    onClose={() => {
                        setAddBiblioModal(false)
                    }}
                />
            }
            <BiblioListLayout
                data={data.biblios}
                headerActions={[
                    <Button key="add" onClick={onClickAddBiblio} variant="contained">{t('add_biblio')}</Button>,
                    <Button style={{marginLeft:'10px'}} key="export" onClick={onClickExportBiblio} variant="contained">Export</Button>
                ]}
                actions={[
                    {
                        key: 'delete', icon: <DeleteIcon />, title: "Delete",
                        onClick: (id, biblio) => {
                            if (window.confirm(`Supprimer ${biblio.titre} ?`)) {
                                onChange({
                                    ...data,
                                    biblios: data.biblios.filter(e => e.objectid !== biblio.objectid)
                                })
                            }
                        }
                    }
                ]}
            />
        </>
    )
}




