import { getYear} from './Dates';



export const displayOeuvreLastProprio = (data) => {

    var lastTransaction = calcLastHistorique(data.historiques)
    var display = ""

    if ( lastTransaction ) {

        var nouveau_proprio = null

        if ( lastTransaction.nouveau_proprio && lastTransaction.nouveau_proprio.length > 0 ) {
            nouveau_proprio = lastTransaction.nouveau_proprio[0]
        }

        if ( nouveau_proprio ) {
           display =  displayProprio(nouveau_proprio)

            if ( lastTransaction.date_historique ) {
                display = display + " - " + getYear(lastTransaction.date_historique)
            }
        } else {
            display = "Inconnu"
        }   

    } else {
        display = "Inconnu"
    }
    return display
}

export const getOeuvreLastProprioId = (data) => {

    var lastTransaction = calcLastHistorique(data.historiques)
    var display = -1

    if ( lastTransaction ) {

        var nouveau_proprio = null

        if ( lastTransaction.nouveau_proprio && lastTransaction.nouveau_proprio.length > 0 ) {
            nouveau_proprio = lastTransaction.nouveau_proprio[0]
        }

        if ( nouveau_proprio ) {
           display =  nouveau_proprio.objectid

        } else {
            display = -1
        }   

    } else {
        display = -1
    }
    return display
}


export const calcLastHistorique = (data) => {

    var lastHistorique = null
    
    if ( data && data.length > 0 ) {
        
        data.sort(function (a, b) {
            if ( a && b ) {
                if ( a.date_historique && b.date_historique ) {
                    return a.date_historique > b.date_historique ? -1 : 1;
                } else if ( a.date_historique ) {
                    return -1
                } else if ( b.date_historique ) {
                    return 1
                }
            }
            return -1
        });

        lastHistorique = data[0]
    }

    return lastHistorique
}

export const displayProprio = (data) => {

    var display = ""

    if ( data ) {

        if ( !data.nom && !data.prenom && !data.structure ) {
            display = "Inconnu"
        }
        
        if ( data.structure ) {
            display = data.structure + " "
        }
        if ( data.nom ) {
            display = display + " " + data.nom
        }
        if ( data.prenom ) { 
            display = display + " " + data.prenom 
        }

    } else {
        display = "Inconnu"
    }
    return display
}





