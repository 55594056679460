import { SET_CRITERES as SET_TYPE } from 'redux/constants';
import { getAllCriteres as getAll } from 'Api'

const setData = data => ({ type: SET_TYPE, data })

export const loadCriteres = () => async (dispatch, getState) => {
    const data = await getAll();

    if (data.criteres) {
        return dispatch(setData(data.criteres));
    }
}
