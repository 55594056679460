// @flow
import React from 'react';

import { updateOeuvreBib, createOeuvreBibUpdateBiblio } from 'Api';
import BiblioOeuvreForm from 'pages/Avila/BiblioOeuvre/Form'

import FormModal from './FormModal'
import { useTranslation } from 'react-i18next'

type Props = {
    biblioId: string,
    onClose: any => any,
    actions: Array<any>,
    data: any,
    onSave: any => any
}
export default ({
    onClose,
    actions = [],
    data,
    biblioId,
    biblio,
    onSave = () => {}
}: Props) => {
    
    const { t } = useTranslation();

    return (

        <FormModal
            title={t("oeuvre")}
            data={data}
            onClose={onClose}
            FormComponent={BiblioOeuvreForm}

            onSave={onSave}
            updateMethod={({ data }) => updateOeuvreBib({biblioId, data })}
            createMethod={({ data }) => createOeuvreBibUpdateBiblio({ biblio, data })}
        />
    )
}