// @flow
import React from 'react';

import {
    TextField,
    MenuItem
} from '@material-ui/core';

import ArtWorkTypesSelector from 'components/Selectors/ArtWorkTypes'
import CountriesSelector from 'components/Selectors/Countries'
import PhotoLocSupportSelector from 'components/Selectors/PhotoLocSupport'


export default ({ config, onChange, ...props } : { onChange: any => any, config: { selectType?: 'artworktypes' } & any}) => {

    if (config.type !== 'select') {
        return null;
    }

    if (!config.selectType) {
        return (
            <TextField
                {...props}
                select
                onChange={({ target: { value }}) => onChange(value)}
            >
                {config.options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    const selectType = config.selectType;

    switch(selectType) {
        case 'artworktypes':
            return (
                <ArtWorkTypesSelector {...props} onChange={onChange} />
            )
        case 'countries':
            return (
                <CountriesSelector {...props} onChange={onChange} />
            )
        case 'photolocsupport':
            return (
                <PhotoLocSupportSelector {...props} />
            )
        default:
            return (
                <TextField
                    {...props}
                    select
                />
            )
    }
}