import React, { useState } from 'react';
import {
    Add as AddIcon
} from '@material-ui/icons'


import DataTable from 'components/DataTableWithHeader'
import OeuvreModal from 'pages/Avila/Modals/AddArtwork'
import OeuvreBibModal from 'pages/Avila/Modals/BiblioOeuvre'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import Image from 'components/Img'
import { deleteOeuvreBib } from 'Api/biblios'

import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@material-ui/icons'

import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { sortAlpha, sortNumbers } from 'Util/Sorting'

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data, reloadData = () => {}, actions, onChange = () => {}, onClickEdit }) => {

    const [ addOeuvre, setAddOeuvre ] = useState(false);
    const [ editOeuvreBib, setEditOeuvreBib ] = useState(false);
    const { t } = useTranslation()


    const onClickAddOeuvre = () => {
        setAddOeuvre(true);
    }

    async function deleteOeuvreAsync(oeuvre_bib) {
        await deleteOeuvreBib({ data : oeuvre_bib });
        onChange({
            ...data,
            oeuvre_bibs: data.oeuvre_bibs.filter(e => e.objectid !== oeuvre_bib.objectid)
        })
    }


    function sorting ( category, order ) {

        data.oeuvre_bibs.sort(function (a, b) {

            if ( category === "titre" ) {
                return sortAlpha(a[category], b[category], order)

            } else if ( category === "archive" ) {
                return sortNumbers(a[category], b[category], order)
            }
            return -1
        });
    }


    return (
    <Paper elevation={3}>

        {addOeuvre &&
            <OeuvreModal
                actions={[
                   {
                        key: 'add', icon: <AddIcon />, title: "Add",
                        onClick: (id, oeuvre) => {

                            setAddOeuvre(false)
                            setEditOeuvreBib({"id_oeuvre" : oeuvre.objectid, "archive" : oeuvre.archive, "titre" : oeuvre.titre})
                        }
                    }
                ]}
                filterData={data.oeuvreBibs}
                biblioId={data.objectid}
                onClose={() => {
                    setAddOeuvre(false)
                }}
            />
        }

        {editOeuvreBib &&
            <OeuvreBibModal
                data={editOeuvreBib}
                biblioId={data.objectid}
                biblio={data}
                onClose={() => {
                    setEditOeuvreBib(false)
                }}
                onSave={reloadData}
            />
        }

        <DataTable
            data={data.oeuvre_bibs}
                headerActions={[
                    <Button key="add" onClick={onClickAddOeuvre}  variant="contained">Ajouter une oeuvre</Button>
                ]}
            defaultOrderBy={"archive"}
            onRequestSort={sorting}
            config={{
                onClickRow: oeuvre_bib => {
                        window.open("/artwork/" + oeuvre_bib.id_oeuvre)
                    },
                cells: [
                    { title: t('archive'), key: 'archive', width:120 },
                    { title: t('photo'), key: 'filename', width:100, sort:false  },
                    { title: t('title'), key: 'titre', width:700 },
                    { title: t('reproduction_mention'), key: 'tirage', width:150, sort:false  },
                    { title: t('page'), width:150, key: 'page' },
                    { title: t('remarques'), key: 'remarques', width:150, sort:false  }
                ],
                actions: actions || [
                    { icon: <EditIcon />, title: "Edit", onClick: (id, oeuvre_bib) => setEditOeuvreBib(oeuvre_bib) },
                    { icon: <DeleteIcon />, title: "Delete", onClick: (id, oeuvre_bib) => {  
                        if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                            deleteOeuvreAsync(oeuvre_bib)
                        }
                    }}
                ]
            }}
            getCell={(item, attr_name) => {

                if (attr_name === "filename") {
                    return (
                        <Image
                            style={{justifyContent: 'flex-start'}}
                            imageWidth={60}
                            alt="" filename={item.filename}
                        />
                    )
                }  
                return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
            }}
        />
    </Paper>
    )
}