import React, { useState } from 'react';
import {
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next';

import {
    Button
} from '@material-ui/core';

import BiblioModal from 'pages/Avila/Modals/AddBiblio'
import BiblioListLayout from 'pages/Avila/Biblio/List/layout_other'


export default ({ data, actions, onChange = () => {}, onClickEdit }) => {

    const [ addBiblioModal, setAddBiblioModal ] = useState(null);

    const onClickAddBiblio = () => {
        setAddBiblioModal(true);
    }
    const { t } = useTranslation()

    
    return (

        <>
            {addBiblioModal &&
                <BiblioModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, biblio) => {
                                onChange({
                                    ...data,
                                    biblios: [
                                        ...data.biblios,
                                        biblio
                                    ]
                                })

                                setAddBiblioModal(false);
                            }
                        }
                    ]}
                    filterData={data.biblios}
                    onClose={() => {
                        setAddBiblioModal(false)
                    }}
                />
            }
            <BiblioListLayout
                data={data.biblios}
                headerActions={[
                    <Button key="add" onClick={onClickAddBiblio} variant="contained">{t('add_biblio')}</Button>
                ]}
                actions={[
                    {
                        key: 'delete', icon: <DeleteIcon />, title: "Delete",
                        onClick: (id, biblio) => {
                            if (window.confirm(`Supprimer ${biblio.titre} ?`)) {
                                onChange({
                                    ...data,
                                    biblios: data.biblios.filter(e => e.objectid !== biblio.objectid)
                                })
                            }
                        }
                    }
                ]}
            />
        </>
    )
}




