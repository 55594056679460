// @flow
import React from 'react';

import { createEnchereUpdateOeuvre, updateEnchere } from 'Api';
import EstimationForm from 'pages/Avila/Estimation/Form'

import FormModal from './FormModal'
import { useTranslation } from 'react-i18next'

type Props = {
    oeuvreid: string,
    onClose: any => any,
    actions: Array<any>,
    data: any,
    onSave: any => any
}
export default ({
    onClose,
    actions = [],
    data,
    oeuvreid,
    oeuvre,
    isMultiple,
    onSave = () => {}
}: Props) => {
    
    const { t } = useTranslation();

    return (

        <FormModal
            title={t("Estimation")}
            data={data}
            onClose={onClose}
            FormComponent={({ data, onChange }) => (
                <EstimationForm
                    isMultiple={isMultiple}
                    data={data}
                    onChange={onChange}
                />
            )}

            onSave={onSave}
            updateMethod={({ data }) => updateEnchere({ oeuvreid, data })}
            createMethod={({ data }) => createEnchereUpdateOeuvre({ oeuvre, data })}
        />
    )
}