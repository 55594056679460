import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default ({ accordions }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        {accordions.map((accordion, i) => (
            <Accordion key={i} defaultExpanded={accordion.expanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{accordion.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {accordion.content}
                </AccordionDetails>
            </Accordion>
        ))}
    </div>
  );
}