// @flow
import React from 'react';

import { updateOeuvreMed, createOeuvreMedUpdateMedia } from 'Api';
import MediaOeuvreForm from 'pages/Avila/MediaOeuvre/Form'

import FormModal from './FormModal'
import { useTranslation } from 'react-i18next'

type Props = {
    mediaId: string,
    onClose: any => any,
    actions: Array<any>,
    data: any,
    onSave: any => any
}
export default ({
    onClose,
    actions = [],
    data,
    mediaId,
    media,
    onSave = () => {}
}: Props) => {
    
    const { t } = useTranslation();

    return (

        <FormModal
            title={t("oeuvre")}
            data={data}
            onClose={onClose}
            FormComponent={MediaOeuvreForm}

            onSave={onSave}
            updateMethod={({ data }) => updateOeuvreMed({mediaId, data })}
            createMethod={({ data }) => createOeuvreMedUpdateMedia({ media, data })}
        />
    )
}