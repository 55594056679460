// @flow
// textType

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    TextField,
} from '@material-ui/core';

const useTextFieldStyles = makeStyles((theme) => ({
    root: {
        height: '20px',
        fontSize:'5px'
    },
}));
const useInputStyles = makeStyles((theme) => ({
    root: {
        height: '20px'
    },
}));


const TextFieldComponent = ({onChange, ...props}: any) => {
    return (
        <input {...props} onChange={({ target: { value }}) => {
            onChange(value)
        }} type='text' />
    )
}

const TextFieldMeasureComponent = ({value, disabled, onChange, ...props}: any) => {
    const textFieldClasses = useTextFieldStyles()
    const inputClasses = useInputStyles()
    const fieldCellStyle = { width: "80px"}

    const [ _value, _setValue ] = useState();

    const getInchesFromCm = cmValue => Math.round((cmValue/2.54)*100)/100

    const onChangeInput = ({ target: { value }}) => {
        const floatValue = parseFloat(value);

        if (!_value && value.endsWith('.')) {
            _setValue(value);
        } else {
            onChange(floatValue);
            _setValue(null);
        }
    }


    return (
        <div>
            <TextField
                {...props}
                onChange={onChangeInput}
                value={_value || `${value}`}
                classes={textFieldClasses}
                variant="outlined"
                disabled={disabled}
                 InputProps={{
                    classes: inputClasses
                }}
                style = {fieldCellStyle}
            />
            <span> cm / ({getInchesFromCm(value)} inches)</span>
        </div>
    )
}

const TextFieldMeasureM2Component = ({value, onChange, ...props}: any) => {
    const textFieldClasses = useTextFieldStyles()
    const inputClasses = useInputStyles()
    const fieldCellStyle = { width: "80px"}

    const [ _value, _setValue ] = useState();

    const getFT2FromM2 = m2Value => Math.round((m2Value*10.7639)*100)/100

    const onChangeInput = ({ target: { value }}) => {

        const cleanValue = value.replaceAll(',', '.');
        const floatValue = parseFloat(cleanValue);

        onChange(floatValue);
        _setValue(value);

     /*   if (!_value && value.endsWith('.')) {
            _setValue(value);
        } else {
            onChange(floatValue);
            _setValue(null);
        }*/
    }

    return (
        <div>
            <TextField
                {...props}
                onChange={onChangeInput}
                value={_value || `${value}`}
                classes={textFieldClasses}
                variant="outlined"
                InputProps={{
                    classes: inputClasses
                }}
                style = {fieldCellStyle}
            />
            <span> m2 / ({getFT2FromM2(10)} ft2)</span>
        </div>
    )
}

const TextFieldWeightComponent = ({value, onChange, ...props}: any) => {
    const textFieldClasses = useTextFieldStyles()
    const inputClasses = useInputStyles()
    const fieldCellStyle = { width: "80px"}

    const [ _value, _setValue ] = useState();

    const getPoundsFromKg = kgValue => Math.round((kgValue/2.20)*100)/100

    const onChangeInput = ({ target: { value }}) => {
        const floatValue = parseFloat(value);

        if (!_value && value.endsWith('.')) {
            _setValue(value);
        } else {
            onChange(floatValue);
            _setValue(null);
        }
    }

    return (
        <div>
            <TextField
                {...props}
                onChange={onChangeInput}
                value={_value || `${value}`}
                classes={textFieldClasses}
                variant="outlined"
                InputProps={{
                    classes: inputClasses
                }}
                style = {fieldCellStyle}
            />
            <span> kg / ({getPoundsFromKg(value)} lbs)</span>
        </div>
    )
}

const TextFieldIntComponent = ({value, onChange, ...props}: any) => {
    const textFieldClasses = useTextFieldStyles()
    const inputClasses = useInputStyles()

    const [ _value, _setValue ] = useState();


    const onChangeInput = ({ target: { value }}) => {
        const floatValue = parseFloat(value);

        if (!_value && value.endsWith('.')) {
            _setValue(value);
        } else {
            onChange(floatValue);
            _setValue(null);
        }
    }


    return (
        <TextField
            {...props}
            onChange={onChangeInput}
            value={_value || `${value}`}
            classes={textFieldClasses}
            variant="outlined"
             InputProps={{
                classes: inputClasses
            }}
        />
    )
}

export default ({ config, onChange, ...props } : { onChange: (value: string) => any, config: { textType?: 'measure' } & any}) => {

    if (config.type !== 'text') {
        return null;
    }
    var disabled = false
    if ( config.disabled ) {
        disabled = config.disabled
    }
    switch(config.textType) {
        case 'measure':
            return (
                <TextFieldMeasureComponent disabled={disabled} {...props} onChange={onChange} />
            )
        case 'measure_m2':
            return (
                <TextFieldMeasureM2Component {...props} onChange={onChange} />
            )
        case 'weight':
            return (
                <TextFieldWeightComponent {...props} onChange={onChange} />
            )
        case 'int':
            return (
                <TextFieldIntComponent {...props} onChange={onChange} />
            )
        default:
            return (
                <TextFieldComponent
                    {...props}
                     onChange={onChange}
                     disabled={disabled} 
                />
            )
    }
}