import React from 'react';

import DataTable from 'components/DataTableWithHeader'

import {
    Edit as EditIcon
} from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import Image from 'components/ImgMultiple'
import Photos from 'data/Photos';


import { sortAlpha, sortPhotos } from 'Util/Sorting'


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Photos, actions, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();
    var images = []

    function sorting ( category, order ) {

        if ( category === "archive" ) {
            data.sort(function (a, b) {
                return sortPhotos(a[category], b[category], order)
            });
        } else {
            data.sort(function (a, b) {
                return sortAlpha(a[category], b[category], order)
            });
        }

        images = []
        data.forEach( photo => {
            images.push(photo["filename"])
        }) 
    }

    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: photo => {
                       window.open("/photo/" + photo.objectid)
                    },
                    cells: [
                        { title: t('archive'), key: 'archive', bold:true },
                        { title: t('photo'), key: 'filename', sort:false },
                        { title: t('photographe'), key: 'photographe', sort:false },
                        { title: t('copyright'), key: 'copyright', sort:false },
                        { title: t('identification_personnes_oeuvres'), key: 'identification', sort:false },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, photo) => {
                                history.push("/photo/" + photo.objectid);
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name,index) => {

                    if (attr_name === "filename") {

                        //console.log("coucou : ", item)
                         //       console.log("getCell : ", images)

                        return (
                            <Image
                                imageWidth={60}
                                images = {images}
                                startIndex = {index}
                                style={{justifyContent: 'flex-start'}}
                                alt=""
                                filename={item.filename}
                            />
                        )
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>
    )
}