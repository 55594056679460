import React from 'react';
import DataTable from 'components/DataTableWithHeader'
import {
    Edit as EditIcon
} from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import Image from 'components/Img'
import Photos from 'data/Photos';

import { sortAlpha, sortPhotos } from 'Util/Sorting'


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Photos, actions, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();
    

    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if ( category === "annee" ) {

                if ( order === true ) {
                    if ( a[category] > b[category] ) {
                        return 1
                    }
                   return -1
                } else {
                    if ( a[category] > b[category] ) {
                        return -1
                    }
                   return 1
                }
            } else if ( (category === "photographe")) {

                return sortAlpha(a[category], b[category], order)
           
            } else if ( (category === "archive")) {

                return sortPhotos(a[category], b[category], order)
            }
            return -1
        });
    }
    
    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: (photo, index) => {
                        history.push("/photo/" + photo.objectid, {
                            context: "search_result",
                            search: {
                                index
                            }
                        });
                    },
                    cells: [
                        { title: t('archive'), key: 'archive', width:'120px', bold:true },
                        { title: t('photo'), key: 'filename', width:'80px', sort:false },
                        { title: t('annee'), key: 'annee', width:'80px' },
                        { title: t('photographe'), key: 'photographe', width:'300px' },
                        { title: t('copyright'), key: 'copyright', width:'300px', sort:false },
                        { title: t('localisation-exposition'), key: 'localisation', sort:false },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, photo) => {
                                window.open("/photo/" + photo.objectid)
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === "filename") {
                        return (
                            <Image
                                imageWidth={60}
                            style={{justifyContent: 'flex-start'}}
                                alt="" filename={item.filename}
                            />
                        )
                    }

                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}