import React, { useState } from 'react';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon } from '@material-ui/icons'

import DataTable from 'components/DataTable'
import OeuvreModal from 'pages/Avila/Modals/AddArtwork'
import ExpoOeuvreModal from 'pages/Avila/Modals/ExpoOeuvre'

import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver'
import { isOeuvreMultiple } from 'Util/Oeuvre'
import { deleteOeuvreExpo } from 'Api/expos'
import { insertImagesFilename } from 'Util/Export.js'
import { sortNumbers } from 'Util/Sorting'
import Image from 'components/Img'


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data, actions, reloadData = () => {}, onChange = () => {}, onClickEdit }) => {

    const [ addOeuvre, setAddOeuvre ] = useState(false);
    const [ editExpoOeuvre, setEditExpoOeuvre ] = useState(false);
    const { t } = useTranslation()


    const onClickAddOeuvre = () => {
        setAddOeuvre(true);
    }

    async function deleteOeuvreAsync(oeuvre_expo) {
        await deleteOeuvreExpo({ data : oeuvre_expo });
        onChange({
            ...data,
            oeuvre_expos: data.oeuvre_expos.filter(e => e.objectid !== oeuvre_expo.objectid)
        })
    }


    const onClickExportOeuvres = () => {
        saveAsExcel();
    }

    async function saveAsExcel() {
        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()

        ws.columns = [
            { header: 'Photo', key: 'photo', width: 20 },
            { header: 'Archive', key: 'Archive', width: 20 },
            { header: 'Titre', key: 'Titre', width: 60 },
            { header: 'Année', key: 'Année', width: 10 },
        ];
 
        const oeuvres = data.oeuvre_expos

        oeuvres.forEach(oeuvre => {

            const row = ws.addRow(["", oeuvre.archive, oeuvre.titre, oeuvre.annee_creation])
            row.font = { bold: false }
            row.height = 110;
        })

        await insertImagesFilename(wb, ws, oeuvres, 0)

        const buf = await wb.xlsx.writeBuffer()
        const filename = "Exposition " + data.archive + " Oeuvres.xlsx"

        saveAs(new Blob([buf]), filename)
    }


    data.oeuvre_expos.sort(function (a, b) {
        return sortNumbers(a["archive"], b["archive"], true)
    });


    console.log("oeuvre in expo :", data.oeuvre_expos)
    return (
    <Paper elevation={3}>

        {addOeuvre &&
            <OeuvreModal
                actions={[
                   {
                        key: 'add', icon: <AddIcon />, title: "Add",
                        onClick: (id, oeuvre) => {

                            setAddOeuvre(false)
                            setEditExpoOeuvre({"id_oeuvre" : oeuvre.objectid, "archive" : oeuvre.archive, "titre" : oeuvre.titre, "isMultiple" : isOeuvreMultiple(oeuvre)})
                        }
                    }
                ]}
                filterData={data.oeuvreExpos}
                expoId={data.objectid}
                onClose={() => {
                    setAddOeuvre(false)
                }}
            />
        }

        {editExpoOeuvre &&
            <ExpoOeuvreModal
                data={editExpoOeuvre}
                expoId={data.objectid}
                expo={data}
                onClose={() => {
                    setEditExpoOeuvre(false)
                }}
                onSave={ reloadData }
            />
        }

        <DataTable
            data={data.oeuvre_expos}
                headerActions={[
                    <Button key="add" onClick={onClickAddOeuvre}  variant="contained">Ajouter une oeuvre</Button>,
                    <Button style={{marginLeft:'10px'}} key="export" onClick={onClickExportOeuvres} variant="contained">Export</Button>
                ]}
            config={{
                onClickRow: oeuvre_expo => {
                        window.open("/artwork/" + oeuvre_expo.id_oeuvre)
                    },
                cells: [
                    { title: t('archive'), key: 'archive' },
                    { title: t('photo'), key: 'filename' },
                    { title: t('title'), key: 'titre' },
                    { title: t('exemplaire'), key: 'multiple' },
                    { title: t('creation_year'), key: 'annee_creation', sort:false },
                    { title: t('remarques'), key: 'remarques' }
                ],
                actions: actions || [
                    { icon: <EditIcon />, title: "Edit", onClick: (id, oeuvre_expo) => setEditExpoOeuvre(oeuvre_expo) },
                    { icon: <DeleteIcon />, title: "Delete", onClick: (id, oeuvre_expo) => {  
                        if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                            deleteOeuvreAsync(oeuvre_expo)
                        }
                    }}
                ]
            }}
            getCell={(item, attr_name) => {

                if (attr_name === "filename") {
                    return (
                        <Image
                            imageWidth={60}
                            style={{justifyContent: 'flex-start'}}
                            alt="" filename={item.filename}
                        />
                    )
                }  
                return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
            }}
        />
    </Paper>
    )
}