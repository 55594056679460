import React, { useState } from 'react';
import {
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next';

import {
    Button
} from '@material-ui/core';

import ExpoModal from 'pages/Avila/Modals/AddExpo'
import ExpoListLayout from 'pages/Avila/Expo/List/layout_oeuvre'

import { country } from 'Util/Listes'
import { isOeuvreMultiple } from 'Util/Oeuvre'
import { saveAs } from 'file-saver'
import { deleteOeuvreExpo, createOeuvreExpo, updateOeuvre } from 'Api';
import { sortAlphaNum} from 'Util/Sorting'
import Moment from 'moment';

export default ({ data, actions, reloadData = () => {}, onChange = () => {}, onClickEdit }) => {

    const [ addExpoModal, setAddExpoModal ] = useState(null);

    const onClickAddExpo = () => { setAddExpoModal(true); }
   

   const { t } = useTranslation()
        data.oeuvre_expos.sort(function (a, b) {

        if ( a.archive && b.archive ) {
            return sortAlphaNum(a.archive.toLowerCase(), b.archive.toLowerCase())
        }
        return -1
    });

    async function saveAsExcel() {
        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()
        
        ws.columns = [
            { header: 'Genre', key: 'Genre', width: 20 },
            { header: 'Titre', key: 'Titre', width: 50 },
            { header: 'Lieu', key: 'Lieu', width: 35 },
            { header: 'Ville', key: 'Ville', width: 15 },
            { header: 'Pays', key: 'Pays', width: 15 },
            { header: 'Date de début', key: 'Date de début', width: 10 },
            { header: 'Date de fin', key: 'Date de fin', width: 10 },
        ];

        const expos = data.oeuvre_expos

//        console.log('export expos : ', expos)
        expos.forEach(expo => {

            const fullCountry = country(expo.pays)
            var genre_string = ""

            if ( expo["type_expos"] ) {
                expo["type_expos"].forEach( type => {
                    genre_string = genre_string + type.name + ", "
                })
            }

            var date_debut = ""
            var date_fin =  ""

            if ( expo.date_debut ) {
                date_debut = Moment(expo.date_debut).format('DD-MM-YYYY')
            }      
            if ( expo.date_fin ) {
                date_fin = Moment(expo.date_fin).format('DD-MM-YYYY')
            }
            

            if ( expo.circa_debut === 1 ) {
                date_debut = ""
            }
            if ( expo.circa_fin === 1 ) {
                date_fin = ""
            }

            const row = ws.addRow([
                genre_string,
                expo.titre,
                expo.lieu,
                expo.ville,
                fullCountry,
                date_debut,
                date_fin])
            row.font = { bold: false }

        })

        const buf = await wb.xlsx.writeBuffer()
        const filename = "Expositions Oeuvre #" + data.archive + ".xlsx"

        saveAs(new Blob([buf]), filename)
    }

    async function saveData(expoId, expo) {
                
        const res = await await createOeuvreExpo({expoId, data:expo})
        expo.objectid = res.objectId
        data = { ...data,
            oeuvre_expos: [
                ...data.oeuvre_expos,
                expo
            ]
        }

        await updateOeuvre({id:data.objectid, data})
        reloadData()
        setAddExpoModal(false)
    }

    async function deleteOeuvreExpoAsync(oeuvre_expo) {
        await deleteOeuvreExpo({ data: oeuvre_expo });

        data = {
            ...data,
            oeuvre_expos: data.oeuvre_expos.filter(e => e.objectid !== oeuvre_expo.objectid)
        }
        await updateOeuvre({id:data.objectid, data})
        reloadData()
    }
    
//        console.log("expos : ", data.oeuvre_expos)

    return (

        <>
            {addExpoModal &&
                <ExpoModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, expo) => {
                                saveData(expo.objectid, {"id_oeuvre" : data.objectid, "archive" : data.archive, "titre" : data.titre, "isMultiple" : isOeuvreMultiple(data)})
                            }
                        }
                    ]}
                    filterData={data.oeuvre_expos}
                    onClose={() => {
                        setAddExpoModal(false)
                    }}
                />
            }

            <ExpoListLayout
                data={data.oeuvre_expos}
                headerActions={[
                    <Button key="add" onClick={onClickAddExpo} variant="contained">{t('add_expo')}</Button>,
                    <Button style={{marginLeft:'10px'}} key="export" onClick={saveAsExcel} variant="contained">Export</Button>
                ]}
                actions={[
                    {
                        key: 'delete', icon: <DeleteIcon />, title: "Delete",
                        onClick: (objectid, oeuvre_expo) => {
                            if (window.confirm(`Supprimer ${oeuvre_expo.titre} ?`)) {
                                deleteOeuvreExpoAsync(oeuvre_expo)
                            }
                        }
                    }
                ]}
            />
        </>
    )
}











