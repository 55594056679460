// @flow
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'


type Props = {
    data: any,
    onChange: any => any
}

export default ({ data, expoId, isMultiple, onChange }: Props) => {
    
    const { t } = useTranslation();

//    console.log(data)
    
   return (
        <React.Fragment>

            <Form
                form={[
                
                    { label: t("archive"), attr: "archive", type: "display", size: { label: 150, field:400 }},
                    { label: t("title"), attr: "titre", type: "display", size: { label: 150, field:400 }},
                    ...(data.isMultiple ? [
                        { label: t("numero_exemplaire"), attr: "multiple", type: "text", size: { label: 150, field:100 }},
                    ] : []),
                    { label: t("remarques"), attr: "remarques", type: "text", multi:3 ,size: { label: 150, field:400 }},

                ]}
                data={data}
                onChange={onChange}
            />

        </React.Fragment>

    )
}
