

import COUNTRIES from '../components/Selectors/Countries/countries.json'



export const country = (code) => {

    var foundCountry = ""
    
    COUNTRIES.forEach( country => {

        if ( country.value === code ) {
            foundCountry = country.loc["fr"]
        }
    })
    return foundCountry
}

export const indexInListeDesLocalisations = ( localisation ) => {

    const liste = listeDesLocalisations()
    var index = 0;
    var foundIndex = -1

    liste.forEach( newLocalisation => {

        if ( newLocalisation === localisation ) {
            foundIndex = index
        }
        index = index + 1
    })
    return foundIndex
}


export const listeDesLocalisations = () => {

    return [
        "Avila, Decaen D",
        "Avila, Decaen D Grille 1",
        "Avila, Decaen D Grille 2",
        "Avila, Decaen D Grille 3",
        "Avila, Decaen D Grille 4",
        "Avila, Decaen D Grille 5",
        "Avila, Decaen D Grille 6",
        "Avila, Decaen D Grille 7",
        "Avila, Decaen D Grille 8",
        "Avila, Decaen D Grille 9",
        "Avila, Decaen D Grille 10",
        "Avila, Decaen D Grille 11",
        "Avila, Decaen G",
        "Avila, Gentilly A",
        "Avila, Gentilly B",
        "Avila, Gentilly C",
        "Avila, Gentilly D",
        "Avila, Gentilly E",
        "Avila, Gentilly F",
        "Avila, Gentilly G",
        "Avila, Gentilly H",
        "Avila, Gentilly I",
        "Avila, Gentilly J",
        "Avila, Gentilly K",
        "Avila, Gentilly L",
        "Avila, Gentilly M",
        "Avila, Gentilly N",
        "Avila, Gentilly O",
        "Avila, Gentilly O Charriots",
        "Avila, Gentilly P",
        "Avila, Gentilly Q",
        "Avila, Gentilly R",
        "Avila, Gentilly S",
        "Avila, Gentilly T",
        "Avila, Gentilly U",
        "Avila, Gentilly V",
        "Avila, Villehardouin, Archives",
        "Avila, Villehardouin, RDC D",
        "Avila, Villehardouin, RDC G",
        "Avila, Villehardouin, Cave",
        "Avila, Villehardouin, Multisalon",
        "Avila, Villehardouin, Salon",
        "Avila, Villehardouin, Bunker",
        "Avila, Villehardouin, Comité",
        "Fundación SOTO, Caracas",
        "Museo Soto",
        "Quinta Lilia, Depósito",
        "Quinta Lilia, Sala",
        "Quinta Lilia, Vestier",
        "Quinta Lilia, 1er piso",
        "Walter Pellevoisin"
    ]
}

export const listeDesLocalisationsSearch = () => {

    return [
        "Avila, Decaen D",
        "Avila, Decaen D Grille 1",
        "Avila, Decaen D Grille 2",
        "Avila, Decaen D Grille 3",
        "Avila, Decaen D Grille 4",
        "Avila, Decaen D Grille 5",
        "Avila, Decaen D Grille 6",
        "Avila, Decaen D Grille 7",
        "Avila, Decaen D Grille 8",
        "Avila, Decaen D Grille 9",
        "Avila, Decaen D Grille 10",
        "Avila, Decaen D Grille 11",
        "Avila, Decaen G",
        "Avila, Gentilly A",
        "Avila, Gentilly B",
        "Avila, Gentilly C",
        "Avila, Gentilly D",
        "Avila, Gentilly E",
        "Avila, Gentilly F",
        "Avila, Gentilly G",
        "Avila, Gentilly H",
        "Avila, Gentilly I",
        "Avila, Gentilly J",
        "Avila, Gentilly K",
        "Avila, Gentilly L",
        "Avila, Gentilly M",
        "Avila, Gentilly N",
        "Avila, Gentilly O",
        "Avila, Gentilly O Charriots",
        "Avila, Gentilly P",
        "Avila, Gentilly Q",
        "Avila, Gentilly R",
        "Avila, Gentilly S",
        "Avila, Gentilly T",
        "Avila, Gentilly U",
        "Avila, Gentilly V",
        "Avila, Villehardouin, Archives",
        "Avila, Villehardouin RDC D",
        "Avila, Villehardouin RDC G",
        "Avila, Villehardouin, Cave",
        "Avila, Villehardouin, Multisalon",
        "Avila, Villehardouin, Salon",
        "Avila, Villehardouin, Bunker",
        "Avila, Villehardouin, Comité",
        "Fundación SOTO, Caracas",
        "Museo Soto",
        "Quinta Lilia, Depósito",
        "Quinta Lilia, Sala",
        "Quinta Lilia, Vestier",
        "Quinta Lilia, 1er piso",
        "Walter Pellevoisin"
    ]
}

export const villeDesLocalisations = () => {

    return [
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Gentilly",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Paris",
        "Caracas",
        "Ciudad Bolivar",
        "Caracas",
        "Caracas",
        "Caracas",
        "Caracas",
        "St Loubouer",

    ]
}

export const paysDesLocalisations = () => {

    return [
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "fr",
        "ve",
        "ve",
        "ve",
        "ve",
        "ve",
        "ve",
        "fr"
    ]
}

export const emplacementDesLocalisations = () => {

    return [
        "Meuble à plans, Tiroir 1",
        "Meuble à plans, Tiroir 2",
        "Meuble à plans, Tiroir 3",
        "Meuble à plans, Tiroir 4",
        "Meuble à plans, Tiroir 5",
        "Meuble à plans, Tiroir 6",
        "Meuble à plans, Tiroir 7",
        "Meuble à plans, Tiroir 8",
        "Meuble à plans, Tiroir 9",
        "Meuble à plans, Tiroir 10",
        "Rayonnage métallique",
        "Placard",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J"
    ]
}




