import React, { useState } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

import {
    FlexRowSpaceBetween
} from 'layouts/Helpers/flexbox'



const TableCell = styled(MuiTableCell)`
    padding: 0px !important;
`;

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },


  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});


const filterCells = (cells, hideCells = []) => {
	return cells.filter(cell => hideCells.indexOf(cell.key));
}


const DataTableWithHeader = (props) => {

	const { config = {}, data = [], defaultOrderBy, onRequestSort, classes, headerActions } = props;
	const { cells = [] } = config;

   	const [pageIndex, setPageIndex] = useState(0);
   	const [rowsPerPage, setRowsPerPage] = useState(50);

   	const [orderBy, setOrderBy] = useState(defaultOrderBy);
   	const [order, setOrder] = useState('asc');


	const renderData = data => {

		const { getSeparator = (el1, el2) => null } = props;

		const rows = [];

		for (let i = 0; i < data.length; i++) {
			const el = data[i];
			const previous_el = i === 0 ? null : data[i-1];

			const separator = getSeparator(previous_el, el);

			if (separator !== null) {
				rows.push(
					<TableRow key={el.id + "_separator"}>
						<TableCell align="left" colSpan={getNbCells()}>
							{separator}
						</TableCell>
					</TableRow>
				);
			}

			rows.push(renderRow(el, i+ rowsPerPage*pageIndex))
		}

		return rows;
	}

	const getNbCells = () => {
		const { config = {} } = props;
		const { cells = [], hideCells = [], actions = [] } = config;

		return filterCells(cells, hideCells).length + (actions.length > 0 ? 1 : 0);
	}

	const renderRow = (el, index) => {
		const { getCell = (el, key) => key, config = {} } = props;
		const { cells = [], hideCells = [], actions = [], onClickRow } = config;

		return (
			<TableRow key={el.id + "_" + index} style={onClickRow ? {cursor: "pointer"} : {}}>

				{filterCells(cells, hideCells).map((cell, i) =>
					<TableCell key={cell.key + i} style={{
						...(cell.noPadding ? {padding:0} : {}), width:cell.width, ...(cell.bold ? {fontWeight: 'bold'} : {})
					}} onClick={onClickRow ? () => onClickRow(el, index) : () => {}}>{getCell(el, cell.key, index)}</TableCell>
				)}

				{actions.length > 0 &&

					<TableCell align="right">

						{actions.map(_action => {

							if (typeof _action === 'function') {
								_action = _action(el);
							}

							if (React.isValidElement(_action)) {
								return _action;
							}

							if (!_action) {
								return null;
							}

							let { Component = null, onClick = () => {}, ...action } = _action

							const actionContent = (
								<IconButton aria-label={action.title || ''} onClick={e => {
									onClick(el.id, el, index);
								}}>
									{action.icon}
								</IconButton>
							)

							if (Component === null) {
								Component = React.Fragment;

								return <Component key={action.key || action.title + action.icon_name}>{actionContent}</Component>
							}

							return <Component object={el} key={action.key || action.title + action.icon_name}>{actionContent}</Component>
						})}

					</TableCell>
				}
			</TableRow>
		)
	}

	const renderPagination = () => {

		const { data = [] } = props;

		return (
			<TablePagination
				colSpan={getNbCells()}
				count={data.length}
				rowsPerPageOptions={[5, 20, 50, 100]}
				rowsPerPage={rowsPerPage}
				page={pageIndex}
				onChangePage={(el, pageIndex) => setPageIndex( pageIndex )}
				onChangeRowsPerPage={ ({ target: { value: rowsPerPage } }) => setRowsPerPage( rowsPerPage )}
			/>
		)
	}

	const getDataFromPaginationOptions = data => {

		const start = rowsPerPage * pageIndex;
		const end = start + rowsPerPage;

		return data.slice(start, end);
	}


	const renderHeader = (classes, cells, onRequestSort) => {
  	
 		const createSortHandler = (property) => (event) => {
    	   	const isAsc = orderBy === property && order === 'asc';
   			setOrder(isAsc ? 'desc' : 'asc');
    		setOrderBy(property);
    	//	onRequestSort(property, isAsc);
  		};

 		return (
	    	<TableHead>
	     		<TableRow>

	        		{cells.map((headCell) => (
	          			<TableCell
	                       key={headCell.title}
	                       align={headCell.numeric ? 'right' : 'left'}
	                       padding={headCell.disablePadding ? 'none' : 'default'}
	                       sortDirection={orderBy === headCell.key ? order : false}
	                       style = {{marginLeft:20}}
                        >

                        { headCell.sort === false ? headCell.title : 
	                  		<TableSortLabel
	                       		active={orderBy === headCell.key}
                            	direction={orderBy === headCell.key ? order : 'asc'}
                            	onClick={createSortHandler(headCell.key)}
                        	>
                            	{headCell.title}
                            	{orderBy === headCell.title ? (
                                	<span className={classes.visuallyHidden}>
                                    	{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                	</span>
                            	) : null}
                        	</TableSortLabel>
                        }
                    	</TableCell>
	        		))}
	    		</TableRow>
	    	</TableHead>
 		);
	}

	const isAsc = order === 'asc';
    onRequestSort(orderBy, isAsc);

	return (
		<Paper elevation={0} className={classes.root}>

			{((headerActions && headerActions.length)) &&
				<FlexRowSpaceBetween style={{marginBottom: '20px'}}>
					<div>
						{headerActions}
					</div>
				</FlexRowSpaceBetween>
			}

			<Table className={classes.table}>
            	{renderHeader(classes, cells, onRequestSort)}

				<TableBody>
					{renderData(getDataFromPaginationOptions(data))}
				</TableBody>

				<TableFooter>
					<TableRow>
						{renderPagination()}
					</TableRow>

				</TableFooter>
			</Table>
		</Paper>
	)
}


export default withStyles(styles)(DataTableWithHeader);