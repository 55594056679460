import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { useDropzone } from 'react-dropzone';
import Style from './styles.module.css';
import classNames from 'classnames';
import axios from 'axios';
import {
    LinearProgress
} from '@material-ui/core'

import getConfig from 'config';

const uploadFile = async ({ file, onProgress = () => {} }) => {
    const config = {
        onUploadProgress: (progressEvent) => {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

            onProgress(percentCompleted);
        }
    }

    let data = new FormData()
    data.append('uploaded_file', file);
    const rs = await axios.post(getConfig().UPLOAD_URL, data, config)
    return rs.data;
}

export default React.forwardRef(
    ({
        style = {},
        className = null,
        disabled = false,
        children = null,
        onUploaded = () => {},
        onUploadStart = () => {},
        onProgress = () => {},
        onFileDialogCancel= () => {},
        ...inputProps
    }, ref) => {

        const [uploadPercentage, setUploadPercentage] = useState(0)
        const [displayProgress, setDisplayProgress] = useState(false)
        const inputRef = useRef(null);

        const mounted = useRef(false);
        useEffect(() => {
            mounted.current = true;
            return () => (mounted.current = false);
        }, []);

        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop: async acceptedFiles => {
                setDisplayProgress(true);
                onUploadStart()

                const data = await uploadFile({ file: acceptedFiles[0], onProgress: percent => {
                    setUploadPercentage(percent);
                    onProgress(percent);
                } });
                onUploaded(data);
                window.setTimeout(() => {
                    if (!mounted.current) {
                        return;
                    }
                    setDisplayProgress(false);
                }, 1000)
            },

            onFileDialogCancel: () => {
                console.log("CANCELED");
                onFileDialogCancel();
            }
        });

        const onClick = () => {
            inputRef.current.click();
        }

        useImperativeHandle(ref, () => ({
            triggerFileSelection: onClick
        }))

        return (
            <div
                {...getRootProps({
                    className: classNames(Style.dropzone, {
                        [Style.dropzoneDragging]: isDragActive,
                        [Style.dropzoneNoPadding]: children !== null
                    }, className)
                })}
                onClick={disabled ? () => {} : onClick}
                style={style}
            >
                {displayProgress &&
                    <LinearProgress variant="determinate" className={Style.progress} value={uploadPercentage} />
                }
                <input
                    hidden {...getInputProps()}
                    ref={inputRef}
                    disabled={disabled}
                    {...inputProps}
                />
                {children}
            </div>
        )
    }
)