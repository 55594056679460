import React from 'react';
import DataTable from 'components/DataTableWithHeader'
import {
    Edit as EditIcon,
} from '@material-ui/icons'
import ArtWorks from 'data/ArtWorks';
import MuiPaper from '@material-ui/core/Paper';

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components';
import { sortAlpha, sortNumbers } from 'Util/Sorting'


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = ArtWorks, actions, onChange, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();
    
     data.sort(function (a, b) {
        return a.archive < b.archive ? -1 : 1
    });


    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if ( (category === "archive") ) {

                return sortNumbers(a[category], b[category], order)
                
            } else if ( (category === "titre")) {

                return sortAlpha(a[category], b[category], order)

            }
            return -1
        });
    }

    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: (multiple, index) => {
                        history.push("/artwork/" + multiple.oeuvre_objectid, {
                            context: "search_result",
                            search: {
                                index
                            }
                        });

                    },
                    cells: [
                        { title: t('archive'), key: 'archive', bold:true },
                        { title: t('title'), key: 'titre' },
                        { title: t('exemplaire'), key: 'numero', bold:true },
                        { title: t('etat_administratif'), key: 'etat'},
                        { title: t('etat_de_conservation'), key: 'etat_de_conservation' },

                        { title: t('emplacement'), key: 'emplacement' },
                        { title: t('derniere_localisation'), key: 'derniere_localisation' },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, multiple) => {
                                console.log("click : ", multiple)
                             //   window.open("/artwork/" + multiple.oeuvre_objectid)
                                history.push("/artwork/" + multiple.oeuvre_objectid);
                            }
                        },
                    ]

                }}
                getCell={(item, attr_name) => {
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>

    )
}