import React from 'react';
import DataTable from 'components/DataTable'
import { useTranslation } from 'react-i18next'
import Moment from 'moment';
import { globalLocalisations } from 'Util/OeuvreGlobalLocalisation';
import { country } from "Util/Listes"
import { sortDate } from 'Util/Sorting'



export default ({ selectedMultiples, data, isMultiple, reloadData = () => {}, actions, onChange = () => {}, onClickEdit }) => {

    const { t } = useTranslation();
    
    const foundLocalisations = globalLocalisations(data, selectedMultiples)

        foundLocalisations.sort(function (a, b) {
            return sortDate(a["date_localisation"], b["date_localisation"], false)
        });

 //   console.log("foundLocalisations : ", foundLocalisations)

    return (
        <>
            <DataTable
                data={foundLocalisations}
                config={{
                    cells: [
                        { title: t('date'), key: 'date_localisation', width: 60},
                        ...(isMultiple ? [
                            { title: t('exemplaire'), key: 'multiple', width: 50 },
                        ] : [] ),
                        { title: t('type'), key: 'type', width: 50 },
                        { title: t('lieu'), key: 'localisation', width: 300 },
                        { title: t('ville'), key: 'ville', width: 50 },
                        { title: t('pays'), key: 'pays', width: 100 },
                    ],

                }}
                getCell={(item, attr_name) => {

                    if (attr_name === 'date_localisation') {
                        return Moment(item['date_localisation']).format('DD-MM-YYYY')

                    } else if ( attr_name === 'pays' ) {
                        return country(item['pays'])
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </>
    )

}