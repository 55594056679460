// @flow
import React from 'react';

import { useTranslation } from 'react-i18next'
import { updateMultiple, createMultipleAndLocalisationUpdateOeuvre } from 'Api';

import NewMultipleForm from 'pages/Avila/NewMultiple/Form'

import FormModal from './FormModal'
import { listeDesExemplaires }  from "Util/OeuvreMultiple"


type Props = {
    oeuvreid: string,
    onClose: any => any,
    actions: Array<any>,
    data: any,
    onSave: any => any
}

export default ({
    onClose,
    actions = [],
    data,
    oeuvre,
    oeuvreid,
    onSave = () => {}
}: Props) => {

    const { t } = useTranslation();

    return (

        <FormModal
            title={t("exemplaire")}
            data={data}
            onClose={onClose}
            FormComponent={({ data, onChange }) => (
                <NewMultipleForm
                    data={data}
                    onChange={onChange}
                />
            )}

            onSave={onSave}
            updateMethod={({ data }) => {
             /*   const listeExemplaires = listeDesExemplaires(oeuvre.multiples)
                const exemplaire = data.numero
                
                console.log("listeExemplaires : ", listeExemplaires)
                console.log("exemplaire : ", exemplaire)

                if (listeExemplaires.indexOf(exemplaire) > -1) {
                    alert("Il exsite déjà un exemplaire avec ce numéro.")
                    return false;
                } else {
                    //Not in the array
                }*/
                updateMultiple({ oeuvreid, data })}
            }
            createMethod={({ data }) => {

                const listeExemplaires = listeDesExemplaires(oeuvre.multiples)
                const exemplaire = data.numero
                
                if (listeExemplaires.indexOf(exemplaire) > -1) {
                    alert("Il existe déjà un exemplaire avec ce numéro.")
                    return false;
                } else {
                    //Not in the array
                }

              /*  if (true) {
                    alert("Blabla")
                    return false;
                }*/
                createMultipleAndLocalisationUpdateOeuvre({oeuvre, data})}
            }
        />
    )
}