



export const filterEncheres = (encheres, selectedMultiples) => {

     var filteredEncheres = []
     const keys = Object.keys(selectedMultiples)

     keys.forEach(multiple => {

        if ( selectedMultiples[multiple] === true ) {

            encheres.forEach(enchere => {
                if ( enchere.multiple === multiple ) {
                    filteredEncheres.push(enchere)
                }
            })
        }
     })

     return filteredEncheres
}

