import React, { useState } from 'react';
import {
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons'

import {
    Button
} from '@material-ui/core';

import PhotoModal from 'pages/Avila/Modals/AddPhoto'
import PhotoListLayout from 'pages/Avila/Photo/List/layout_oeuvre'
import Image from 'components/Img'
import { updateOeuvre } from 'Api';
import { useTranslation } from 'react-i18next';


export default ({ data, actions, reloadData = () => {}, onChange = () => {}, onClickEdit }) => {

    const [ addPhotoModal, setAddPhotoModal ] = useState(null);
    const { t } = useTranslation()

    const onClickAddPhoto = () => {
        setAddPhotoModal(true);
    }

    async function saveData(data) {
      
        await updateOeuvre({id:data.objectid, data})
        reloadData()
        setAddPhotoModal(false);
    }

    return (

        <>
            {addPhotoModal &&
                <PhotoModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, photo) => {

                                saveData({ ...data, photos: [ ...data.photos, photo ] })
                              /*  onChange({
                                    ...data,
                                    photos: [
                                        ...data.photos,
                                        photo
                                    ]
                                })
                                setAddPhotoModal(false);*/
                            }
                        }
                    ]}
                    filterData={data.photos}
                    onClose={() => {
                        setAddPhotoModal(false)
                    }}
                />
            }
            <PhotoListLayout
                data={data.photos}
                headerActions={[
                    <Button key="add" onClick={onClickAddPhoto} variant="contained">{t('add_photo')}</Button>
                ]}
                actions={[
                    {
                        key: 'delete', icon: <DeleteIcon />, title: "Delete",
                        onClick: (id, photo) => {
                            if (window.confirm(`Supprimer ${photo.titre} ?`)) {

                                saveData({
                                    ...data,
                                    photos: data.photos.filter(e => e.objectid !== photo.objectid)
                                })
                            }
                        }
                    }
                ]}
                getCell={(item, attr_name) => {

                    if (attr_name === "filename") {
                        return (
                            <Image
                                imageWidth={60}
                            style={{justifyContent: 'flex-start'}}
                                alt="" filename={item.filename}
                            />
                        )
                    }

                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </>
    )
}









