

export const displayBigNumber = number => {


    return number

}

export const cleanNumber = numberString => {

	if ( typeof(numberString) == "number" ) {
		return numberString
	} else if ( typeof(numberString) == "string" ) {
		if ( numberString ) {

			const cleanNumberString = numberString.replace ( /[^0-9]/g, '' );
			if ( cleanNumberString === "" ) {
				return 0
			}
	   		return parseInt(cleanNumberString)
	   	}
	}
	return 0
}


export const cleanBigNumber = numberString => {

	if ( typeof(numberString) == "number" ) {
		return numberString
	} else if ( typeof(numberString) == "string" ) {
		if ( numberString ) {

	    	var cleanNumber = numberString.replaceAll(',', '');
	    	cleanNumber = cleanNumber.replaceAll('.', '');

	   		return cleanNumber
	   	}
	}
	return null
}


export const parseBigNumber = numberString => {

    return numberString

}


export const getDimensions = item => {
    var dimensions = ""

	if ( item["largeur"] ) {
		dimensions = item["largeur"] + " x "
	} else {
		dimensions = "largeur x "
	}
	if ( item["profondeur"] ) {
		dimensions = dimensions + item["profondeur"] + " x "
	} else {
		dimensions = dimensions + "profondeur x "
	}
	if ( item["hauteur"] ) {
		dimensions = dimensions + item["hauteur"]
	} else {
		dimensions = dimensions + "hauteur"
	}
	return dimensions
}


