import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom'

import {
    CircularProgress
} from '@material-ui/core'

import PhotoLayout from './index';
import SearchNav from 'components/SearchNav'

import { getPhotoById, updatePhoto } from 'Api'
import { useHistory } from 'react-router-dom'

export default () => {

    const params = useParams();
    const id = params.id;
    const [ photo, setPhoto ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const location = useLocation();
    const context = (location.state && location.state.context) || null;
    const history = useHistory();


    const loadData = async (id) => {

        const photo = await getPhotoById({ id })

        setPhoto(photo.photo);
        setLoading(false);
    }

    useEffect(() => {
        loadData(id);
    }, [ id ])


    let content = null;

    if (loading) {
        content = <CircularProgress />
    } else if (!id || !photo) {
        content = "NOT FOUND"
    } else {
        content = (

            <PhotoLayout
                initialData={photo}
                reloadData={() => loadData(id)}
                saveData={async data => {
                    await updatePhoto({ id: data.objectid, data });
                    return true;
                }}
                onSaved={() => {
                    loadData(id);
                }}
                onDelete={() => {
                    history.push("/photo");
                }}
            />
        )
    }
    return (
        <>
            {context === 'search_result' &&
                <SearchNav
                    search_type={"photo"}
                    constructPath={photo => ("/photo/" + photo.objectid)}
                    searchPath={"/photo/search"}
                />
            }

            {content}

        </>
    )

}