import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Edit as EditIcon
} from '@material-ui/icons'

import Search from 'Api/Search/expo';
import DescriptionPanel from '../EditCreate/Description';
import ListLayout from '../List/layout_search'
import SearchLayout from 'layouts/SearchPage'
import { insertImagesFilename } from "Util/Export"
import { saveAs } from 'file-saver'
import { typesExpoString } from "Util/Expos"
import Moment from 'moment';
import { country } from 'Util/Listes'


export default () => {

    const history = useHistory();
    const objectType = 'expo';
    
    async function exportExpos(selectedList, expos) {

        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()
        
        ws.columns = [
            { header: 'Archive', key: 'archive', width: 15 },
            { header: 'Titre', key: 'titre', width: 50 },
            { header: 'Date de début', key: 'date_debut', width: 15 },
            { header: 'Date de fin', key: 'date_fin', width: 15 },
            { header: 'Lieu', key: 'lieu', width: 50 },
            { header: 'Ville', key: 'ville', width: 20 },
            { header: 'Pays', key: 'pays', width: 20 },
            { header: 'Type', key: 'type', width: 30 },
            { header: 'Participants', key: 'participants', width: 50 },
            { header: 'Commissaire', key: 'commissaire', width: 50 },
        ];
 
        expos.forEach(expo => {

            console.log("expo : ", expo)

            const type_expo = typesExpoString(expo.typeexpo_string)
            const date_debut = Moment(expo['date_debut']).format('DD-MM-YYYY')
            const date_fin = Moment(expo['date_fin']).format('DD-MM-YYYY')
            const pays = country(expo.pays)

            const row = ws.addRow([
                    expo.archive,
                    expo.titre,
                    date_debut,
                    date_fin,
                    expo.lieu,
                    expo.ville,
                    pays,
                    type_expo,
                    expo.artistes,
                    expo.commissaire])
            row.font = { bold: false }
        })

        await insertImagesFilename(wb, ws, expos, 0)

        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), "Expos.xlsx")
    }

    return (
        <SearchLayout
            exportList={[{"value":"expositions", "name":"Liste expos"},]}
            onClickExport={exportExpos}
            objectType={objectType}
            title={"Rechercher une expo"}
            search={Search}
            SearchFormComponent={props => (
                <DescriptionPanel layout="search" {...props} />
            )}
            ResultListComponent={({ data }) => (
                <ListLayout
                    data={data}
                    actions={[
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, object, index) => {
                                window.open("/"+objectType+"/" + object.objectid, {
                                    context: "search_result",
                                    search: {
                                        index
                                    }
                                });
                            }
                        }
                    ]}
                />
            )}

            onFeelingLucky={oeuvre => history.push("/"+objectType+"/"+oeuvre.objectid)}
        />
    )
}