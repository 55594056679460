import React from 'react';
import { useHistory } from 'react-router-dom'
import { createBiblio } from 'Api'
import BiblioLayout from './index';

export default () => {

    const history = useHistory();

    return (
        <BiblioLayout
            layout="create"
            saveData={async data => {

                const result = await createBiblio({ data });
                return result;
            }}
            onSaved={biblio => {
                history.push("/biblio/" + biblio.objectId);
            }}
        />
    )
}