// @flow
import React from 'react';

import { createAssuranceUpdateOeuvre, updateAssurance } from 'Api';
import AssuranceForm from 'pages/Avila/Assurance/Form'

import FormModal from './FormModal'

type Props = {
    oeuvreid: string,
    onClose: any => any,
    actions: Array<any>,
    data: any,
    onSave: any => any
}
export default ({
    onClose,
    actions = [],
    data,
    oeuvre,
    oeuvreid,
    onSave = () => {}
}: Props) => {

    return (
        <FormModal
            title="Assurance"
            data={data}
            onClose={onClose}
            FormComponent={AssuranceForm}

            onSave={onSave}
            updateMethod={({ data }) => updateAssurance({ oeuvreid, data })}
            createMethod={({ data }) => createAssuranceUpdateOeuvre({ oeuvre, data })}
        />
    )
}