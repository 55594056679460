import React, { useState } from 'react';

import {
    Delete as DeleteIcon,
    Add as AddIcon,
} from '@material-ui/icons'

import OeuvreSoeurModal from 'pages/Avila/Modals/AddOeuvreSoeur'
import DataTable from 'components/DataTable'
import { useTranslation } from 'react-i18next'
import Image from 'components/Img'

import { updateOeuvre } from 'Api/artworks'
import { sortNumbers } from 'Util/Sorting'

import {
    Button
} from '@material-ui/core';


export default ({ data, isComitee, actions, reloadData = () => {}, onChange = () => {}, onClickEdit }) => {

    const [ addOeuvreModal, setAddOeuvreModal ] = useState(null);
    const { t } = useTranslation();

    const onClickAddOeuvre = () => {
        setAddOeuvreModal(true);
    }

    async function updateOeuvreAsync(data) {
        await updateOeuvre({ id: data.objectid, data });
        onChange(data)
    }

    if ( data.soeurs ) {
        data.soeurs.sort(function (a, b) {
            return sortNumbers(a["archive"], b["archive"], false)
        });
    }
    return (

        <>
            {addOeuvreModal &&
                <OeuvreSoeurModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, oeuvre) => {
                                updateOeuvreAsync({
                                    ...data,
                                    soeurs: [
                                        ...data.soeurs,
                                        oeuvre
                                    ]
                                })
                                setAddOeuvreModal(false);
                            
                            }
                        }
                    ]}
                    filterData={data.soeurs}
                    onClose={() => {
                        setAddOeuvreModal(false)
                    }}

                />
            }

            <DataTable
                data={data.soeurs}
                headerActions={[
                    ...(isComitee ? [
                    <Button key="add" onClick={onClickAddOeuvre} variant="contained">{t("add_oeuvre")}</Button>
                    ] : [] )
                ]}
                config={{

                    cells: [
                        { title: t('nb_archive'), key: 'archive' },
                        { title: t('photo'), key: 'filename' },
                        { title: t('title'), key: 'titre' },
                        { title: t('creation_year'), key: 'annee_creation' }
                    ],
                    onClickRow: oeuvre => {
                        window.open("/artwork/" + oeuvre.objectid)
                    },
                    actions: actions || [
                        ...(isComitee ? [
                        { icon: <DeleteIcon />, title: "Delete", onClick: (id, oeuvre) => {  
                            if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                updateOeuvreAsync({
                                    ...data,
                                    soeurs: data.soeurs.filter(e => e.objectid !== oeuvre.objectid)
                                })
                            }
                        }}
                        ] : [])
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === "filename") {
                        return (
                            <Image
                                style={{justifyContent: 'flex-start'}}
                                imageWidth={60}
                                alt="" filename={item.filename}
                            />
                        )
                    }

                     return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </>
    )
}