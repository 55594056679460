import React from 'react';
import DataTable from 'components/DataTableWithHeader'
import Image from 'components/Img'
import TYPES from 'Assets/json/TypesOeuvre.json'
import {
    Button
} from '@material-ui/core';

import Moment from 'moment';
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'
import { getDimensions } from 'Util/Divers'
import { insertImagesFilename } from 'Util/Export'
import { sortNumbers, sortAlpha } from 'Util/Sorting'


export default ({ data, actions, onClickEdit }) => {
    
    const { t } = useTranslation();
    

    async function saveAsExcel() {
        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()
        
        ws.columns = [
            { header: 'Archive', key: 'archive', width: 5 },
            { header: 'Photo', key: 'photo', width: 30 },
            { header: 'Exemplaire', key: 'numero', width: 5 },
            { header: 'Titre', key: 'titre', width: 100 },
            { header: 'Date', key: 'date', width: 15 },
            { header: 'Dimensions', key: 'Dimensions', width: 15 },
        ];


        data.forEach(oeuvre => {

            var dimensions = getDimensions(oeuvre)

            const row = ws.addRow([oeuvre.archive, "", oeuvre.titre, oeuvre.annee_creation, dimensions])
            row.font = { bold: false }
            row.height = 100;

        })
        await insertImagesFilename(wb, ws, data, 1)
        
        const buf = await wb.xlsx.writeBuffer()
        const filename = "Contact.xlsx"

        saveAs(new Blob([buf]), filename)
    }


    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if ( (category === "archive") || (category === "annee_creation") || (category === "type_oeuvre")  ) {

                return sortNumbers(a[category], b[category], order)
            } 

            return sortAlpha(a[category], b[category], order)
        });
    }

    return (
        <>

        <DataTable
            data={data}
            defaultOrderBy={"archive"}
            onRequestSort={sorting}
            headerActions={[
                <Button style={{marginLeft:'10px'}} key="add" onClick={saveAsExcel} variant="contained">Export</Button>
            ]} 
            config={{
                cells: [
                    { title: t('archive'), key: 'archive', width:'100px' },
                    { title: t('exemplaire'), key: 'numero', width:'100px' },
                    { title: t('photo'), key: 'photo_items', width:'50px', sort:false },
                    { title: t('title'), key: 'titre', width:'400px' },
                    { title: t('annee_creation'), key: 'annee_creation', width:'80px' },
                    { title: t('type'), key: 'type_oeuvre', width:'100px' },
                    { title: t('date_acquisition'), key: 'date_historique', width:'80px', sort:false },
                    { title: t('transaction'), key: 'type', width:'100px', sort:false },
                ],
                onClickRow: oeuvre => {
                    window.open("/artwork/" + oeuvre.objectid)
                },

            }}
            getCell={(item, attr_name) => {

                if ( attr_name === "date_historique" ) {
                    if (item['date_historique']) {
                        return  Moment(item['date_historique']).format('YYYY')
                    }
                    return ""

                } else if ( attr_name === "type_oeuvre" ) {

                    const type = item["type_oeuvre"]-1

                    if ( TYPES[type] ) {
                        return TYPES[type].loc["fr"]
                    }
                    return "inconnu"

                } else if ( attr_name === "photo_items" ) {
                    var filename = ""

                    if ( item.photo_items && item.photo_items.length > 0 ) {
                        filename = item.photo_items[0].filename
                    }
                    return (
                        <Image
                            imageWidth={60}
                            style={{justifyContent: 'flex-start'}}
                            alt="" filename={filename}
                        />
                    )
                }
                return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
            }}
        />
        </>
    )

}