import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom'

import {
    CircularProgress
} from '@material-ui/core'

import DessinLayout from './index';
import SearchNav from 'components/SearchNav'

import { getDessinById, updateDessin } from 'Api'
import { useHistory } from 'react-router-dom'

export default () => {

    const params = useParams();
    const id = params.id;
    const [ dessin, setDessin ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const location = useLocation();
    const history = useHistory();
    
    const context = (location.state && location.state.context) || null;

    const loadData = async (id) => {

        const dessin = await getDessinById({ id })

        setDessin(dessin.dessin);
        setLoading(false);
    }

    useEffect(() => {
        loadData(id);
    }, [ id ])

    let content = null;

    if (loading) {
        content = <CircularProgress />
    } else if (!id || !dessin) {
        content = "NOT FOUND"
    } else {
        content = (

            <DessinLayout
                key={dessin.objectid}
                initialData={dessin}
                reloadData={() => loadData(id)}
                saveData={async data => {
                    await updateDessin({ id: data.objectid, data });
                    return true;
                }}
                onSaved={() => {
                    loadData(id);
                }}
                onDelete={() => {
                    history.push("/dessin");
                }}
            />
        )
    }
    return (
        <>
            {context === 'search_result' &&
                <SearchNav
                    search_type={"dessin"}
                    constructPath={dessin => ("/dessin/" + dessin.objectid)}
                    searchPath={"/dessin/search"}
                />
            }

            {content}

        </>
    )
}