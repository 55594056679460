import React, { useState } from 'react';
import {
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next';

import {
    Button
} from '@material-ui/core';

import PhotoModal from 'pages/Avila/Modals/AddPhoto'
import PhotoListLayout from 'pages/Avila/Photo/List/layout_expo'


export default ({ data, actions, onChange = () => {}, onClickEdit }) => {

    const [ addPhotoModal, setAddPhotoModal ] = useState(null);

    const onClickAddPhoto = () => {
        setAddPhotoModal(true);
    }
    const { t } = useTranslation()

    return (

        <>
            {addPhotoModal &&
                <PhotoModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, photo) => {
                                onChange({
                                    ...data,
                                    photos: [
                                        ...data.photos,
                                        photo
                                    ]
                                })
                            //
                                setAddPhotoModal(false);
                            }
                        }
                    ]}
                    filterData={data.expositions}
                    onClose={() => {
                        setAddPhotoModal(false)
                    }}
                />
            }
            <PhotoListLayout
                data={data.photos}
                headerActions={[
                    <Button key="add" onClick={onClickAddPhoto} variant="contained">{t('add_photo')}</Button>
                ]}
                actions={[
                    {
                        key: 'delete', icon: <DeleteIcon />, title: "Delete",
                        onClick: (id, photo) => {
                            if (window.confirm(`Supprimer ${photo.titre} ?`)) {

                                onChange({
                                    ...data,
                                    photos: data.photos.filter(e => e.objectid !== photo.objectid)
                                })
                            }
                        }
                    }
                ]}
            />
        </>
    )
}









