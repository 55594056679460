import React, { useEffect, useState } from 'react';

import PageLayout from 'layouts/Helpers/PageLayout'
import Tabs, {
    TabsContainer, TabsPanels
} from 'components/Tabs'

import {
    CircularProgress, Button
} from '@material-ui/core'

import { getAllConsignations } from 'Api';
import { useTranslation } from 'react-i18next';
import { consignationsTerminees, consignationsEnCours } from 'Util/Consignations'
import { saveAs } from 'file-saver'

import Layout from './layout'


export default ({ actions }) => {

    const [ consignations, setConsignations ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const { t } = useTranslation()

    useEffect(() => {
        getAllConsignations().then(consignations => {
            setConsignations(consignations.consignations || []);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <CircularProgress />
    }

      
    const consignationEnCours = consignationsEnCours(consignations)
    const consignationTerminees = consignationsTerminees(consignations)
  
    async function saveAsExcel() {
        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()
        
        ws.columns = [
            { header: 'Consignation', key: 'Consignation', width: 50 },
            { header: 'Oeuvre', key: 'Oeuvre', width: 50 },
        ];

        consignationEnCours.forEach(consignation => {
            const oeuvres = consignation.oeuvre_consignes

            oeuvres.forEach(oeuvre => {

                const row = ws.addRow([
                    consignation.emprunteur,
                    oeuvre.titre])
                row.font = { bold: false }

            })
        })

        const buf = await wb.xlsx.writeBuffer()
        const filename = "Consignations Oeuvres.xlsx"

        saveAs(new Blob([buf]), filename)
    }

    return (
        <PageLayout title={"Consignation"} actions={(
            <div>
            </div>
        )}>

            <TabsContainer orientation={"horizontal"}>
                <Tabs
                    orientation={"horizontal"}
                    variant="scrollable"
                    initialIndex={0}
                    tabs={[
                        {
                            label: t("toutes"),
                            panel: (
                                <TabsPanels>
                                    <Layout data={consignations} onChange={setConsignations} actions={actions} />
                                </TabsPanels>
                            )
                        },
                        {
                            label: t("en_cours"),
                            panel: (
                                <TabsPanels>
                                    <Layout data={consignationEnCours} onChange={setConsignations} actions={actions} exportAction={[<Button key="add" onClick={saveAsExcel}  variant="contained">Ajouter une oeuvre</Button>]}/>
                                </TabsPanels>
                            )
                        },

                        {
                            label: t("terminees"),
                            panel: (
                                <TabsPanels>
                                    <Layout data={consignationTerminees} onChange={setConsignations} actions={actions} />
                                </TabsPanels>
                            )
                        }
                    ]}
                />
            </TabsContainer>

        </PageLayout>
    )
}