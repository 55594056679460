import React from 'react';
import DataTable from 'components/DataTableWithHeader'
import {
    Edit as EditIcon
} from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'
import Moment from 'moment';
import { convertToDuration } from 'Util/Media'

import Medias from 'data/Medias';

import { sortNumbers, sortAlpha, sortArchiveMedia } from 'Util/Sorting'

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Medias, actions, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();
    
    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if ( category === "annee" ) {

                return sortNumbers(a[category], b[category], order)

            } else if ( (category === "titre") || (category === "genre") ) {

                return sortAlpha(a[category], b[category], order)
           
            } else if ( (category === "archive")) {

                return sortArchiveMedia(a[category], b[category], order)
            }
            return -1
        });
    }

    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: (media, index) => {
                        history.push("/media/" + media.objectid, {
                            context: "search_result",
                            search: {
                                index
                            }
                        });
                    },
                    cells: [
                        { title: t('archive'), key: 'archive', width:'100px', bold:true },
                        { title: t('genre'), key: 'genre', width:'200px' },
                        { title: t('title'), key: 'titre', width:'800px' },
                        { title: t('annee'), key: 'date_parution', width:'100px' },
                        { title: t('duree'), key: 'duree', width:'80px', sort:false },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, media) => {
                                window.open("/media/" + media.objectid)
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name) => {

                    
                   if (attr_name === 'date_parution') {
                        if (item['date_parution']) { 
                            return Moment(item['date_parution']).format('YYYY')
                        }
                        return ""

                    } else if ( attr_name === 'duree' ) {

                        return convertToDuration(item['duree'])
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}