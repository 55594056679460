

import OEUVRE_ELEMENTS from 'Assets/json/OeuvreElements.json'
import OEUVRE_MATERIAUX from 'Assets/json/OeuvreMateriaux.json'
import OEUVRE_COULEURS from 'Assets/json/OeuvreCouleurs.json'
import OEUVRE_FAMILLES from 'Assets/json/OeuvreFamilles.json'


export const getOeuvreElements = () => {

	var newElements = []

	OEUVRE_ELEMENTS.forEach( element => {

		var newElement = {}

		newElement["value"] = element["tag"]
		newElement["label"] = element["loc"]["fr"]
		newElements.push(newElement)

	})
	return newElements
}


export const getOeuvreMateriaux = () => {

	var newElements = []

	OEUVRE_MATERIAUX.forEach( element => {

		var newElement = {}

		newElement["value"] = element["tag"]
		newElement["label"] = element["loc"]["fr"]
		newElements.push(newElement)

	})
	return newElements
}


export const getOeuvreCouleurs = () => {

	var newElements = []

	OEUVRE_COULEURS.forEach( element => {

		var newElement = {}

		newElement["value"] = element["tag"]
		newElement["label"] = element["loc"]["fr"]
		newElements.push(newElement)

	})
	return newElements
}


export const getOeuvreFamilles = () => {

	var newElements = []

	OEUVRE_FAMILLES.forEach( element => {

		var newElement = {}

		newElement["value"] = element["tag"]
		newElement["label"] = element["loc"]["fr"]
		newElements.push(newElement)

	})
	return newElements
}

export const critereStringFromArray = (array) => {

	var string = ""
	var count = 0

	if ( array != null ) {
		array.forEach( item => {
			string = string + item.value
			count = count + 1
			if ( count !== array.length ) {
				string = string + ","
			}
		})
	}

	return string
}


export const critereArrayFromString = (string, criteres) => {

	var items = []

	if ( string != null ) {
		var strings = string.split(',');

		strings.forEach( tag => {

			var found = critereItem(tag, criteres)

			if ( found != null ) {
				items.push(found)
			}
		})
	}

	return items
}


export const critereItem = (tag, criteres) => {

	var found = null

	criteres.forEach( item => {

		if ( item["value"] === tag ) {
			found = item
		}
	})	

	return found
}

