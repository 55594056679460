import Query from './Query';
import ApiCall from './ApiCall';
import Moment from 'moment';


export default async ({ data }) => {
    const query = new Query();

    if (data.titre) {
        query.contains({
            fieldName: 'titre',
            value: data.titre
        })
    }

    if (data.archive) {
        query.contains({
            fieldName: 'archive',
            value: data.archive.replaceAll("-", " ")
        })
    }

    if (data.title) {
        query.contains({
            fieldName: 'title',
            value: data.title
        })
    }

    if (data.lieu) {
        query.contains({
            fieldName: 'lieu',
            value: data.lieu
        })
    }

    if (data.ville) {
        query.contains({
            fieldName: 'ville',
            value: data.ville
        })
    }

    if (data.pays) {
        query.contains({
            fieldName: 'pays',
            value: data.pays
        })
    }

    if (data.circa_debut) {
        query.matchNumber({
            fieldName: 'circa_debut',
            value: data.circa_debut
        })
    }
    if (data.circa_fin) {
        query.matchNumber({
            fieldName: 'circa_fin',
            value: data.circa_fin
        })
    }

    if ( data.annee_debut && !data.annee_fin) {

        const dateDebutMoment = Moment(data.annee_debut+"-01-01", "YYYY-MM-DD")        
        const dateFinMoment = Moment(data.annee_debut+"-12-30", "YYYY-MM-DD")
        const date_debut = dateDebutMoment.toDate();
        const date_fin = dateFinMoment.toDate();

        query.matchDateBetween({
            fieldName: 'date_debut',
            date_min: date_debut,
            date_max: date_fin
        })
    } else if ( data.annee_fin && !data.annee_debut ) {
        const dateDebutMoment = Moment(data.annee_fin+"-01-01", "YYYY-MM-DD")
        const dateFinMoment = Moment(data.annee_fin+"-12-30", "YYYY-MM-DD")

        const date_debut = dateDebutMoment.toDate();
        const date_fin = dateFinMoment.toDate();

        query.matchDateBetween({
            fieldName: 'date_fin',
            date_min: date_debut,
            date_max: date_fin
        })    

    } else if ( data.annee_debut && data.annee_fin ) {
        const dateDebutMoment = Moment(data.annee_debut+"-01-01", "YYYY-MM-DD")
        const dateFinMoment = Moment(data.annee_fin+"-12-30", "YYYY-MM-DD")

        const date_debut = dateDebutMoment.toDate();
        const date_fin = dateFinMoment.toDate();

        query.matchDateBetween({
            fieldName: 'date_fin',
            date_min: date_debut,
            date_max: date_fin
        })        
    }

    if (data.typeexpo_array) {
        data.typeexpo_array.forEach( type_expo => {
            query.contains({
                fieldName: 'typeexpo_string',
                value: type_expo["value"]
            })          
        })
    }
    if (data.website) {
        query.contains({
            fieldName: 'website',
            value: data.website
        })
    }

    if (data.artistes) {
        query.contains({
            fieldName: 'artistes',
            value: data.artistes
        })
    }

    if (data.commissaire) {
        query.contains({
            fieldName: 'commissaire',
            value: data.commissaire
        })
    }
    
    if (data.remarques) {
        query.contains({
            fieldName: 'remarques',
            value: data.remarques
        })
    }

    if (data.dossierdexpo) {
        query.contains({
            fieldName: 'dossierdexpo',
            value: data.dossierdexpo
        })
    }
    console.log("query Expo : ", query);

    return await ApiCall({ type: 'exposition', query })
}