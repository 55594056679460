// @flow
import React, { useState } from 'react';

import Modal from './BaseModal';
import { CircularProgress } from '@material-ui/core';



const SearchForm = ({ onSubmit = async () => {} }: { onSubmit: (searchQuery: string, e: any) => Promise<any> }) => {

    const [ query, setQuery ] = useState("");
    const [ loading, setLoading ] = useState(false);

    return (
        <form onSubmit={async e => {
            setLoading(true);
            await onSubmit(query, e)
            setLoading(false);
        }}>
            <div>
                Archive : <input type="text" name="archive" value={query} onChange={({ target: { value: query }}) => setQuery(query) } />
                <input type="submit" name="search" value="Rechercher" />
                {loading && <CircularProgress size={15} />}
            </div>
        </form>
    );
}

type PropsList = {
    actions: Array<any>,
    data: Array<any>,
    filterData: Array<any>,
    renderList: ({ data: Array<any>, actions: any }) => any
}

const ResultList = ({
    actions = [],
    data: resultData = [],
    filterData = [],
    renderList
}: PropsList) => {

    let data = resultData.filter(item => {
        if (filterData.findIndex(filterItem => filterItem.objectid === item.objectid) !== -1) {
            return false;
        }

        return true;
    })

    return (
        <>
            {renderList({data, actions})}
        </>
    );
}


export type Props = {
    onClose: any => any,
    actions: Array<any>,
    filterData: Array<any>,
    searchFunction: (query: string) => Promise<Array<any>>,
    renderList: ({ data: Array<any>, actions: any }) => any
}
export default ({
    title,
    onClose,
    actions = [],
    filterData = [],
    searchFunction = async (query: string) => ([]),
    renderList = () => (null)
}: Props) => {

    const [ data, setData ] = useState(null);

    return (
        <Modal title={title} onClose={onClose}>
            <SearchForm onSubmit={async (query, e) => {
                e.stopPropagation();
                e.preventDefault();

                const data = await searchFunction(query);

                setData(data || []);
            }} />

            {data && !!data.length && (
                <ResultList
                    data={data}
                    filterData={filterData}
                    actions={actions}
                    renderList={renderList}
                />
            )}

            {data && !data.length && (
                <div>
                    Pas de résultat
                </div>
            )}
        </Modal>
    )
}