import React from 'react';

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default ({ items = [], onChange = () => {} }) => {
  return (
    <Swiper
        style={{width: '100%'}}
        navigation
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={({ activeIndex }) => onChange(activeIndex)}
    >
        {items.map((item, i) => (
            <SwiperSlide key={i}>{item}</SwiperSlide>
        ))}
    </Swiper>
  );
};