import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Helmet from 'react-helmet';

import MomentUtils from '@date-io/moment';
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { SnackbarProvider } from 'notistack';
import moment from 'moment';
import Cookies from 'js-cookie';
import 'moment/locale/fr';

//import { onAuth, loadColors, loadElements, loadFamilles, loadMateriaux, loadType_Expos, loadCriteres, loadGenre_Contacts } from 'Actions'
import { onAuth, loadType_Expos, loadCriteres, loadGenre_Contacts } from 'Actions'

import maTheme from "./theme";
import Routes from "./routes/Routes";
import { CircularProgress } from "@material-ui/core";

import 'react-image-lightbox/style.css';

moment.locale('fr')

const App = ({ theme }) => {
    const dispatch = useDispatch();

    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                try {
                    const token = Cookies.get('token');
                    const tokenTimeout = parseInt(Cookies.get('token-timeout'), 10);
                    const currentTimeSecond = (new Date()).getTime() / 1000;
                    const user_id = Cookies.get('user_id');
                    if (currentTimeSecond < tokenTimeout && token && user_id) {
                        await dispatch(onAuth(token, user_id))
                    }
                } catch (e) {
                    console.log(e.message)
                }
                setLoading(false);

                // LOAD ALL REQUIRED DATA
             //   console.log("load les options")
            //    dispatch(loadColors());
            //    dispatch(loadElements());
            //    dispatch(loadMateriaux());
            //    dispatch(loadFamilles());
                dispatch(loadType_Expos());
                dispatch(loadCriteres());
                dispatch(loadGenre_Contacts());
            } catch (e) {
                console.error(e);
                setLoading(false);
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) {
        return <CircularProgress />
    }

    return (
        <React.Fragment>
            <Helmet
                titleTemplate="%s | Avila - Logiciel de gestion de stock"
                defaultTitle="Avila - Logiciel de gestion de stock"
            />
            <StylesProvider injectFirst>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
                        <ThemeProvider theme={maTheme[theme.currentTheme]}>
                            <SnackbarProvider
                                maxSnack={2}
                                autoHideDuration={1500}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Routes />
                            </SnackbarProvider>
                        </ThemeProvider>
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </React.Fragment>
    );
}

export default connect(store => ({ theme: store.themeReducer }))(App);
