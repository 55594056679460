import React from 'react';
import { useHistory } from 'react-router-dom'
import { createExpo } from 'Api'
import ExpoLayout from './index';

export default () => {

    const history = useHistory();

    return (
        <ExpoLayout
            layout="create"
            saveData={async data => {

                const result = await createExpo({ data });
                return result;
            }}
            onSaved={expo => {
                history.push("/expo/" + expo.objectId);
            }}
        />
    )
}