import Query from './Query';
import ApiCall from './ApiCall';

export default async ({ data }) => {
    const query = new Query();



    if ( data.date_debut ) {

        if ( data.date_debut_max ) {

            query.matchDateBetween({
                fieldName: 'date_debut',
                date_min: data.date_debut,
                date_max: data.date_debut_max
            })
        } else {
            query.matchDate({
                fieldName: 'date_debut',
                value: data.date_debut
            })
        }
    }

    if ( data.date_fin ) {

        if ( data.date_fin_max ) {

            query.matchDateBetween({
                fieldName: 'date_fin',
                date_min: data.date_fin,
                date_max: data.date_fin_max
            })
        } else {
            query.matchDate({
                fieldName: 'date_fin',
                value: data.date_fin
            })
        }
    }


    if (data.motif) {
        query.contains({
            fieldName: 'motif',
            value: data.motif
        })
    }

    if (data.emprunteur) {
        query.contains({
            fieldName: 'emprunteur',
            value: data.emprunteur
        })
    }

    if (data.contact) {
        query.contains({
            fieldName: 'contact',
            value: data.contact
        })
    }

    if (data.transport) {
        query.contains({
            fieldName: 'transport',
            value: data.transport
        })
    }


    if (data.adresse) {
        query.contains({
            fieldName: 'adresse',
            value: data.adresse
        })
    }

    if (data.adresse_2) {
        query.contains({
            fieldName: 'adresse_2',
            value: data.adresse_2
        })
    }

    if (data.code_postal) {
        query.matchText({
            fieldName: 'code_postal',
            value: data.code_postal
        })
    }

    if (data.ville) {
        query.contains({
            fieldName: 'ville',
            value: data.ville
        })
    }


    if (data.pays) {
        query.matchText({
            fieldName: 'pays',
            value: data.pays
        })
    }

    if (data.telephone) {
        query.matchText({
            fieldName: 'telephone',
            value: data.telephone
        })
    }

    if (data.email) {
        query.contains({
            fieldName: 'email',
            value: data.email
        })
    }


    if (data.remarques) {
        query.contains({
            fieldName: 'remarques',
            value: data.remarques
        })
    }

    console.log("query Consignation : ", query);

    return await ApiCall({ type: 'consignation', query })
}