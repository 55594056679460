import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames'
import Lightbox from 'react-image-lightbox';


import { getImagePath } from 'Util/Image';


const ImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .action_container {
        position: absolute;
        z-index: 10;
        top: 10px; right: 10px;

        display: flex;
        flex-direction: flex-end;
        align-items: center;
        >* {
            margin-left: 10px;
        }
    }
    .action_container.outside {
        top: -70px;
    }

    > .save_btn {
        position: absolute;
        top: 10px; right: 10px;
    }

    > img {
        z-index: 3;
    }

    .progress {
        position: absolute;
        z-index:9;
        top:0; left:0;
        width: 100%;
    }
`

export default ({
    src: doNotUseSrc,
    downloadFile = false,
    alt="",
    imgStyle = {},
    filename,
    startIndex,
    images,
    subdir = "photos",

    actionPosition = 'inside', // or 'outside'
    onChange, onClickDelete,

    imageHeight,
    imageWidth,

    ...props
}) => {


    const [ lightboxOpened, setLighboxOpened ] = useState(false);
    const [ photoIndex, setPhotoIndex ] = useState(startIndex)

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    }, []);



   // const src = filename ? getImagePath(filename, subdir) : getImagePath("default.jpg", subdir);
    const src = images[photoIndex] ? getImagePath(images[photoIndex], subdir) : getImagePath("default.jpg", subdir);


    const imageProps = {};
    const resizedSrc = (() => {
        if (imageHeight) {
            imageProps.height = imageHeight;
            return src+"?height=" + imageHeight;
        }
        if (imageWidth) {
            imageProps.width = imageWidth;
            return src+"?width=" + imageWidth;
        }

        return src;
    })()

    return (
        <ImageContainer {...props} onClick={e => {
            if (lightboxOpened) {
                e.stopPropagation();
                e.preventDefault();
            }
            if (props.onClick) {
                props.onClick(e);
            }
        }}>


            <div className={classNames('action_container', actionPosition)}>

            </div>

            {filename &&
                <img
                    src={resizedSrc}
                    alt={alt}
                    style={{...imgStyle, opacity: 1, cursor: 'zoom-in'}}
                    {...imageProps}
                    onClick={e => {
                        setLighboxOpened(true);
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                />
            }
            {!filename &&
                <img
                    src={src}
                    alt={alt}
                    style={{...imgStyle, opacity: 1, cursor: 'zoom-in'}}
                    {...imageProps}

                />
            }

            {src && lightboxOpened &&
                <Lightbox
                    onCloseRequest={() => setLighboxOpened(false)}
                    mainSrc={src}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    reactModalStyle={{ overlay: {zIndex: 100000}}}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            }

        </ImageContainer>
    )
}