import React from 'react';
import DataTable from 'components/DataTable'
import {
    Edit as EditIcon
} from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import Biblios from 'data/Biblios';

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Biblios, actions, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Paper elevation={3}>

            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}

            <DataTable
                data={data}
                config={{
                    onClickRow: biblio => {
                        window.open("/biblio/" + biblio.objectid)
                    },
                    cells: [
                        { title: t('nb_archive'), key: 'archive', width:'100px', bold:true },
                        { title: t('auteur'), key: 'auteurs' },
                        { title: t('title'), key: 'titre' },
                        { title: t('annee'), key: 'annee' },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, biblio) => {
                                history.push("/biblio/" + biblio.objectid);
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name) => {
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}