// @flow
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'


type Props = {
    data: any,
    onChange: any => any
}

export default ({ data, biblioId, onChange }: Props) => {
    
    const { t } = useTranslation();

   return (
        <React.Fragment>

            <Form
                form={[
                
                    { label: t("archive"), attr: "archive", type: "display", size: { label: 150, field:400 }},
                    { label: t("title"), attr: "titre", type: "display", size: { label: 150, field:400 }},

                    { label: t("numero_exemplaire"), attr: "multiple", type: "text", size: { label: 150, field:100 }},

                    { label: t("consignation"), attr: "consignation", type: "radio", value:"0", label_1 : "Vente", label_2: "Prêt",  size: { label: 150, field:400 }},
                    { label: t("contrat"), attr: "contrat", type: "radio", value:"0", label_1 : "Non", label_2: "Oui",  size: { label: 150, field:400 }},
                    { label: t("vendu"), attr: "vendu", type: "radio", value:"0", label_1 : "Non", label_2: "Oui",  size: { label: 150, field:400 }},

                    { label: t("depart_valide"), attr: "depart", type: "checkbox", size: { label: 150, field:100 }},
                    { label: t("retour_valide"), attr: "retour", type: "checkbox", size: { label: 150, field:100 }},
                    { label: t("consignation_terminee"), attr: "consignation_terminee", type: "checkbox", size: { label: 150, field:400 }},

                ]}
                data={data}
                onChange={ data => {
                    if ( (data.retour === 1) || (data.vendu === 1) ) {
                        data.consignation_terminee = 1
                    }
                    onChange(data)
                }}
            />

        </React.Fragment>

    )
}
