


export const completeListe = (multiples, oeuvres) => {

    var liste = []

    multiples.forEach(multiple => {

        oeuvres.forEach(oeuvre => {

            if ( oeuvre.objectid === multiple.oeuvre_objectid ) {


                var newMultiple = []

                newMultiple.oeuvre_objectid = oeuvre.objectid
                newMultiple.titre = oeuvre.titre
                newMultiple.archive = oeuvre.archive
                newMultiple.numero = multiple.numero
                newMultiple.etat = multiple.etat
                newMultiple.etat_de_conservation = multiple.etat_de_conservation
                newMultiple.emplacement = multiple.emplacement
                newMultiple.derniere_localisation = multiple.derniere_localisation
                newMultiple.disponibilite = multiple.disponibilite
                newMultiple.serie = oeuvre.serie
                newMultiple.editer_par = oeuvre.editer_par
                newMultiple.hauteur = oeuvre.hauteur
                newMultiple.largeur = oeuvre.largeur
                newMultiple.profondeur = oeuvre.profondeur

                liste.push(newMultiple)
            }
        })
    })

    return liste
}



