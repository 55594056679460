import React from 'react';
import { useHistory } from 'react-router-dom'
import { createContact } from 'Api'
import ContactLayout from './index';

export default () => {

    const history = useHistory();

    return (
        <ContactLayout
            layout="create"
            saveData={async data => {

                const result = await createContact({ data });
                return result;
            }}
            onSaved={contact => {
                history.push("/contact/" + contact.objectId);
            }}
        />
    )
}