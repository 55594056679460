// @flow
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'

type Props = {
    data: any,
    onChange: any => any
}

export default ({ data, isMultiple, onChange }: Props) => {

    const { t } = useTranslation();

    return (
        <React.Fragment>

            <Form
                form={[
                    { label: t("nimp15"), attr: "nimp15", type: "checkbox", size: { label: 150, field:150 }},
                    
                    { label: t("largeur"), attr: "largeur", type: "text", textType: 'measure', size: { label: 150, field:300 }},
                    { label: t("profondeur"), attr: "profondeur", type: "text", textType: 'measure', size: { label: 150, field:300 }},
                    { label: t("hauteur"), attr: "hauteur", type: "text", textType: 'measure', size: { label: 150, field:300 }},
                    
                    { label: t("poids"), attr: "poids", type: "text", textType: 'weight', size: { label: 150, field:300 }},
        
                    ...(isMultiple ? [
                        { label: t("numero_exemplaire"), attr: "multiple", type: "text", size: { label: 150, field:400 }},
                    ] : [] ),
                    { label: t("etat_de_la_caisse"), attr: "etat", type: "text", size: { label: 150, field:400 }},
                    { label: t("remarques"), attr: "remarques", type: "text", multi:3, size: { label: 150, field:500 }},

                    [
                        { label: t("cadre_de_voyage"), attr: "cadre_de_voyage", type: "checkbox", size: { label: 150, field:150 }},
                        { label: t("ouverture_verticale"), attr: "ouverture_verticale", type: "checkbox", size: { left: 100, field:150 }},
                        { label: t("peinte"), attr: "peinte", type: "checkbox", size: { left: 100, field:150 }},
                    ],
                    [
                        { label: t("a_refaire"), attr: "a_refaire", type: "checkbox", size: { label: 150, field:150 }},
                        { label: t("tyvek_ok"), attr: "tyvek_ok", type: "checkbox", size: { label: 50, field:150 }},
                        { label: t("cales_ok"), attr: "cales_ok", type: "checkbox", size: { label: 65, field:150 }},
                    ],
               ]}
                data={data}
                onChange={onChange}
            />

        </React.Fragment>

    )
}
