import React from "react";

import {
    BrowserRouter as Router, Route, Switch,
    Redirect
} from "react-router-dom";
import { dashboard as dashboardRoutes, auth as authRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import { useAuth } from "redux/hooks";


const CheckLogin = ({ needLogin = false, children }) => {

     const auth = useAuth();

     if (!needLogin) {
         return children;
     }

     if (!auth || !auth.token || !auth.user) {
         return <Redirect to="/auth/sign-in" />
     }

    return children;
}



const childRoutes = (Layout, routes, needLogin = false) =>
    routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.map(({ path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                 /* render={props => (
                      <Layout>
                        <Component {...props} />
                      </Layout>
                    )}*/

                  render={props => (
                       <CheckLogin needLogin={needLogin}>
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        </CheckLogin >
                    )}
                />
            ))
        ) : (
                // Route item without children
                <Route
                    key={index}
                    path={path}
                    exact
                /*  render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}*/
                    
                   render={props => (
                        <CheckLogin needLogin={needLogin}>
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        </CheckLogin>
                    )}
                />
            )
    );



const Routes = () => (
  <Router>
    <Switch>
      {childRoutes(DashboardLayout, dashboardRoutes, true)}
      {childRoutes(AuthLayout, authRoutes)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default Routes;
