import React from 'react';
import DataTable from 'components/DataTableWithHeader'

import {
    Edit as EditIcon
} from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import Biblios from 'data/Biblios';


import { sortArchiveBiblio, sortAlpha } from 'Util/Sorting'


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Biblios, actions, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();

    
    function sorting ( category, order ) {

        if ( category === "archive" ) {
            data.sort(function (a, b) {
                return sortArchiveBiblio(a[category], b[category], order)
            });
        } else {
            data.sort(function (a, b) {
                return sortAlpha(a[category], b[category], order)
            });
        }
    }

    return (
        <Paper elevation={3}>

            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}

            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: biblio => {
                        window.open("/biblio/" + biblio.objectid)
                    },
                    cells: [
                        { title: t('archive'), key: 'archive', width:'120px', bold:true },
                        { title: t('bibliographie'), key: 'titre', width:'700px' },
                        { title: t('annee'), key: 'annee', width:'100px', sort:false  },
                        { title: t('auteur'), key: 'auteurs', width:'300px', sort:false  },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, biblio) => {
                                history.push("/biblio/" + biblio.objectid);
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name) => {
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}