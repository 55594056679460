import Query from './Query';
import ApiCall from './ApiCall';

export default async ({ data }) => {
    const query = new Query();

    if (data.prenom) {
        query.contains({
            fieldName: 'prenom',
            value: data.prenom
        })
    }

    if (data.nom) {
        query.contains({
            fieldName: 'nom_prenom',
            value: data.nom
        })
    }

    if (data.structure) {
        query.contains({
            fieldName: 'structure',
            value: data.structure
        })
    }

    if (data.title) {
        query.contains({
            fieldName: 'title',
            value: data.title
        })
    }
    if (data.fonction) {
        query.contains({
            fieldName: 'fonction',
            value: data.fonction
        })
    }


  /*  ['genre_contacts'].forEach(attrName => {
        if (data[attrName] && data[attrName].length > 0) {
            query.matchSubobjectList({
                fieldName: attrName,
                ids: data[attrName]
            })
        }
    })*/

    if (data.genres_array) {
        data.genres_array.forEach( genre => {
            query.contains({
                fieldName: 'genres_string',
                value: genre["value"]
            })          
        })
    }

    /*if (data.genre) {
        query.contains({
            fieldName: 'genre',
            value: data.genre
        })
    }*/

    if (data.invitation) {
        query.matchNumber({
            fieldName: 'invitation',
            value: data.invitation
        })
    }
    if (data.prive) {
        query.matchNumber({
            fieldName: 'prive',
            value: data.prive
        })
    }

    if (data.remarques) {
        query.contains({
            fieldName: 'remarques',
            value: data.remarques
        })
    }

    if (data.adresse) {
        query.contains({
            fieldName: 'adresse',
            value: data.adresse
        })
    }

      if (data.adresse_2) {
        query.contains({
            fieldName: 'adresse_2',
            value: data.adresse_2
        })
    }

     if (data.code_postal) {
        query.contains({
            fieldName: 'code_postal',
            value: data.code_postal
        })
    }

     if (data.ville) {
        query.contains({
            fieldName: 'ville',
            value: data.ville
        })
    }

    if (data.pays) {
        query.contains({
            fieldName: 'pays',
            value: data.pays
        })
    }


    if (data.tel_perso) {
        query.contains({
            fieldName: 'tel_perso',
            value: data.tel_perso
        })
    }
    if (data.email_perso) {
        query.contains({
            fieldName: 'email_perso',
            value: data.email_perso
        })
    }

    if (data.tel_pro) {
        query.contains({
            fieldName: 'tel_pro',
            value: data.tel_pro
        })
    }
    if (data.email_pro) {
        query.contains({
            fieldName: 'email_pro',
            value: data.email_pro
        })
    }

    if (data.tel_autre) {
        query.contains({
            fieldName: 'tel_autre',
            value: data.tel_autre
        })
    }
    if (data.email_autre) {
        query.contains({
            fieldName: 'email_autre',
            value: data.email_autre
        })
    }

    if (data.site_web) {
        query.contains({
            fieldName: 'site_web',
            value: data.site_web
        })
    }



    console.log("query Contact : ", query)

    return await ApiCall({ type: 'contact', query })
}