import React, { useState } from 'react';
import MediaUploader from 'components/MediaUploader'
import getConfig from 'config';

export default () => {

    const [ filePath, setFilePath ] = useState(null)
    const [ fileSize, setFileSize ] = useState(300)
    const [ size, setSize ] = useState(300)

    const onUploaded = data => {

        setFilePath(getConfig().MEDIA_URL + '/' + data.path)
    }

    const filePathWithSize = `${filePath}?size=${fileSize}`
    return (
        <>
            <MediaUploader style={{height: '300px'}} onUploaded={onUploaded}>
                Drag drop file here !
            </MediaUploader>
            <br/><br/>
            <input type="text" name="size" value={size} onChange={({ target: { value }}) => setSize(value)} />
            <input type="button" value="Change size" onClick={() => setFileSize(size)} />
            <br/><br/>
            {filePath &&
                <>
                    {filePathWithSize}
                    <div>
                        <img src={filePathWithSize} alt="test"/>
                    </div>
                </>
            }
        </>
    )
}