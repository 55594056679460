import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import {
    CircularProgress
} from '@material-ui/core'

import MediaLayout from './index';
import SearchNav from 'components/SearchNav'

import { getMediaById, updateMedia } from 'Api'

export default () => {

    const params = useParams();
    const id = params.id;
    const [ media, setMedia ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const location = useLocation();
    const context = (location.state && location.state.context) || null;
    const history = useHistory();

    const loadData = async (id) => {

        const media = await getMediaById({ id })

        setMedia(media.media);
        setLoading(false);
    }

    useEffect(() => {
        loadData(id);
    }, [ id ])

    let content = null;

    if (loading) {
        content = <CircularProgress />
    } else if (!id || !media) {
        content = "NOT FOUND"
    } else {
        content = (

            <MediaLayout
                initialData={media}
                reloadData={() => loadData(id)}
                saveData={async data => {
                    await updateMedia({ id: data.objectid, data });
                    return true;
                }}
                onSaved={() => {
                    loadData(id);
                }}
                onDelete={() => {
                    history.push("/media");
                }}
            />
        )
    }
    return (
        <>
            {context === 'search_result' &&
                <SearchNav
                    search_type={"media"}
                    constructPath={media => ("/media/" + media.objectid)}
                    searchPath={"/media/search"}
                />
            }

            {content}

        </>
    )
}