import React, { useEffect, useState } from 'react';

import {
    CircularProgress,
} from '@material-ui/core'

import { getAllMedias } from 'Api';

import PageLayout from 'layouts/Helpers/PageLayout'
import Layout from './layout'


export default ({ actions }) => {

    const [ medias, setMedias ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getAllMedias().then(medias => {
            setMedias(medias.medias || []);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <CircularProgress />
    }

    return (
        <PageLayout title={"Medias"} actions={(
            <div>
            </div>
        )}>
            <Layout data={medias} actions={actions} onChange={setMedias} />
        </PageLayout>
    )
}