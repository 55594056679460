import { validate as uuidValidate } from 'uuid';
import getConfig from 'config';

export const getImagePath = (_fileNameWithExt, subdir = "photos") => {

    let fileNameWithExt = _fileNameWithExt;

    if (fileNameWithExt.substr(0, 7) === "file://") {
        fileNameWithExt = fileNameWithExt.split('/')
        fileNameWithExt = fileNameWithExt[fileNameWithExt.length - 1];
    }

    const [ fileName, ext ] = fileNameWithExt.split('.');
    const isUUID = uuidValidate(fileName);

    const BASE_URL = getConfig().MEDIA_URL

    if (isUUID) {
        return BASE_URL + "/" + fileName.substr(0, 2) + "/" + fileName.substr(2, 2) + "/" + fileNameWithExt;
    }

    return BASE_URL + "/" + fileName + "." + (ext ? ext : "jpg");
   // return "https://tappublisher.com/avila/"+subdir+"/" + fileName + "." + (ext ? ext : "jpg");
}


export const getFileExtension = (filename) =>
{
    const ext = filename.split('.');

    
    return ext[1]
}