import { apiCall, constructUrl, getResult, fetchApi as fetch } from './ApiCall';
import { getExpoTypes, critereArrayFromString, critereStringFromArray } from 'Util/ExpoCriteres';


const constructExpo = exposition => ({
    ...exposition,
    type_expos: (exposition.type_expos || []).map(item => item.objectid),
    typeexpo_array: critereArrayFromString(exposition.typeexpo_string, getExpoTypes()),

})


export const getAllExpos = async ({ cursor, limit } = {}) => {
    return await apiCall("getallexpositions")
}

export const getExpoById = async ({ id }) => {
    const rs = await apiCall("getexposition", "?exposition_id=" + id)
    if (rs.exposition && rs.exposition.length > 0) {
        return {
            exposition: constructExpo(rs.exposition[0])
        }
    }

    return null;
}

export const deleteExpo = async ({ expo }) => {

    await apiCall("deleteexposition", "?exposition_id=" + expo.objectid)

}

export const createExpo = async ({ data }) => {
    const url = constructUrl("createexposition");

    data['typeexpo_string'] = critereStringFromArray(data['typeexpo_array'], getExpoTypes())

    var formdata = new FormData();
    formdata.append("exposition", JSON.stringify({expositionData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createexposition');
}

export const updateExpo = async ({ id, data }) => {
    const url = constructUrl("updateexposition");

    [
        'biblios',
        'oeuvres', 'medias',
        'photos',
        'photo_items',
        'oeuvre_expos'
    ].forEach(attr => {
        if (data[attr]) {
            data[attr] = data[attr].map(item => item.objectid);
        }
    })

    data['typeexpo_string'] = critereStringFromArray(data['typeexpo_array'], getExpoTypes())

    var formdata = new FormData();
    formdata.append("exposition_id", id);
    formdata.append("exposition", JSON.stringify({expositionData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updateexposition');
}

//
//
//
export const deleteOeuvreExpo= async ({ data }) => {

    console.log("oeuvre_expo_id : ", data.objectid)
    await apiCall("deleteoeuvreexpo", "?oeuvre_expo_id=" + data.objectid)

}

export const createOeuvreExpoUpdateExpo = async ( {expo, data} ) => {

    const resCreate = await createOeuvreExpo({expoId:expo.objectid, data});
    const objectId = resCreate["objectId"]

    expo = {
        ...expo,
        oeuvre_expos: [
            ...expo.oeuvre_expos,
            {"objectid" : objectId}
        ]
    }

    await updateExpo({ id: expo.objectid, data: expo });
}

export const createOeuvreExpo = async ({ expoId, data }) => {
    
    const url = constructUrl("createoeuvreexpo");
    
    var formdata = new FormData();

    data["expositions"] = [expoId]

    formdata.append("oeuvre_expo", JSON.stringify({ oeuvre_expoData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createoeuvre_expo');
}

export const updateOeuvreExpo = async ({expoId, data }) => {
    const url = constructUrl("updateoeuvreexpo");

    var formdata = new FormData();
    formdata.append("oeuvre_expo_id", data.objectid);
    data["expositions"] = [expoId]

    delete data['objectid']
    formdata.append("oeuvre_expo", JSON.stringify({ oeuvre_expoData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updateoeuvreexpo');
}



export const getAllType_Expos = () => apiCall("getalltype_expos");
