
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'
import {listeDesLocalisations, indexInListeDesLocalisations, paysDesLocalisations, villeDesLocalisations, emplacementDesLocalisations } from 'Util/Listes'



export default ({ data, isMultiple, onChange }: Props) => {
    
    const { t } = useTranslation();

//    console.log("data : ", data)
    return (
        <React.Fragment>

            <Form
                form={[
                    { label: t("date"), attr: "date_localisation", type: "dateselector", size: { label: 150, field:150 }},
                    ...(isMultiple ? [
                        { label: t("numero_exemplaire"), attr: "multiple", type: "text", size: { label: 150, field:150 }},

                    ] : [] ),

                    { label: t("lieu"), attr: "localisation", type: "autocomplete", autocomplete: {
                            type: 'free',
                            options: listeDesLocalisations()
                        }, size: { label: 150, field:400 }
                    },
                    { label: t("emplacement"), attr: "emplacement", type: "autocomplete", autocomplete: {
                            type: 'free',
                            options: emplacementDesLocalisations()
                        }, size: { label: 150, field:400 }
                    },
                    { label: t("ville"), attr: "ville", type: "text", size: { label: 150, field:400 }},
                    { label: t("pays"), attr: 'pays', type: "select",
                        selectType: 'countries', size: { label: 150, field: 300 }
                    },
                    { label: t("remarques"), attr: "remarque", type: "text", size: { label: 150, field:400 }},
                ]}
                data={data}
                onChange={ (data) => {

                    const index = indexInListeDesLocalisations(data.localisation)

                    if ( index !== -1 ) {
                        const pays = paysDesLocalisations()[index]
                        data.pays = pays

                        const ville = villeDesLocalisations()[index]
                        data.ville = ville
                    }
                    onChange(data)
                }
            }
            />

        </React.Fragment>

    )
}
