// @flow
import React from 'react';

import { updateUser, createUser } from 'Api';
import UserForm from 'pages/Avila/Users/Form'

import FormModal from './FormModal';

type Props = {
    userid: string,
    onClose: any => any,
    actions?: Array<any>,
    data: any,
    onSave: any => any
}
export default ({
    onClose,
    actions = [],
    data,
    userid,
    onSave = () => {}
}: Props) => {


    return (
        <FormModal
            title="Utilisateur"
            data={data}
            onClose={onClose}
            FormComponent={UserForm}

            onSave={onSave}
            updateMethod={({ data }) => updateUser({ id: userid, data })}
            createMethod={({ data }) => createUser({ data })}
        />
    )
}