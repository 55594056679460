import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { SET_SEARCH_FORM, SET_SEARCH_RESULTS } from './constants'

const selectData = key => createSelector(
    state => state[key],
    data => {
        return {
            data: data.data.filter(item => item.active === 1)
        }
    }
)

export const useFamilles = () => useSelector(selectData('familles'));
export const useMateriaux = () => useSelector(selectData('materiaux'));
export const useElements = () => useSelector(selectData('elements'));
export const useColors = () => useSelector(selectData('colors'));
export const useType_Expos = () => useSelector(selectData('type_expos'));
export const useCriteres = () => useSelector(selectData('criteres'));
export const useGenre_Contacts = () => useSelector(selectData('genre_contacts'));

export const useSearch = (search_type, defaultFormValue) => {

    const dispatch = useDispatch();

    const defaultValue = {};
    if (defaultFormValue) {
        defaultValue.form = defaultFormValue;
    }
    const search = useSelector(
        state => (state.search[search_type] || defaultValue)
    );

    const setForm = form => {
        dispatch({
            type: SET_SEARCH_FORM,
            search_type,
            form
        })
    }
    const setResult = results => {
        dispatch({
            type: SET_SEARCH_RESULTS,
            search_type,
            results
        })
    }

    return [ search, setForm, setResult ];
}

export const useMutipleSearch = (search_type, defaultFormValue) => {

    const dispatch = useDispatch();

    const defaultValue = {};
    if (defaultFormValue) {
        defaultValue.form = defaultFormValue;
    }
    const search = useSelector(
        state => (state.search[search_type] || defaultValue)
    );

    const setForm = form => {
        dispatch({
            type: SET_SEARCH_FORM,
            search_type,
            form
        })
    }
    const setResult = results => {
        dispatch({
            type: SET_SEARCH_RESULTS,
            search_type,
            results
        })
    }

    return [ search, setForm, setResult ];
}

const selectAuth = () => createSelector(
    state => state.auth,
    auth => auth
)
export const useAuth = () => useSelector(selectAuth())


export const useCurrentUser = () => {
    const auth = useAuth();

    if (auth && auth.user) {
        return auth.user;
    }

    return null;
}


export const useCurrentPermissionProfile = () => {
    const user = useCurrentUser();
    if (!user) {
        return null;
    }

    return user.permission_profile;
}

export const useCurrentUserEmail = () => {
    const user = useCurrentUser();
    if (!user) {
        return null;
    }

    return user.email;
}