import React from 'react';
import { useHistory } from 'react-router-dom'
import { createDessin } from 'Api'
import DessinLayout from './index';

export default () => {

    const history = useHistory();

    return (
        <DessinLayout
            layout="create"
            saveData={async data => {

                const result = await createDessin({ data });
                return result;
            }}
            onSaved={dessin => {
                history.push("/dessin/" + dessin.objectId);
            }}
        />
    )
}