// @flow
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'

type Props = {
    data: any,
    onChange: any => any
}

export default ({ data, onChange }: Props) => {

    const { t } = useTranslation();

    return (
        <React.Fragment>

            <Form
                form={[
                    { label: t("numero_exemplaire"), attr: "numero", type: "text", size: { label: 200, field:100 }},
                    { label: t("localisation"), attr: "derniere_localisation", type: "display", size: { label: 200, field:400 }},
                    { label: t("emplacement"), attr: "emplacement", type: "display", size: { label: 200, field:400 }},

                    { label: t("etat_administratif"), attr: 'etat', type: "select", options : [
                        { label: "A vérifier", value: "averifier"},
                        { label: "Dépôt-vente", value: "depotvente"},
                        { label: "Disponible", value: "disponible"},
                        { label: "Disponible - Modèle d'expo", value: "disponiblemodeledexpo"},
                        { label: "Donné", value: "donne"},
                        { label: "Non présentable", value: "nonpresentable"},
                        { label: "Prêt", value: "pret"},
                        { label: "Prêt - Modèle d'expo", value: "pretmodeledexpo"},
                        { label: "Réservé", value: "reserve"},
                        { label: "Vendu", value: "vendu"},
                    ], size: { label: 200, field:300 }},

                    { label: t("etat_de_conservation"), attr: 'etat_de_conservation', type: "select", options : [
                        { label: "A restaurer", value: "arestaurer"},
                        { label: "A vérifier", value: "averifier"},
                        { label: "Neuf", value: "neuf"},
                        { label: "Bon état", value: "bon"},
                        { label: "Moyen", value: "moyen"},
                        { label: "Moyen / Mauvais", value: "moyenmauvais"},
                        { label: "Mauvais", value: "mauvais"},
                        { label: "Non restaurable", value: "nonrestaurable"},
                        { label: "Détruit", value: "detruit"},
                    ], size: { label: 200, field:300 }},

                    { label: t("non_signe"), attr: "nonsigne", type: "checkbox", size: { label: 200, field:100 }},
                    { label: t("non_numerote"), attr: "nonnumerote", type: "checkbox", size: { label: 200, field:100 }},

                    { label: t("conservation"), attr: "conservation", type: "text", multi:5, size: { label: 200, field:400 }},
                    { label: t("remarques"), attr: "remarques", type: "text", multi:5, size: { label: 200, field:400 }},
                ]}
                data={data}
                onChange={onChange}
            />

        </React.Fragment>

    )
}
