import axios from 'axios';
import { constructUrl, fetchApi as fetch, getToken } from './ApiCall';
import getConfig from 'config';

export const getAllUsers = async ({ token } = {}) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    let url = constructUrl("/users", false);
    if (token) {
        url = url + "?token="+token;
    }
    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    if (!responseJson.users) {
        throw new Error("Erreur inconnue.")
    }

    return responseJson.users;
}

export const getUserById = async ({ id }) => {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    let url = constructUrl("/user/" + id, false);
    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    if (!responseJson.user) {
        throw new Error("Erreur inconnue.")
    }

    return responseJson.user;
}

export const updateUser = async ({ id, data }) => {
    const token = getToken();
    if (!token) {
        throw new Error("Erreur inconnue.");
    }
    const url = constructUrl("/user/" + id + "?token=" + token, false);

    const rs = await axios.post(url, {
        user: data
    })

    return rs.data;
}

export const createUser = async ({ data }) => {

    var url = getConfig().API_BASE_URL;
    url = url + "/auth/invite?email=" + data["email"] + "&token=93ZEkDVVbyRDGup4S9fzobMFi3sfsa9dGQ";

    const rs = await axios.get(url, {
    })
    console.log("createUser :", rs.data)
    return rs.data;

}

