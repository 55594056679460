

import CONTACT_GENRES from 'Assets/json/ContactGenres.json'


export const getContactGenres = () => {

	var newElements = []

	CONTACT_GENRES.forEach( element => {

		var newElement = {}

		newElement["value"] = element["tag"]
		newElement["label"] = element["loc"]["fr"]
		newElements.push(newElement)

	})
	return newElements
}



export const critereStringFromArray = (array) => {

	var string = ""
	var count = 0

	if ( array != null ) {
		array.forEach( item => {
			string = string + item.value
			count = count + 1
			if ( count !== array.length ) {
				string = string + ","
			}
		})
	}

	return string
}


export const critereArrayFromString = (string) => {

	var items = []

	if ( string != null ) {
		var strings = string.split(',');

		strings.forEach( tag => {

			var found = critereItem(tag)

			if ( found !== null ) {
				items.push(found)
			}
		})
	}

	return items
}


export const critereItem = (tag) => {

	var found = null
	const genres = getContactGenres()

	if ( genres != null ) {
		genres.forEach( item => {

			if ( item["value"] === tag ) {
				found = item
			}
		})
	}	

	return found
}

