import React, { useState } from 'react';
import {
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next';

import {
    Button
} from '@material-ui/core';

import BiblioModal from 'pages/Avila/Modals/AddBiblio'
import BiblioListLayout from 'pages/Avila/Biblio/List/layout_oeuvre'

import { saveAs } from 'file-saver'
import { deleteOeuvreBib, createOeuvreBib, updateOeuvre } from 'Api';

export default ({ data, actions, reloadData = () => {}, onChange = () => {}, onClickEdit }) => {

    const [ addBiblioModal, setAddBiblioModal ] = useState(null);
    const { t } = useTranslation()

    const onClickAddBiblio = () => {
        setAddBiblioModal(true);
    }


    async function saveAsExcel() {
        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()

        ws.columns = [
            { header: 'Archive', key: 'Archive', width: 10 },
            { header: 'Genre', key: 'Genre', width: 20 },
            { header: 'Bibliographie', key: 'Bibliographie', width: 60 },
            { header: 'Année', key: 'Année', width: 10 },
        ];
 
        const biblios = data.oeuvre_bibs

        biblios.forEach(biblio => {

            const row = ws.addRow([
            biblio.archive,
            biblio.type_document,
             biblio.bibliographie,
             biblio.annee])
            row.font = { bold: false }

        })
        const buf = await wb.xlsx.writeBuffer()
        const filename = "Bibliographie Oeuvre #" + data.archive + ".xlsx"

        saveAs(new Blob([buf]), filename)
    }

    
    async function saveData(biblioId, biblio) {
                
        const res =  await createOeuvreBib({biblioId, data:biblio})
    
        biblio.objectid = res.objectId
        data = { ...data,
            oeuvre_bibs: [
                ...data.oeuvre_bibs,
                {"objectid" :res.objectId}
            ]
        }

        await updateOeuvre({id:data.objectid, data})
        reloadData()
        setAddBiblioModal(false)
    }

    async function deleteOeuvreBibAsync(oeuvre_bib) {
        await deleteOeuvreBib({ data: oeuvre_bib });

        data = {
            ...data,
            oeuvre_bibs: data.oeuvre_bibs.filter(e => e.objectid !== oeuvre_bib.objectid)
        }
        await updateOeuvre({id:data.objectid, data})
        reloadData()
    }

    return (

        <>
            {addBiblioModal &&
                <BiblioModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, biblio) => {
                                saveData(biblio.objectid, {"id_oeuvre" : data.objectid, "archive" : data.archive, "titre" : data.titre})
                            }
                        }
                    ]}
                    filterData={data.biblios}
                    onClose={() => {
                        setAddBiblioModal(false)
                    }}
                />
            }
            <BiblioListLayout
                data={data.oeuvre_bibs}
                headerActions={[
                    <Button key="add" onClick={onClickAddBiblio} variant="contained">{t('add_biblio')}</Button>,
                    <Button style={{marginLeft:'10px'}} key="export"  onClick={saveAsExcel} variant="contained">Export</Button>
                ]}
                actions={[
                    {
                        key: 'delete', icon: <DeleteIcon />, title: "Delete",
                        onClick: (id, oeuvre_bib) => {
                            if (window.confirm(`Supprimer ${oeuvre_bib.titre} ?`)) {
                                deleteOeuvreBibAsync(oeuvre_bib)
                            }
                        }
                    }
                ]}
            />
        </>
    )
}




