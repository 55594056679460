



export const getEmplacements = () => {

    return [
        "Numérique",
        "Salle d'archives A",
        "Salle d'archives B",
        "Salle d'archives C",
        "Salle d'archives D",
        "Salle d'archives E",
        "Salle d'archives F",
        "Salle d'archives G",
        "Salle d'archives H",
        "Salle d'archives I",
        "Salle d'archives J",
        "Salle d'archives K",
        "Salle d'archives L",
        "Salle d'archives M",
        "Salle d'archives N",
        "Salle d'archives O",
        "Salle d'archives P",
        "Salle d'archives Q",
        "Salle d'archives R",
        "Salle d'archives S",
        "Salle d'archives T",
        "Salle d'archives U"
    ]

}


export const getRangements = () => {

    return [
        "Boite de conservation",
    ]

}


