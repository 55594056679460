// @flow
import React from 'react';

import { createHistoriqueUpdateOeuvre, updateHistoriqueUpdateOeuvre } from 'Api';
import HistoriqueForm from 'pages/Avila/Historique/Form'

import FormModal from './FormModal'

type Props = {
    oeuvreid: string,
    onClose: any => any,
    actions: Array<any>,
    data: any,
    onSave: any => any
}
export default ({
    onClose,
    actions = [],
    data,
    oeuvre,
    oeuvreid,
    isMultiple,
    multiples,
    onSave = () => {}
}: Props) => {

    return (
        <FormModal
            title="Transaction"
            data={data}
            onClose={onClose}
            FormComponent={({ data, onChange }) => (
                <HistoriqueForm
                    isMultiple={isMultiple}
                    multiples={multiples}
                    data={data}
                    onChange={onChange}
                />
            )}

            onSave={onSave}
            updateMethod={({ data }) => updateHistoriqueUpdateOeuvre({oeuvre, data, isMultiple})}
            createMethod={({ data }) => createHistoriqueUpdateOeuvre({oeuvre, data})}
        />
    )
}