import React, { useState } from 'react';
import {
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next';

import {
    Button
} from '@material-ui/core';

import OeuvreModal from 'pages/Avila/Modals/AddArtwork'
import OeuvreListLayout from 'pages/Avila/ArtWork/List/layout_subpanel'


export default ({ data, actions, onChange = () => {}, onClickEdit }) => {

    const [ addOeuvreModal, setAddOeuvreModal ] = useState(null);
    const { t } = useTranslation()

    const onClickAddOeuvre = () => {
        setAddOeuvreModal(true);
    }

    return (

        <>
            {addOeuvreModal &&
                <OeuvreModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, oeuvre) => {
                                onChange({
                                    ...data,
                                    oeuvres: [
                                        ...data.oeuvres,
                                        oeuvre
                                    ]
                                })

                                setAddOeuvreModal(false);
                            }
                        }
                    ]}
                    filterData={data.oeuvres}
                    onClose={() => {
                        setAddOeuvreModal(false)
                    }}
                />
            }

            <OeuvreListLayout
                data={data.oeuvres}
                headerActions={[
                    <Button key="add" onClick={onClickAddOeuvre} variant="contained">{t('add_oeuvre')}</Button>
                ]}
                actions={[
                    {
                        key: 'delete', icon: <DeleteIcon />, title: "Delete",
                        onClick: (id, oeuvre) => {
                            if (window.confirm(`Supprimer ${oeuvre.titre} ?`)) {

                                onChange({
                                    ...data,
                                    oeuvres: data.oeuvres.filter(e => e.objectid !== oeuvre.objectid)
                                })
                            }
                        }
                    }
                ]}
            />
        </>
    )
}




