// @flow
import React from 'react';
import styled from 'styled-components';

///// TABS
export const TabsContainerHorizontal = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`
export const TabsContainerVertical = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-grow: 1;
`
export const TabsContainer = ({ orientation, ...props }: { orientation: 'horizontal' | 'vertical'}) =>
    orientation === 'vertical' ? <TabsContainerVertical {...props} /> : <TabsContainerHorizontal {...props} />

export const TabsPanels = styled.div`
    flex: 1;
    min-height: 400px;
`