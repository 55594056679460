import axios from 'axios';
import getConfig from 'config';

const constructUrl = name => (getConfig().API_BASE_URL + "/" + name);

export const login = async ({ email }) => {
    const url = constructUrl('auth/login');

    const rs = await axios.get(url,{
        params: {
            email
        }
    })
    if (rs.data.error) {
        throw new Error(rs.data.error);
    }

    return rs.data;
}

export const loginWithCode = async ({ email, code }) => {
    const url = constructUrl('auth/login');

    const rs = await axios.get(url,{
        params: {
            email,
            code
        }
    })
    if (rs.data.error) {
        throw new Error(rs.data.error);
    }

    return rs.data;
}