import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom'

import {
    CircularProgress
} from '@material-ui/core'

import ContactLayout from './index';
import SearchNav from 'components/SearchNav'
import { getContactById, updateContact } from 'Api'
import { useHistory } from 'react-router-dom'

export default () => {

    const params = useParams();
    const location = useLocation();
    const id = params.id;
    const [ contact, setContact ] = useState(null);
    const [ loading, setLoading ] = useState(true);
      const history = useHistory();

    const context = (location.state && location.state.context) || null;

    const loadData = async (id) => {
        const contact = await getContactById({ id })
         setContact(contact.contact);
         setLoading(false);
    }

    useEffect(() => {
        loadData(id);
    }, [ id ])


    let content = null;

    if (loading) {
        content = <CircularProgress />
    } else if (!id || !contact) {
        content = "NOT FOUND"
    } else {
        content = (

            <ContactLayout
                key={contact.objectid}
                initialData={contact}
                reloadData={() => loadData(id)}
                saveData={async data => {
                    await updateContact({ id: data.objectid, data });
                    return true;
                }}
                onSaved={() => {
                    loadData(id);
                }}
                onDelete={() => {
                    history.push("/contact");
                }}
            />
        )
    }
    return (
        <>
            {context === 'search_result' &&
                <SearchNav
                    search_type={"contact"}
                    constructPath={contact => ("/contact/" + contact.objectid)}
                    searchPath={"/contact/search"}
                />
            }

            {content}

        </>
    )
}