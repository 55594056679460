

import PHOTO_CRITERES from 'Assets/json/PhotoCriteres.json'


export const getPhotoCriteres = () => {

	var newElements = []

	PHOTO_CRITERES.forEach( element => {

		var newElement = {}

		newElement["value"] = element["tag"]
		newElement["label"] = element["loc"]["fr"]
		newElements.push(newElement)

	})
	return newElements
}




export const critereStringFromArray = (array) => {

	var string = ""
	var count = 0


	array.forEach( item => {
		string = string + item.value
		count = count + 1
		if ( count !== array.length ) {
			string = string + ","
		}
	})

	return string
}


export const critereArrayFromString = (string, criteres) => {

	var items = []

	if ( string != null ) {
		var strings = string.split(',');

		strings.forEach( tag => {

			var found = critereItem(tag, criteres)

			if ( found !== null ) {
				items.push(found)
			}
		})
	}

	return items
}


export const critereItem = (tag, criteres) => {

	var found = null

	criteres.forEach( item => {

		if ( item["value"] === tag ) {
			found = item
		}
	})	

	return found
}

