import { apiCall, constructUrl, getResult, fetchApi as fetch } from './ApiCall';

const constructBiblio = biblio => ({
    ...biblio,
})


export const getAllBiblios = async ({ cursor, limit } = {}) => {
    return await apiCall("getallbiblios")
}

export const getBiblioById = async ({ id }) => {
    const rs = await apiCall("getbiblio", "?biblio_id=" + id)
    if (rs.biblio && rs.biblio.length > 0) {
        return {
            biblio: constructBiblio(rs.biblio[0])
        }
    }

    return null;
}

export const deleteBiblio = async ({ biblio }) => {

   /* const response =*/ await apiCall("deletebiblio", "?biblio_id=" + biblio.objectid)

}

export const createBiblio = async ({ data }) => {
    const url = constructUrl("createbiblio");

    var formdata = new FormData();
    formdata.append("biblio", JSON.stringify({biblioData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createbiblio');
}

export const updateBiblio = async ({ id, data }) => {
    const url = constructUrl("updatebiblio");

    var formdata = new FormData();
    formdata.append("biblio_id", id);

    [
        'expositions', 'medias', 'photos', 'oeuvres', 'photo_items', 'biblios', 'oeuvre_bibs'
    ].forEach(attr => {
        if (data[attr]) {
            data[attr] = data[attr].map(item => item.objectid);
        }
    })


    formdata.append("biblio", JSON.stringify({biblioData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updatebiblio');
}

//
//
//

export const deleteOeuvreBib= async ({ data }) => {

    console.log("oeuvre_bib_id : ", data.objectid)
    await apiCall("deleteoeuvrebib", "?oeuvre_bib_id=" + data.objectid)

}


export const createOeuvreBibUpdateBiblio = async ( {biblio, data} ) => {

    const resCreate = await createOeuvreBib({biblioId:biblio.objectid, data});
    const objectId = resCreate["objectId"]

    biblio = {
        ...biblio,
        oeuvre_bibs: [
            ...biblio.oeuvre_bibs,
            {"objectid" : objectId}
        ]
    }

    await updateBiblio({ id: biblio.objectid, data: biblio });
}

export const createOeuvreBib = async ({ biblioId, data }) => {
    
    const url = constructUrl("createoeuvrebib");
    
    var formdata = new FormData();

    data["biblios"] = [biblioId]

    formdata.append("oeuvre_bib", JSON.stringify({ oeuvre_bibData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createoeuvre_bib');
}

export const updateOeuvreBib = async ({biblioId, data }) => {
    const url = constructUrl("updateoeuvrebib");

    var formdata = new FormData();
    formdata.append("oeuvre_bib_id", data.objectid);
    data["biblios"] = [biblioId]

    delete data['objectid']
    formdata.append("oeuvre_bib", JSON.stringify({ oeuvre_bibData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updateoeuvrebib');
}

