import { apiCall, constructUrl, getResult, fetchApi as fetch } from './ApiCall';
import { critereStringFromArray, critereArrayFromString} from 'Util/MediaCriteres'
import { langueStringFromArray, langueArrayFromString} from 'Util/MediaLangues'
import { langueSTStringFromArray, langueSTArrayFromString} from 'Util/MediaLanguesST'
import { formatStringFromArray, formatArrayFromString} from 'Util/MediaFormats'

const constructMedia = media => ({
    ...media,
    langue_array: langueArrayFromString(media.langue),
    langue_sous_titre_array: langueSTArrayFromString(media.langue_sous_titre),
    criteres_array: critereArrayFromString(media.criteres),
    format_array: formatArrayFromString(media.format)

})


export const getAllMedias = async ({ cursor, limit } = {}) => {
    return await apiCall("getallmedias")
}

export const getMediaById = async ({ id }) => {
    const rs = await apiCall("getmedia", "?media_id=" + id)
    if (rs.media && rs.media.length > 0) {
        return {
            media: constructMedia(rs.media[0])
        }
    }

    return null;
}

export const deleteMedia = async ({ media }) => {

    await apiCall("deletemedia", "?media_id=" + media.objectid)

}

export const createMedia = async ({ data }) => {

    console.log("inside createMedia : ", data)

    const url = constructUrl("createmedia");
    
    data['criteres'] = critereStringFromArray(data['criteres_array'])
    data['langue'] = langueStringFromArray(data['langue_array'])
    data['langue_sous_titre'] = langueSTStringFromArray(data['langue_sous_titre_array'])
    data['format'] = formatStringFromArray(data['format_array'])

    console.log("after createMedia : ", data)

    var formdata = new FormData();
    formdata.append("media", JSON.stringify({mediaData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createmedia');
}

export const updateMedia = async ({ id, data }) => {
    const url = constructUrl("updatemedia");

    [
        'expositions', 'biblios', 'photos', 'oeuvres', 'oeuvre_meds'
    ].forEach(attr => {
        if (data[attr]) {
            data[attr] = data[attr].map(item => item.objectid);
        }
    })
    
    data['criteres'] = critereStringFromArray(data['criteres_array'])
    data['langue'] = langueStringFromArray(data['langue_array'])
    data['langue_sous_titre'] = langueSTStringFromArray(data['langue_sous_titre_array'])
    data['format'] = formatStringFromArray(data['format_array'])

    var formdata = new FormData();
    formdata.append("media_id", id);
    formdata.append("media", JSON.stringify({mediaData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updatemedia');
}


//
//
//
export const deleteOeuvreMed = async ({ data }) => {

    console.log("oeuvre_med_id : ", data.objectid)
    await apiCall("deleteoeuvremed", "?oeuvre_med_id=" + data.objectid)

}

export const createOeuvreMedUpdateMedia = async ( {media, data} ) => {

    const resCreate = await createOeuvreMed({mediaId:media.objectid, data});
    const objectId = resCreate["objectId"]

    media = {
        ...media,
        oeuvre_meds: [
            ...media.oeuvre_meds,
            {"objectid" : objectId}
        ]
    }

    await updateMedia({ id: media.objectid, data: media });
}

export const createOeuvreMed = async ({ mediaId, data }) => {
    
    const url = constructUrl("createoeuvremed");
    
    var formdata = new FormData();

    data["medias"] = [mediaId]

    formdata.append("oeuvre_med", JSON.stringify({ oeuvre_medData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createoeuvre_med');
}

export const updateOeuvreMed = async ({mediaId, data }) => {
    const url = constructUrl("updateoeuvremed");

    var formdata = new FormData();
    formdata.append("oeuvre_med_id", data.objectid);
    
    data["medias"] = [mediaId]
    delete data['objectid']

    formdata.append("oeuvre_med", JSON.stringify({ oeuvre_medData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updateoeuvremed');
}
