import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Edit as EditIcon
} from '@material-ui/icons'

import Search from 'Api/Search/photo';
import DescriptionPanel from '../EditCreate/Description';
import ListLayout from '../List/layout_search'
import SearchLayout from 'layouts/SearchPage'
import { saveAs } from 'file-saver'
import { insertImagesFilename } from "Util/Export"

export default () => {

    const history = useHistory();
    const objectType = 'photo';


    async function exportPhoto(selectedList, photos) {

        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()
        
        ws.columns = [
            { header: 'Photo', key: 'filename', width: 30 },
            { header: 'Année', key: 'annee', width: 10 },
            { header: 'Copyright', key: 'copyright', width: 30 },
            { header: 'Photographe', key: 'photographe', width: 30 },
            { header: 'Localisation / Exposition', key: 'localisation', width: 60 },
        ];
 
        photos.forEach(photo => {
            
            const row = ws.addRow(["",
                    photo.annee,
                    photo.copyright,
                    photo.photographe,
                    photo.localisation])
            row.font = { bold: false }
            row.height = 100
        })

        await insertImagesFilename(wb, ws, photos, 0)

        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), "Photos.xlsx")
    }

    return (
        <SearchLayout
            exportList={[{"value":"photos", "name":"Liste photos"},]}
            onClickExport={exportPhoto}
            objectType={objectType}
            title={"Rechercher une photo"}
            search={Search}
            SearchFormComponent={props => (
                <DescriptionPanel layout="search" {...props} />
            )}
            ResultListComponent={({ data }) => (
                <ListLayout
                    data={data}
                    actions={[
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, object, index) => {
                                window.open("/"+objectType+"/" + object.objectid, {
                                    context: "search_result",
                                    search: {
                                        index
                                    }
                                });
                            }
                        }
                    ]}
                />
            )}

            onFeelingLucky={oeuvre => history.push("/"+objectType+"/"+oeuvre.objectid)}
        />
    )
}