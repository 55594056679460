import axios from 'axios';
import getConfig from 'config';

const constructUrl = type => (getConfig().SEARCH_BASE_URL + "/" + type);

export default async ({ type, query }) => {
    const url = constructUrl(type);

    const rs = await axios.post(url, {
        query: query.query
    })

    return rs.data;
}