// @flow
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'


type Props = {
    data: any,
    isMultiple: any,
    onChange: any => any
}

export default ({ data, isMultiple, onChange }: Props) => {
    
    const { t } = useTranslation();
    data["type"] = "enchere"

    return (
        <React.Fragment>

            <Form
                form={[
                    { label: t("date"), attr: "date_enchere", type: "dateselector", size: { label: 150, field:150 }},
                    
                    { label: t("interlocuteur"), attr: "source", type: "autocomplete", autocomplete: {
                        type: 'free',
                        options: [ "Bonhams", "Bukowskis", "Christies", "Cornette de Saint Cyr", "Dorotheum", "Germann Auktionhaus", "Ketterer Kunst", "Lempertz", "Odalys", " Palm Beach Modern Auctions", "Phillips", "Shapiro", "Sotheby's", "Swann Galleries", "Van Ham"]
                        }, size: { label: 150, field:400 }
                    },  

                    { label: t("lot"), attr: "lot", type: "text", size: { label: 150, field:400 }},
                    ...(isMultiple ? [
                        { label: t("numero_exemplaire"), attr: "multiple", type: "text", size: { label: 150, field:400 }},
                    ] : [] ),

                    [
                        { label: t("estimation_basse"), attr: "estimation_min", type: "text", size: { label: 150, field:100 } },
                        { label: t("devise"), attr: 'devise_estimation', type: "select", options : [
                            { label: "Euros", value: "Euros"},
                            { label: "Dollars", value: "Dollars"},
                        ], size: { left:50, label: 120, field:150 }},
                    ],

                    [
                        { label: t("estimation_haute"), attr: "estimation_max", type: "text", size: { label: 150, field:100 } },
                    ],

                    [
                        { label: t("prix_au_marteau"), attr: "prix_marteau", type: "text", size: { label: 150, field:100 } },
                        { label: t("devise"), attr: 'devise_prix_au_marteau', type: "select", options : [
                            { label: "Euros", value: "Euros"},
                            { label: "Dollars", value: "Dollars"},
                        ], size: { left:50, label: 120, field:150 }},
                    ],

                    [
                        { label: t("prix_de_vente"), attr: "prix_de_vente", type: "text", size: { label: 150, field:100 } },
                        { label: t("devise"), attr: 'devise_prix_de_vente', type: "select", options : [
                            { label: "Euros", value: "Euros"},
                            { label: "Dollars", value: "Dollars"},
                        ], size: { left:50, label: 120, field:150 }},
                    ],

                    { label: t("ville"), attr: "ville", type: "text", size: { label: 150, field:400 }},
                    {
                        label: t("pays"), attr: 'pays', type: "select",
                            selectType: 'countries', size: { label: 150, field: 400 }
                    },
                    { label: t("remarques"), attr: "remarques", type: "text", size: { label: 150, field:400 }},
                ]}
                data={data}
                onChange={onChange}
            />

        </React.Fragment>

    )
}
