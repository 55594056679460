



export const getOeuvreStatus = () => {

    return [
    	{label: "A jour", value: "a_jour"},
    	{label: "Approximatif", value: "approximatif"},
    	{label: "Mauvaises infos", value: "mauvaises_infos"},
        {label: "Problème", value: "probleme"},
    	{label: "Photo manquante", value: "photo_manquante"},
    	{label: "Image manquante", value: "image_manquante"},
    	{label: "Dos manquante", value: "dos_manquant"},
        {label: "Couleur manquante", value: "couleur_manquante"},
        {label: "Donnée manquante", value: "donne_manquante"},
        {label: "Localisation manquante", value: "localisation_manquante"},
        {label: "Identification manquante", value: "identification_manquante"},
        {label: "Améliorer l'image", value: "ameliorer_image"},
    ]
}


export const statusStringFromArray = (array) => {

	var string = ""
	var count = 0

	if ( array != null ) {
		array.forEach( item => {
			string = string + item.value
			count = count + 1
			if ( count !== array.length ) {
				string = string + ","
			}
		})
	}

	return string
}


export const statusArrayFromString = (string) => {

	var items = []

	if ( string != null ) {
		var strings = string.split(',');

		strings.forEach( tag => {

			var found = statusItem(tag)

			if ( found !== null ) {
				items.push(found)
			}
		})
	}

	return items
}


export const statusItem = (tag) => {

	var found = null
	const langues = getOeuvreStatus()

	langues.forEach( item => {

		if ( item["value"] === tag ) {
			found = item
		}
	})	

	return found
}

