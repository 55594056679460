import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import {
    Grid,
    Paper
} from '@material-ui/core';

import Tabs, {
    TabsContainer, TabsPanels as OriginalTabsPanels
} from 'components/Tabs'

import Form from 'components/Form';
import SalesTable from './Sales'
import MultiplesTable from './Multiples'

import { filtreOeuvresActuelles, filtreOeuvresPassees, filtreMultiplesActuels, filtreMultiplesPasses } from 'Util/Contacts'
import { getContactGenres } from 'Util/ContactCriteres';


const TabsPanels = styled(OriginalTabsPanels)`
    padding:20px;
    padding-top: 0px;
`

const FormContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const Box = styled(Paper)`
    padding:20px;
`

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)

export default ({ data = {}, onChange = () => {}, layout = 'editcreate' }) => {

    const { t } = useTranslation();

    const layoutIsSearch = layout === 'search';
    const layoutIsEdit = layout === 'edit';

    const form_left = [
        { label: t("structure"), attr: "structure", type: "text", size: { label: 150, field:300 }},

        { label: t("fonction"), attr: "fonction", type: "autocomplete", autocomplete: {
            type: 'free',
            options: [ "Avocat", "Directeur", "Docteur", "Président", "Conservateur", "Photographe", "Journaliste"]
            }, size: { label: 150, field:300 }
        },

        { label: t("title"), attr: "titre", type: "autocomplete", autocomplete: {
            type: 'free',
            options: [ "M.", "Mme", "M. et Mme"]
            }, size: { label: 150, field:150 }
        },
        { label: t("nom"), attr: "nom", type: "text", size: { label: 150, field:300 }},

        ...(layoutIsSearch ? [] :
            [{ label: t("prenom"), attr: "prenom", type: "text", size: { label: 150, field:300 }}]
        ),

        { label: t("genre_du_contact"), attr: "genres_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getContactGenres()
            },
            size: { label: 150, field: 350 }
        },

         [
            { label: t("invitation"), attr: "invitation", type: "checkbox", size: { label: 150, field:100 } },
            { label: t("prive"), attr: "prive", type: "checkbox", size: { label: 50, field:100 } },
        ],
        { label: t("remarques"), attr: "remarques", type: "text", multi:'4', size: { label: 150, field:300 }},

    ]

    const form_right = [


        { label: t("tel_pro"), attr: "tel_pro", type: "text", size: { label: 150, field:200 }},
        { label: t("tel_perso"), attr: "tel_perso", type: "text", size: { label: 150, field:200 }},
        { label: t("tel_autre"), attr: "tel_autre", type: "text", size: { label: 150, field:200 }},

        { label: t("email_pro"), attr: "email_pro", type: "text", size: { label: 150, field:300 }},
        { label: t("email_perso"), attr: "email_perso", type: "text", size: { label: 150, field:300 }},
        { label: t("email_autre"), attr: "email_autre", type: "text", size: { label: 150, field:300 }},

        { label: t("adresse"), attr: "adresse", type: "text", size: { label: 150, field:300 }},
        { label: t("code_postal"), attr: "code_postal", type: "text", size: { label: 150, field:100}},
        { label: t("ville"), attr: "ville", type: "text", size: { label: 150, field:300 }},
        {
            label: t("pays"), attr: 'pays', type: "select",
            selectType: 'countries', size: { label: 150, field: 300 }
        },

        { label: t("adresse_2"), attr: "adresse_2", type: "text", multi:"3", size: { label: 150, field:300 }},

    ]

    var oeuvresActuelles = []
    var oeuvresPassees = []
    var multiplesActuels = []
    var multiplesPasses = []

    if ( data.oeuvres_contact != null ) {
        oeuvresActuelles = filtreOeuvresActuelles(data.oeuvres_contact, data.objectid)
        oeuvresPassees = filtreOeuvresPassees(data.oeuvres_contact, data.objectid)
        multiplesActuels = filtreMultiplesActuels(data.oeuvres_contact, data.objectid)
        multiplesPasses = filtreMultiplesPasses(data.oeuvres_contact, data.objectid)
    }
    
  //  console.log("contact : ", data)

    return (

        <GridContainer>

            <Grid item xs={6}>
                <Box elevation={1}>
                    <FormContainer>
                        <Form form={form_left}  data={data} onChange={onChange}/>
                    </FormContainer>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box elevation={1}>
                    <FormContainer>
                        <Form form={form_right}  data={data} onChange={onChange}/>
                    </FormContainer>
                </Box>
            </Grid>

            {!layoutIsSearch && layoutIsEdit && <Grid item xs={12}>

                <TabsContainer orientation={"horizontal"}>

                    <Tabs
                        orientation={"horizontal"}
                        variant="scrollable"
                        tabs={[

                            {
                                label: t("oeuvres_detenues"),
                                panel: (
                                    <TabsPanels>
                                        <SalesTable data={oeuvresActuelles}/>
                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("multiples_detenus"),
                                panel: (
                                    <TabsPanels>
                                        <MultiplesTable data={multiplesActuels}/>
                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("oeuvres_detenues_passe"),
                                panel: (
                                    <TabsPanels>
                                        <SalesTable data={oeuvresPassees}/>
                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("multiples_passes"),
                                panel: (
                                    <TabsPanels>
                                        <MultiplesTable data={multiplesPasses}/>
                                    </TabsPanels>
                                )
                            },
                        ]}
                    />

                </TabsContainer>
            </Grid>}

        </GridContainer>


    )
}