

export const convertToDuration = data => {

    if ( !data ) {
        return ""
    } 
    const hours = Math.trunc(data / (60*60))
    const minutes = Math.trunc((data - hours*60*60)/60)
    const secondes = data - hours*60*60 - minutes*60
    var duration = ""

    if ( hours > 0 ) {
        if ( hours >= 10 ) {
            duration = String(hours)
        } else {
            duration = "0" + String(hours)
        }
        duration = duration + ":"
    }

    if ( minutes >= 10 ) {
        duration = duration + String(minutes)
    } else {
        duration = duration + "0" + String(minutes)
    }
    duration = duration + ":"

    if ( secondes >= 10 ) {
        duration = duration + String(secondes)
    } else {
        duration = duration + "0" + String(secondes)
    }

    return duration

}



