import React, { useEffect, useState } from 'react';

import {
    CircularProgress,
} from '@material-ui/core'

import { getAllExpos } from 'Api';

import Layout from './layout'

import PageLayout from 'layouts/Helpers/PageLayout'
import { sortAlphaNum } from 'Util/Sorting'


export default ({ actions }) => {

    const [ expositions, setExpositions ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getAllExpos().then(expositions => {

            var expos = expositions.expositions
    
            expos.sort(function (a, b) {

                if ( a.archive && b.archive ) {
                    return sortAlphaNum(a.archive.toLowerCase(), b.archive.toLowerCase())
                }
                return -1
            });

            setExpositions(expos || []);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <CircularProgress />
    }

    return (
        <PageLayout title={"Expositions"} actions={(
            <div>
            </div>
        )}>
            <Layout data={expositions} actions={actions} onChange={setExpositions}/>
        </PageLayout>
    )
}