import React, { useState } from 'react';
import DataTable from 'components/DataTable'
import HistoriqueModal from 'pages/Avila/Modals/Historique'
import { useTranslation } from 'react-i18next'
import Moment from 'moment';

import { isSelectedMultiples  } from 'Util/OeuvreMultiple';
import { filterHistoriques } from 'Util/OeuvreHistorique';
import { displayProprio } from 'Util/OeuvreProprietaire';
import { deleteHistorique, updateOeuvre } from 'Api/artworks'
//import { sortDate } from 'Util/Sorting'

import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@material-ui/icons'

import {
    Grid, Button
} from '@material-ui/core';

import styled from 'styled-components';

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)


export default ({ data, isMultiple, selectedMultiples, reloadData = () => {}, actions, onChange = () => {}, onClickEdit }) => {

    const [ editHistorique, setEditHistorique ] = useState(null);
    const { t } = useTranslation();

    if ( data.historiques ) {
        data.historiques.sort(function (a, b) {
            if ( a && b ) {
                if ( a.date_historique && b.date_historique ) {
                    return a.date_historique > b.date_historique ? -1 : 1;
                } else if ( a.date_historique ) {
                    return -1
                } else if ( b.date_historique ) {
                    return 1
                }
            }
            return -1
        });
    }

    const isFiltered = isSelectedMultiples(selectedMultiples)
    
    var filteredHistoriques = []

    if ( isFiltered === true ) {
        filteredHistoriques = filterHistoriques(data.historiques, selectedMultiples)
    } else {
        filteredHistoriques = data.historiques
    }

    async function updateOeuvreAsync(historique) {

        await deleteHistorique({ historique });

        data = {
            ...data,
            historiques: data.historiques.filter(e => e.objectid !== historique.objectid)
        }

        await updateOeuvre({ id: data.objectid, data });
        onChange(data)
    }


    return (

        <GridContainer>
            <Grid item xs={12}>
                {editHistorique &&
                    <HistoriqueModal
                        data={editHistorique}
                        oeuvre={data}
                        oeuvreid={data.objectid}
                        isMultiple={isMultiple}
                        multiples={data.multiples}
                        onClose={() => {
                            setEditHistorique(false)
                        }}
                        onSave={() => reloadData()}
                    />
                }

                <DataTable
                    data={filteredHistoriques}
                    headerActions={[
                        <Button key="add" onClick={() => setEditHistorique({})} variant="contained">{t('add_owner')}</Button>
                    ]}
                    config={{
                        cells: [
                            { title: t('date'), key: 'date_historique' },
                            { title: t('type'), key: 'type' },
                            ...(isMultiple ? [
                                { title: t('exemplaire'), key: 'multiple' },
                            ] : [] ),
                            { title: t('previous_owner'), key: 'ancien_proprio' },
                            { title: t('new_owner'), key: 'nouveau_proprio' },
                            { title: t('remarques'), key: 'remarques' },
                        ],
                        actions: actions || [
                            { icon: <EditIcon />, title: "Edit", onClick: (id, historique) =>  setEditHistorique(historique)},
                            { icon: <DeleteIcon />, title: "Delete", onClick: (id, historique) => {  
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                    updateOeuvreAsync(historique)
                                }
                            }}
                        ]
                    }}
                    getCell={(item, attr_name) => {

                        if ( attr_name === "ancien_proprio" ) {

                            if ( item.ancien_proprio && item.ancien_proprio.length > 0 ) {
                                item.ancien_proprio = item.ancien_proprio[0]
                            }
                            return displayProprio(item.ancien_proprio)

                        } else if ( attr_name === "nouveau_proprio" ) {
                            
                            if ( item.nouveau_proprio && item.nouveau_proprio.length > 0 ) {
                                item.nouveau_proprio = item.nouveau_proprio[0]
                            }
                            return displayProprio(item.nouveau_proprio)
                        }

                        if (attr_name === 'date_historique') {
                            if (item['date_historique']) {
                                return Moment(item['date_historique']).format('DD-MM-YYYY')
                            }
                            return ""   
                        }
                        return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                    }}
                />
            </Grid>

        </GridContainer>
    )
}
