import React from 'react';
import DataTable from 'components/DataTableWithHeader'
import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@material-ui/icons'


import ArtWorks from 'data/ArtWorks';
import MuiPaper from '@material-ui/core/Paper';
import Image from 'components/Img'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components';
import { useCurrentPermissionProfile } from 'redux/hooks';
import { deleteOeuvre } from 'Api'
import { sortAlpha, sortNumbers } from 'Util/Sorting'

import TYPES from 'Assets/json/TypesOeuvre.json'

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = ArtWorks, actions, onChange, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const permissionProfile = useCurrentPermissionProfile();
    const canDelete = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === null;


    async function deleteOeuvreAsync(oeuvre) {
        /*const result =*/ await deleteOeuvre({ oeuvre });
        onChange(data.filter(e => e.objectid !== oeuvre.objectid))
    }

    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if ( (category === "archive") || (category === "annee_creation") ) {

                return sortNumbers(a[category], b[category], order)
                
            } else if ( (category === "titre")) {

                return sortAlpha(a[category], b[category], order)

            } else if ( (category === "type") ) {
                const type_1 = a[category]-1
                const type_2 = b[category]-1
                var type_one = ""
                var type_two = ""

                if ( TYPES[type_1] ) {
                    type_one =  TYPES[type_1].loc[i18n.language]
                }
                if ( TYPES[type_2] ) {
                    type_two =  TYPES[type_2].loc[i18n.language]
                }
                return sortAlpha(type_one, type_two, order)
            }
            return -1
        });
    }

    data.sort(function (a, b) {
        return sortNumbers(a["archive"], b["archive"], true)
    });

    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: artwork => {
                        history.push("/artwork/" + artwork.objectid);
                    },
                    cells: [
                        { title: t('nb_archive'), key: 'archive', bold:true, width:100 },
                        { title: t('photo'), key: 'filename', width:100, sort:false },
                        { title: t('title'), key: 'titre', width:750  },
                        { title: t('creation_year'), key: 'annee_creation', width:200 },
                        { title: t('type_doeuvre'), key: 'type', width:200 }
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, artwork) => {
                                window.open("/artwork/" + artwork.objectid)
                            }
                        },
                        ...(canDelete ? [
                            {
                                icon: <DeleteIcon />, title: "Delete",
                                onClick: (id, oeuvre) => {
                                    if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                        deleteOeuvreAsync(oeuvre)
                                    }
                                },
                            }] : []
                        )
                    ],
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === "filename") {
                        return (
                            <Image
                                imageWidth={60}
                                style={{justifyContent: 'flex-start'}}
                                alt="" filename={item.filename}
                            />
                        )
                    } else if ( attr_name === "type") {

                        const type = item["type"]-1
                        if ( TYPES[type] ) {
                            return TYPES[type].loc[i18n.language]
                        }
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>

    )
}