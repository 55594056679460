// @flow
import React from "react";

import {
    MenuItem,
    TextField,
} from '@material-ui/core';



export type Props = {
    value?: number,
    onChange?: (value: number) => any
}


export default ({ value, onChange, list, ...props }: Props) => {

    const valueIsValid = list.find(el => el.value === value) !== undefined;
    const first = list[0].value

    return (
        <TextField
            {...props}
            size="small"
            select
            variant='outlined'
            defaultValue={first}
            value={valueIsValid ? value : first}
            onChange={e => {
                onChange && onChange(e.target.value)
            }}
        >
            <MenuItem value={0}></MenuItem>
            {list.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
	)
};