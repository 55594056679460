import React from 'react';
import DataTable from 'components/DataTableWithHeader'

import {
    Edit as EditIcon
} from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import Biblios from 'data/Biblios';
import Image from 'components/Img';


import { sortAlpha, sortArchiveBiblio, sortNumbers } from 'Util/Sorting'


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Biblios, actions, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();
    

    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if ( category === "annee" ) {

                return sortNumbers(a[category], b[category], order)

            } else if ( (category === "titre")) {
                return sortAlpha(a[category], b[category], order)

            } else if ( (category === "archive")) {
                return sortArchiveBiblio(a[category], b[category], order)
            }
            return -1
        });
    }

    return (
        <Paper elevation={3}>

            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}

            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: (biblio, index) => {
                        history.push("/biblio/" + biblio.objectid, {
                            context: "search_result",
                            search: {
                                index
                            }
                        });
                    },
                    cells: [
                        { title: t('archive'), key: 'archive', width:'150px', bold:true },
                        { title: t('title'), key: 'titre', width:'700px' },
                        { title: t('annee'), key: 'annee', width:'80px', sort:false  },
                        { title: t('type_de_document'), key: 'type_document', width:'150px', sort:false  },
                        { title: t('auteur'), key: 'auteurs', width:'200px', sort:false },
                        { title: t('exemplaires'), key: 'nb_exemplaires', width:'200px', sort:false  },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, biblio) => {
                                window.open("/biblio/" + biblio.objectid)
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === "filename") {
                        return (
                            <Image
                                imageWidth={60}
                            style={{justifyContent: 'flex-start'}}
                                alt="" filename={item.filename}
                            />
                        )
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}