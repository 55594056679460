import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames'
import FileSaver from 'file-saver';
import Lightbox from 'react-image-lightbox';

import Fab from '@material-ui/core/Fab';
import {
    Delete
} from '@material-ui/icons'
import { Button, LinearProgress } from '@material-ui/core';

import { getImagePath } from 'Util/Image';

import MediaUploader from '../MediaUploader'
import { useTranslation } from 'react-i18next'

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .action_container {
        position: absolute;
        z-index: 10;
        top: 10px; right: 10px;

        display: flex;
        flex-direction: flex-end;
        align-items: center;
        >* {
            margin-left: 10px;
        }
    }
    .action_container.outside {
        top: -70px;
    }

    > .save_btn {
        position: absolute;
        top: 10px; right: 10px;
    }

    > img {
        z-index: 3;
    }

    .progress {
        position: absolute;
        z-index:9;
        top:0; left:0;
        width: 100%;
    }
`

export default ({
    src: doNotUseSrc,
    downloadFile = false,
    alt="",
    imgStyle = {},
    filename,
    subdir = "photos",

    actionPosition = 'inside', // or 'outside'
    onChangeLabel = "Changer de photo",
    onChange, onClickDelete,

    imageHeight,
    imageWidth,

    ...props
}) => {


    const mediaUploader = useRef()
    const [ uploading, setUploading ] = useState(false);
    const [ lightboxOpened, setLighboxOpened ] = useState(false);
    const [ uploadPercentage, setUploadPercentage ] = useState(0)
    const [ displayProgress, setDisplayProgress ] = useState(false)
    const { t } = useTranslation();

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    }, []);

    const onClickChange = () => {
        mediaUploader.current.triggerFileSelection();
    }

    const reset = () => {
        setDisplayProgress(false);
        setUploadPercentage(0);
        setUploading(false);
    }

    const src = filename ? getImagePath(filename, subdir) : getImagePath("default.jpg", subdir);
    const imageProps = {};
    const resizedSrc = (() => {
        if (imageHeight) {
            imageProps.height = imageHeight;
            return src+"?height=" + imageHeight;
        }
        if (imageWidth) {
            imageProps.width = imageWidth;
            return src+"?width=" + imageWidth;
        }

        return src;
    })()

    return (
        <ImageContainer {...props} onClick={e => {
            if (lightboxOpened) {
                e.stopPropagation();
                e.preventDefault();
            }
            if (props.onClick) {
                props.onClick(e);
            }
        }}>

            {displayProgress &&
                <LinearProgress variant="determinate" className={"progress"} value={uploadPercentage} />
            }
            <div className={classNames('action_container', actionPosition)}>

                {downloadFile &&
                    <Button variant="contained" color="primary" onClick={() => FileSaver.saveAs(src, downloadFile)}> Export</Button>
                }
                {onChange &&
                    <Button variant="contained" color="primary" onClick={onClickChange}>{t("changer_de_photo")}</Button>
                }
                {onClickDelete &&
                    <Fab size="small" color="primary" aria-label="delete" onClick={onClickDelete} className="save_btn">
                        <Delete />
                    </Fab>
                }
            </div>

            {filename &&
                <img
                    src={resizedSrc}
                    alt={alt}
                    style={{...imgStyle, opacity: uploading ? 0.5 : 1, cursor: 'zoom-in'}}
                    {...imageProps}
                    onClick={e => {
                        setLighboxOpened(true);
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                />
            }
            {!filename &&
                <img
                    src={src}
                    alt={alt}
                    style={{...imgStyle, opacity: uploading ? 0.5 : 1, cursor: 'zoom-in'}}
                    {...imageProps}

                />
            }

            {onChange && <MediaUploader
                ref={mediaUploader}
                onFileDialogCancel={() => {
                    reset()
                }}
                onProgress={setUploadPercentage}
                onUploadStart={() => {
                    setDisplayProgress(true)
                    setUploading(true)
                }}
                onUploaded={fileUploaded => {
                    onChange(fileUploaded.fileName)
                    window.setTimeout(() => {
                        if (!mounted.current) {
                            return;
                        }
                        reset();
                    }, 500)
                }}
                style={{ zIndex: filename ? -1 : 0, position: 'absolute', top: 0, left: 0, height: '100%', width: '100%'}}
            />}

            {src && lightboxOpened &&
                <Lightbox
                    onCloseRequest={() => setLighboxOpened(false)}
                    mainSrc={src}
                    reactModalStyle={{ overlay: {zIndex: 100000}}}
                />
            }

        </ImageContainer>
    )
}