import React from 'react';
import moment from 'moment';

import { DatePicker } from "@material-ui/pickers";
import { XCircle  } from 'react-feather';
import styled from 'styled-components';

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`

const Delete = styled.div`
    width:20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
`


export default ({
    label,
    value,
    onChange,
    displayFormat = "L",
    format = 'YYYY-MM-DD'
}) => {


    return (
        <DatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label={label}
            value={value ? moment(value, format).locale('fr') : ""}
            defaultValue={null}
            format={displayFormat}
            invalidLabel= "no good date"
            onChange={momentDate => {
                onChange(momentDate.format(format))
            }}

            TextFieldComponent={({ value: valueInput, InputProps, inputRef, onClick, onKeyDown, ...props }) => {
                return (
                    <InputContainer>
                        <input value={value ? valueInput : ""} {...InputProps} style={{width: '100%'}} type="text" ref={inputRef} onClick={onClick} onKeyDown={onKeyDown}  />
                        {value && <Delete onClick={() => onChange(null)}>
                            <XCircle size={"18px"}/>
                        </Delete>}
                    </InputContainer>
                )
            }}
        />
    )
}