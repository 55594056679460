// @flow
import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';


export default ({
    when = false,
    message
}: {
    when: boolean,
    message: string
}) => {

    useEffect(() => {
        if (!when) {
            return () => {};
        }

        const onBeforeUnload = function (event) {
            event.preventDefault();

            event.returnValue = message;
            return message;
        }

        window.addEventListener("beforeunload", onBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        }
    }, [when, message])

    return (
        <Prompt
            when={when}
            message={message}
        />
    )
}