import React from 'react';
import { useHistory } from 'react-router-dom'
import { createConsignation } from 'Api'
import  ConsignationLayout from './index';

export default () => {

    const history = useHistory();

    return (
        <ConsignationLayout
            layout="create"
            saveData={async data => {

                const result = await createConsignation({ data });
                return result;
            }}
            onSaved={consignation => {
                history.push("/consignation/" + consignation.objectId);
            }}
        />
    )
}