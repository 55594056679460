import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
    Grid,
    Paper
} from '@material-ui/core';

import Tabs, {
    TabsContainer, TabsPanels as OriginalTabsPanels
} from 'components/Tabs'

import Form from 'components/Form';

import ArtworksPanel from './Artworks/';
import ExposPanel from '../../../Expo/SubPanel';
import BibliosPanel from '../../../Biblio/SubPanel';
import PhotosPanel from '../../../Photo/SubPanel';

import { getMediaCriteres } from "Util/MediaCriteres"
import { getMediaLangues } from "Util/MediaLangues"
import { getMediaLangueSTs } from "Util/MediaLanguesST"
import { getMediaFormats } from "Util/MediaFormats"
import { updateMedia } from 'Api/medias'
import { cleanNumber } from "Util/Divers"
import { getEmplacements, getRangements } from "Util/MediaRangement"


const TabsPanels = styled(OriginalTabsPanels)`
    padding:20px;
    padding-top: 0px;
`
const FormContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const Box = styled(Paper)`
    padding:20px;
`

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)

export default ({ data, reloadData = () => {}, onChange = () => {}, layout = 'edit' }) => {

    const { t } = useTranslation()
    
    const layoutIsSearch = layout === 'search';
    const layoutIsEdit = layout === 'edit';

    const is_soustitre = data.sous_titre
    
    if ( data.oeuvre_meds ) {
        data.oeuvre_meds.sort(function (a, b) {
            return a.titre < b.titre ? -1 : 1;
        });
    }

    const isDateInvalid = !data["date_de_parution"]?0:1
    
    if ( data.duree === null ) {
        data.duree = 0
    }

    if ( data.duree ) {
        data.duree_heures = Math.trunc(data.duree / (60*60))    
        data.duree_minutes = Math.trunc((data.duree - data.duree_heures*60*60)/60)
        data.duree_secondes = data.duree - data.duree_heures*60*60 - data.duree_minutes*60
    } else {
        
    }
    
    const form_left = [

        { label: t("archive"), attr: "archive", type: "text", size: { label: 150, field:150  }},
        { label: t("title"), attr: "titre", type: "text", size: { label: 150, field:450 }},

        ...(!layoutIsSearch ? [
            [
                { label: t("date_de_parution"), attr: "date_parution", type: "dateselector", size: { label: 150, field:150  }},
                { label: "Circa", attr: "circa", type: "checkbox", size: { label: 10, left:30 }},
                ...(!isDateInvalid ? [ { label: "", attr: "date_parution_invalid", type: "text", size: { label: 10, field:150 }},]:[])
            ]
        ] : [
            [
                { label: t("date_de_parution"), attr: "date_parution", type: "dateselector", size: { label: 150, field:150  }},
                { label: " - ", attr: "date_parution_max", type: "dateselector", size: { label: 20, field:150  }},
            ]
        ]),

        ...(!layoutIsSearch ? [
            [
                { label: t("duree"), attr: "duree_heures", type: "text", size: { label: 150, field:30 }},
                { label: "h ", attr: "duree_minutes", type: "text", size: { left:0, label: 0, field:30 }},
                { label: "m", attr: "duree_secondes", type: "text", size: { left:0, label: 0, field:30 }},
                { label: "s", attr: "", type: "display", size: { left:0, label: 0, field:30 }},
            ],
        ] : [
            [
                { label: t("duree"), attr: "minutes_min", type: "text", size: { label: 150, field:50 }},
                { label: " -", attr: "minutes_max", type: "text", size: { left:20, label: 0, field:50 }},
                { label: "minutes", attr: "minutes_text", type: "display", size: { left:20, label: 100, field:0 }},
            ]
        ]),
        
        { label: t("genre"), attr: "genre", type: "autocomplete",
            autocomplete: {
                type: 'free',
                options: [ "Documentaire", "Programme TV", "Vidéo amateur", "Musique", "Programme radio", "Vidéo professionelle"]
           }
            , size: { label: 150, field:350 }
        },

        { label: t("criteres"), attr: "criteres_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getMediaCriteres()
            },
            size: { label: 150, field: 350 }
        },

        { label: t("langue_son"), attr: "langue_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getMediaLangues()
            },
            size: { label: 150, field: 350 }
        },

        { label: t("sous_titre"), attr: "sous_titre", type: "checkbox", size: { label: 150, field:50 } },

        ...(is_soustitre ? [
        { label: t("langue_soustitre"), attr: "langue_sous_titre_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getMediaLangueSTs()
            },
            size: { label: 150, field: 300 }
        },
        ] : []
         ),

        { label: t("mots_clefs"), attr: "mots_clefs", type: "text", multi:5, size: { label: 150, field:350 } },
        { label: t("remarques"), attr: "remarques", type: "text", multi: 10, size: { label: 150, field:350 }  },

    ]

    const form_right = [

 
        { label: t("source"), attr: "source", type: "text", size: { label: 150, field:300 }},
        { label: t("site_web"), attr: "site_web", type: "text", size: { label: 150, field:300 } },
        { label: t("support_original"), attr: "support_original", type: "text", size: { label: 150, field:300 }},

        { label: t("format"), attr: "format_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getMediaFormats()
            },
            size: { label: 150, field: 300 }
        },
        { label: t("emplacement"), attr: "emplacement", type: "autocomplete", autocomplete: {
                    type: 'free', options: getEmplacements()}
                , size: { label: 150, field:400 }
        },
        { label: t("rangement"), attr: "rangement", type: "autocomplete", autocomplete: {
                    type: 'free', options: getRangements()}
                , size: { label: 150, field:400 }
        },

        { label: t("fonction"), attr: "fonction", type: "autocomplete",
            autocomplete: {
                type: 'free',
                options: ["Réalisateur", "Producteur", "Journaliste", "Chroniqueur", "Ecrivain", "Autre"]
            }, size: { label: 150, field:300 }
        },
        { label: t("nom"), attr: "nom", type: "text", size: { label: 150, field:300 }},
        { label: t("prenom"), attr: "prenom", type: "text", size: { label: 150, field:300 }},
        { label: t("email"), attr: "email", type: "text", size: { label: 150, field:300 } },
        { label: t("phone"), attr: "telephone", type: "text", size: { label: 150, field:300 }},

        { label: t("adresse"), attr: "adresse", type: "text", multi:3, size: { label: 150, field:300 }},
        { label: t("code_postal"), attr: "code_postal", type: "text", size: { label: 150, field:100 }},
        { label: t("ville"), attr: "ville", type: "text", size: { label: 150, field:300 }},
        { label: t("pays"), attr: 'pays', type: "select",
            selectType: 'countries', size: { label: 150, field: 300 }
        },
    ]

    async function updateMediaAsync(data) {
        await updateMedia({ id: data.objectid, data });
        reloadData(data)
    }

    return (

        <GridContainer>

            <Grid item xs={6}>
                <Box elevation={1}>
                    <FormContainer>
                        <Form form={form_left}
                            data={data}
                            onChange={
                                data => {
                                    data.duree = (cleanNumber(data.duree_heures) * 60 * 60) + (cleanNumber(data.duree_minutes) * 60) + cleanNumber(data.duree_secondes)
                                    onChange(data)
                                }
                            }
                        />
                    </FormContainer>
                </Box>
            </Grid>

            <Grid item xs={6}>
                <Box elevation={1}>
                    <FormContainer>
                        <Form form={form_right} data={data} onChange={onChange}/>
                    </FormContainer>
                </Box>
            </Grid>


            <Grid item xs={12}>

                <TabsContainer orientation={"horizontal"}>

                    <Tabs
                        orientation={"horizontal"}
                        variant="scrollable"
                        tabs={[
                            {
                                label: t("transcription"),
                                panel: (
                                    <TabsPanels>
                                        <Paper elevation={3}>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Form form={[
                                                    { label: t(""), attr: "transcription", type: "text", multi: 16, size:{label :0, field:500} }
                                                ]} data={data} onChange={onChange}/>
                                            </Grid>
                                        </Grid>
                                         </Paper>
                                    </TabsPanels>
                                )
                            },

                            ...(layoutIsEdit ? [

                            {
                                label: t("oeuvres"),
                                panel: (
                                    <TabsPanels>
                                        <ArtworksPanel  layout={layout} reloadData={reloadData} data={data} onChange={updateMediaAsync}/>
                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("expos"),
                                panel: (
                                    <TabsPanels>
                                        <ExposPanel  layout={layout} data={data} onChange={updateMediaAsync}/>
                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("biblios"),
                                panel: (
                                    <TabsPanels>
                                        <BibliosPanel  layout={layout} data={data} onChange={updateMediaAsync}/>
                                    </TabsPanels>
                                )
                            },
                            {
                                label: t("photos"),
                                panel: (
                                    <TabsPanels>
                                        <PhotosPanel  layout={layout} data={data} onChange={updateMediaAsync}/>
                                    </TabsPanels>
                                )
                            },
                            ]: []),

                        ]}
                    />

                </TabsContainer>

            </Grid>


        </GridContainer>

    )
}