



export const getMediaCriteres = () => {

    return [
    	{label: "Artistes", value: "artistes"},
    	{label: "Atelier", value: "atelier"},
    	{label: "Conférence", value: "conference"},
    	{label: "Critique d'art", value: "critique_d_art"},
    	{label: "Discours", value: "discours"},
    	{label: "Domicile", value: "domicile"},
    	{label: "Entretien", value: "entretien"},
    	{label: "Exposition collective", value: "exposition_collective"},
    	{label: "Exposition personnelle", value: "exposition_personnelle"},
        {label: "Famille", value: "famille"},
    	{label: "Foire", value: "foire"},
        {label: "Installation d'oeuvre", value: "installation_oeuvre"},
        {label: "Jeunesse", value: "jeunesse"},
        {label: "Guitare", value: "guitare"},
        {label: "Montage d'exposition", value:"montage_d_exposition"},
        {label: "Museo Jesus Soto", value:"museo_jesus_soto"},
        {label: "Musique", value: "musique"},
        {label: "Oeuvre non identifiée", value:"oeuvre_non_identifiee"},
        {label: "Officiels", value: "officiels"},
        {label: "Pénétrable", value: "penetrable"},
        {label: "Remise de prix", value: "remise_de_prix"},
        {label: "Vernissage", value: "vernissage"},
        {label: "Vue d'oeuvres", value: "vue_d_oeuvres"},
        {label: "Vue de salle", value:"vue_de_salle"}
    ]
}


export const critereStringFromArray = (array) => {

	var string = ""
	var count = 0

	if ( array != null ) {
		array.forEach( item => {
			string = string + item.value
			count = count + 1
			if ( count !== array.length ) {
				string = string + ","
			}
		})
	}

	return string
}


export const critereArrayFromString = (string) => {

	var items = []

	if ( string != null ) {
		var strings = string.split(',');

		strings.forEach( tag => {

			var found = critereItem(tag)

			if ( found !== null ) {
				items.push(found)
			}
		})
	}

	return items
}


export const critereItem = (tag) => {

	var found = null
	const genres = getMediaCriteres()

	if ( genres != null ) {
		genres.forEach( item => {

			if ( item["value"] === tag ) {
				found = item
			}
		})
	}	

	return found
}

