import React from 'react';
import DataTable from 'components/DataTableWithHeader'

import {
    Edit as EditIcon
} from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import Biblios from 'data/Biblios';

import { sortAlpha, sortArchiveBiblio, sortNumbers } from 'Util/Sorting'


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Biblios, actions, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();
    
    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if (category === "annee" ) {

                return sortNumbers(a[category], b[category], order)
            
            } else if ( category === "archive") {

             return sortArchiveBiblio(a[category], b[category], order)

            } else if ( category === "bibliographie") {

                return sortAlpha(a[category], b[category], order)

            }
            return -1
        });
    }

    return (
        <Paper elevation={3}>

            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}

            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: biblio => {
                        window.open("/biblio/" + biblio.id_biblio)
                    },
                    cells: [
                        { title: t('archive'), key: 'archive', width:'100px', bold:true },
                        { title: t('bibliographie'), key: 'bibliographie', width:'600px' },
                        { title: t('annee'), key: 'annee', width:'100px' },
                        { title: t('auteur'), key: 'auteurs', width:'300px', sort:false },
                        { title: t('page'), key: 'page', width:'150px', sort:false },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, biblio) => {
                                history.push("/biblio/" + biblio.id_biblio);
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name) => {
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}