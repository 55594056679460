import React, { useState } from 'react';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon } from '@material-ui/icons'


import DataTable from 'components/DataTableWithHeader'
import OeuvreModal from 'pages/Avila/Modals/AddArtwork'
import OeuvreMedModal from 'pages/Avila/Modals/MediaOeuvre'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Image from 'components/Img'

import { deleteOeuvreMed } from 'Api/medias'
import { sortAlpha, sortNumbers } from 'Util/Sorting'


const Paper = styled(MuiPaper)`
    padding: 10px;
`



export default ({ data, actions, reloadData = () => {}, onChange = () => {}, onClickEdit }) => {

    const [ addOeuvre, setAddOeuvre ] = useState(false);
    const [ editOeuvreMed, setEditOeuvreMed ] = useState(false);
    const { t } = useTranslation()


    const onClickAddOeuvre = () => {
        setAddOeuvre(true);
    }


    async function deleteOeuvreAsync(oeuvre_med) {
        /*const result =*/ await deleteOeuvreMed({ data : oeuvre_med });
        onChange({
            ...data,
            oeuvre_meds: data.oeuvre_meds.filter(e => e.objectid !== oeuvre_med.objectid)
        })
    }

    function sorting ( category, order ) {

        data.oeuvre_meds.sort(function (a, b) {

            if ( category === "titre" ) {

                return sortAlpha(a[category], b[category], order)
           
            } else if ( category === "archive") {

                return sortNumbers(a[category], b[category], order)
            }
            return -1
        });
    }

    return (
    <Paper elevation={3}>

        {addOeuvre &&
            <OeuvreModal
                actions={[
                   {
                        key: 'add', icon: <AddIcon />, title: "Add",
                        onClick: (id, oeuvre) => {

                            setAddOeuvre(false)
                            setEditOeuvreMed({"id_oeuvre" : oeuvre.objectid, "titre" : oeuvre.titre})

                        }
                    }
                ]}
                filterData={data.oeuvreMeds}
                mediaId={data.objectid}
                onClose={() => {
                    setAddOeuvre(false)
                }}
            />
        }

        {editOeuvreMed &&
            <OeuvreMedModal
                data={editOeuvreMed}
                mediaId={data.objectid}
                media={data}
                onClose={() => {
                    setEditOeuvreMed(false)
                }}
                onSave={reloadData}
            />
        }

        <DataTable
            data={data.oeuvre_meds}
                headerActions={[
                    <Button key="add" onClick={onClickAddOeuvre}  variant="contained">Ajouter une oeuvre</Button>
                ]}
            defaultOrderBy={"archive"}
            onRequestSort={sorting}
            config={{
                onClickRow: oeuvre_med => {
                        window.open("/artwork/" + oeuvre_med.id_oeuvre)
                    },
                cells: [
                    { title: t('archive'), key: 'archive' },
                    { title: t('photo'), key: 'filename', sort: false },
                    { title: t('title'), key: 'titre' },
                    { title: t('timecode'), key: 'timecode', sort: false },
                    { title: t('remarques'), key: 'remarques', sort: false }
                ],
                actions: actions || [
                    { icon: <EditIcon />, title: "Edit", onClick: (id, oeuvre_med) => setEditOeuvreMed(oeuvre_med) },
                    { icon: <DeleteIcon />, title: "Delete", onClick: (id, oeuvre_med) => {  
                        if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                            deleteOeuvreAsync(oeuvre_med)
                        }
                    }}
                ]
            }}
            getCell={(item, attr_name) => {

                if (attr_name === "filename") {
                    return (
                        <Image
                            style={{justifyContent: 'flex-start'}}
                            imageWidth={60}
                            alt="" filename={item.filename}
                        />
                    )
                } 
                return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
            }}
        />
    </Paper>
    )
}