import React from 'react';
import DataTable from 'components/DataTable'
import {
    Edit as EditIcon
} from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';

import Contacts from 'data/Contacts';

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Contacts, actions, onClickEdit }) => {

    const history = useHistory();

    return (
        <Paper elevation={3}>
            <DataTable
                data={data}
                config={{

                    cells: [
                        { title: 'Structure', key: 'structure' },
                        { title: 'Nom', key: 'nom' },
                        { title: 'Prénom', key: 'prenom' },
                        { title: 'Genre', key: 'genre' },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, contact) => {
                                history.push("/contact/" + contact.objectid);
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name) => {
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}