// @flow
import React from 'react';

import Form from 'components/Form';
import { useTranslation } from 'react-i18next'

type Props = {
    data: any,
    onChange: any => any
}
export default ({ data, onChange }: Props) => {
    
    const { t } = useTranslation();

    return (
        <React.Fragment>

            <Form
                form={[
                    { label: t("year"), attr: "annee", type: "text", size: { label: 150, field: 100 }},
                    { label: t("valeur"), attr: "valeur", type: "text", size: { label: 150, field: 100 }},
                    { label: t("devise"), attr: 'devise', type: "select", options : [
                        { label: "Euros", value: "Euros"},
                        { label: "Dollars", value: "Dollars"},], size: { label: 150, field: 150 }
                    },
                    { label: t("assureur"), attr: "assureur", type: "text", size: { label: 150, field: 400 }},
                    { label: t("remarques"), attr: "remarque", type: "text", size: { label: 150, field: 400 }},
                ]}
                data={data}
                onChange={onChange}
            />

        </React.Fragment>

    )
}
