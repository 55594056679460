

import { sortDate } from 'Util/Sorting'
import { calcMultipleDatas, historiquesWithNumero } from 'Util/OeuvreMultiple';


export const filtreOeuvresActuelles = (oeuvres, contactId) => {

     var found = []

     if ( oeuvres !== null ) {
   
        oeuvres.forEach(oeuvre => {

            if ( oeuvre.multiples && oeuvre.multiples.length>0 ) {
            } else {
            if ( oeuvre.historiques ) {
                oeuvre.historiques.sort(function (a, b) {
                    return sortDate(a["date_historique"], b["date_historique"], false)
                });
            }

            if ( oeuvre.historiques.length > 0 ) {
                const lastTransaction = oeuvre.historiques[0]

                if ( lastTransaction && lastTransaction.nouveau_proprio.length>0 ) {
                    if ( lastTransaction.nouveau_proprio[0].objectid === contactId ) {
                        var oeuvre_actuelle = []

                        oeuvre_actuelle.objectid = oeuvre.objectid
                        oeuvre_actuelle.titre = oeuvre.titre
                        oeuvre_actuelle.archive = oeuvre.archive
                        oeuvre_actuelle.annee_creation = oeuvre.annee_creation
                        oeuvre_actuelle.filename = oeuvre.filename
                        oeuvre_actuelle.photo_items = oeuvre.photo_items
                        oeuvre_actuelle.multiple = oeuvre.multiple
                        oeuvre_actuelle.type_oeuvre = oeuvre.type
                        oeuvre_actuelle.type = lastTransaction.type
                        oeuvre_actuelle.date_historique = lastTransaction.date_historique

                        found.push(oeuvre_actuelle)
                    }
                }
            }
        }
        })

    }
     return found
}

export const filtreOeuvresPassees = (oeuvres, contactId) => {

     var found = []
     
     if ( oeuvres !== null ) {

        oeuvres.forEach(oeuvre => {
            
            if ( oeuvre.multiples && oeuvre.multiples.length>0 ) {
            } else {
                if ( oeuvre.historiques ) {
                    oeuvre.historiques.sort(function (a, b) {
                        return sortDate(a["date_historique"], b["date_historique"], false)
                    });
                }

                if ( oeuvre.historiques.length > 0 ) {
                    const lastTransaction = oeuvre.historiques[0]

                    if ( lastTransaction && lastTransaction.nouveau_proprio.length>0 ) {

                        if ( lastTransaction.nouveau_proprio[0].objectid !== contactId ) {
                            var oeuvre_passee = []

                            oeuvre_passee.objectid = oeuvre.objectid
                            oeuvre_passee.titre = oeuvre.titre
                            oeuvre_passee.archive = oeuvre.archive
                            oeuvre_passee.annee_creation = oeuvre.annee_creation
                            oeuvre_passee.filename = oeuvre.filename
                            oeuvre_passee.photo_items = oeuvre.photo_items
                            oeuvre_passee.multiple = oeuvre.multiple
                            oeuvre_passee.type_oeuvre = oeuvre.type
                            oeuvre_passee.type = lastTransaction.type
                            oeuvre_passee.date_historique = lastTransaction.date_historique

                            found.push(oeuvre_passee)
                        }
                    }
                }
            }
        })
    }

     return found
}

export const filtreMultiplesActuels = (oeuvres, contactId) => {

     var found = []

     if ( oeuvres !== null ) {
   
        oeuvres.forEach(oeuvre => {
            
            const multiples = oeuvre.multiples

            if ( multiples && multiples.length>0 ) {

                calcMultipleDatas(oeuvre)

                multiples.forEach( multiple => {

                //    console.log("oeuvre : ", oeuvre)
                    console.log("multiple : ", multiple)

                    const historiques = historiquesWithNumero(oeuvre.historiques, multiple.numero)

                    const lastTransaction = historiques[0]

                    if ( lastTransaction && lastTransaction.nouveau_proprio.length>0) {

                        console.log("lastTransaction : ", lastTransaction)

                        if ( lastTransaction.nouveau_proprio[0].objectid === contactId ) {
                            const multiple_actuel = []

                            multiple_actuel.objectid = oeuvre.objectid
                            multiple_actuel.numero = multiple.numero
                            multiple_actuel.titre = oeuvre.titre
                            multiple_actuel.archive = oeuvre.archive
                            multiple_actuel.annee_creation = oeuvre.annee_creation
                            multiple_actuel.filename = oeuvre.filename
                            multiple_actuel.photo_items = oeuvre.photo_items
                            multiple_actuel.type_oeuvre = oeuvre.type
                            multiple_actuel.type = lastTransaction.type
                            multiple_actuel.date_historique = lastTransaction.date_historique

                            found.push(multiple_actuel)
                        }
                    } else {
                        // si pas de transaction et l'état est déterminé alors c'est un multiple qui appartient à sarl Avila
                        const etat = multiple.etat

                        if ( (etat === "averifier")
                            || (etat === "depotvente")
                            || (etat === "disponible")
                            || (etat === "pret") 
                            || (etat === "nonpresentable")
                            || (etat === "reserve") ) {
                            
                            if ( contactId === 80760 ) {   // si le contactId est celui de sarl Avila
                                var multiple_initial = []

                                multiple_initial.objectid = oeuvre.objectid
                                multiple_initial.numero = multiple.numero
                                multiple_initial.titre = oeuvre.titre
                                multiple_initial.archive = oeuvre.archive
                                multiple_initial.annee_creation = oeuvre.annee_creation
                                multiple_initial.filename = oeuvre.filename
                                multiple_initial.photo_items = oeuvre.photo_items
                                multiple_initial.type_oeuvre = oeuvre.type

                                //console.log("multiple_initial : ", oeuvre)
                                found.push(multiple_initial)
                            }
                        }
                    }
                })
            }
        })
    }
    return found
}

export const filtreMultiplesPasses = (oeuvres, contactId) => {

    var found = []

    if ( oeuvres !== null ) {

        oeuvres.forEach(oeuvre => {

            const multiples = oeuvre.multiples

            if ( multiples && multiples.length>0 ) {

                calcMultipleDatas(oeuvre)
               // console.log("oeuvre :", oeuvre)

                multiples.forEach( multiple => {
                    
                    const historiques = historiquesWithNumero(oeuvre.historiques, multiple.numero)
                    var compteurTransaction = 0
                    historiques.forEach( transaction => {

                        if ( compteurTransaction === 0 ) {

                            if ( transaction.ancien_proprio.length>0) {

                                if ( transaction.ancien_proprio[0].objectid === contactId ) {
                                    var multiple_passe = []

                                    multiple_passe.objectid = oeuvre.objectid
                                    multiple_passe.numero = multiple.numero
                                    multiple_passe.titre = oeuvre.titre
                                    multiple_passe.archive = oeuvre.archive
                                    multiple_passe.annee_creation = oeuvre.annee_creation
                                    multiple_passe.filename = oeuvre.filename
                                    multiple_passe.photo_items = oeuvre.photo_items
                                    multiple_passe.type_oeuvre = oeuvre.type
                                    multiple_passe.type = transaction.type
                                    multiple_passe.date_historique = transaction.date_historique

                                    found.push(multiple_passe)
                                }
                            }
                        } else {

                            if ( transaction.nouveau_proprio.length>0) {

                                if ( transaction.nouveau_proprio[0].objectid === contactId ) {

                                    multiple_passe.objectid = oeuvre.oeuvre_id
                                    multiple_passe.numero = multiple.numero
                                    multiple_passe.titre = oeuvre.titre
                                    multiple_passe.archive = oeuvre.archive
                                    multiple_passe.annee_creation = oeuvre.annee_creation
                                    multiple_passe.filename = oeuvre.filename
                                    multiple_passe.photo_items = oeuvre.photo_items
                                    multiple_passe.type_oeuvre = oeuvre.type
                                    multiple_passe.type = transaction.type
                                    multiple_passe.date_historique = transaction.date_historique

                                    found.push(multiple_passe)
                                }
                            }
                        }
                        compteurTransaction = compteurTransaction + 1
                    })
                })
            }
        })
    }
    return found

}
