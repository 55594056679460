// @flow
import React from 'react';

import { useTranslation } from 'react-i18next'
import { updateMultiple, createMultipleUpdateOeuvre } from 'Api';
//import { listeDesExemplaires }  from "Util/OeuvreMultiple"

import MultipleForm from 'pages/Avila/Multiple/Form'
import FormModal from './FormModal'


type Props = {
    oeuvreid: string,
    onClose: any => any,
    actions: Array<any>,
    data: any,
    onSave: any => any
}

export default ({
    onClose,
    actions = [],
    data,
    oeuvre,
    oeuvreid,
    isMultiple,
    onSave = () => {}
}: Props) => {

    const { t } = useTranslation();

    return (

        <FormModal
            title={t("exemplaire")}
            data={data}
            onClose={onClose}
            FormComponent={({ data, onChange }) => (
                <MultipleForm
                    isMultiple={isMultiple}
                    data={data}
                    onChange={onChange}
                />
            )}

            onSave={onSave}
            updateMethod={({ data }) => {
            /*    const listeExemplaires = listeDesExemplaires(oeuvre.multiples)
                const exemplaire = data.numero

                if (listeExemplaires.indexOf(exemplaire) > -1) {
                    alert("Blabla")
                    return false;
                } else {
                    //Not in the array
                }*/
                updateMultiple({ oeuvreid, data })}
            }
            createMethod={({ data }) => createMultipleUpdateOeuvre({oeuvre, data})}
        />
    )
}