// @flow
import React, { useState } from 'react';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Img from 'components/Img';
import { Divider, Grid } from '@material-ui/core';
import { FlexRowSpaceBetween } from 'layouts/Helpers/flexbox';

const DialogContent = styled(MuiDialogContent)`

`;

const MainContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
        margin-right: 20px;
    }
`
const MainPictureEditContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
        margin-bottom: 20px;
    }
`
const Title = styled.h2`

`

const OtherPicturesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`
type PhotoItem = {objectid?: string | number, type: string, filename: string};

type Props = {
    title: string,
    onClose: any => any,
    onConfirm: Array<PhotoItem> => any,
    photo_items: Array<PhotoItem>
}

export default ({
    onClose,
    title,
    photo_items,
    onConfirm = () => {}
}: Props) => {


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [ photoItems: Array<PhotoItem>, setPhotoItems ] = useState(photo_items);

    let face_photo: ?PhotoItem = null;
    let back_photo: ?PhotoItem = null;
    let other_photos: Array<PhotoItem> = [];

    photoItems.forEach(photo_item => {
        if (!photo_item) {
            return;
        }

      //  if ( photo_item ) {
            if (photo_item.type === "1") {
                face_photo = photo_item;
                return;
            }

            if (photo_item.type === "2") {
                back_photo = photo_item;
                return;
            }
            other_photos.push(photo_item);
      //  }
    })

    const addNewOtherPhoto = filename => {
        if ( back_photo === null ) {
            setPhotoItems([
                face_photo,
                ...other_photos,
                {
                    filename,
                    type: "99"
                }
            ])
        } else {
            setPhotoItems([
                face_photo, back_photo,
                ...other_photos,
                {
                    filename,
                    type: "99"
                }
            ])
        }
    }
    const deleteOtherPhotoItem = index => {
        const new_other_photos = [
            ...other_photos.slice(0, index),
            ...other_photos.slice(index + 1)
        ]
        console.log("back_photo : ", back_photo)
        console.log("new_other_photos : ", new_other_photos)

        if ( back_photo === null ) {
            setPhotoItems([ face_photo, ...new_other_photos ])
        } else {
            setPhotoItems([ face_photo, back_photo, ...new_other_photos ])

        }
    }

    return (
        <Dialog
            open
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"lg"}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>

                <MainContainer>
                    <MainPictureEditContainer>
                        <Title>Photo de face</Title>
                        <Divider style={{width: '100%'}}/>
                        <Img
                            filename={face_photo && face_photo.filename}
                            onChange={filename => {
                                setPhotoItems([
                                    {
                                        filename,
                                        private: 0,
                                        type: "1"
                                    },
                                    ...(back_photo ? [ back_photo ] : []),
                                    ...other_photos
                                ])
                            }}
                            onClickDelete={() => {
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                    setPhotoItems([ ...(back_photo ? [ back_photo ] : []), ...other_photos ])
                                }
                            }}
                            style={{width: '100%', height: '300px'}}
                            imageHeight={300}
                        />
                    </MainPictureEditContainer>
                    <MainPictureEditContainer>
                        <Title>Photo de dos</Title>
                        <Divider style={{width: '100%'}}/>
                        <Img
                            filename={back_photo && back_photo.filename}
                            onChange={filename => {
                                setPhotoItems([
                                    ...(face_photo ? [ face_photo ] : []),
                                    {
                                        filename,
                                        private: 0,
                                        type: "2"
                                    },
                                    ...other_photos
                                ])
                            }}
                            onClickDelete={() => {
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                    setPhotoItems([ ...(face_photo ? [ face_photo ] : []), ...other_photos ])
                                }
                            }}
                            style={{width: '100%', height: '300px'}}
                            imageHeight={300}
                        />
                    </MainPictureEditContainer>
                </MainContainer>

                <Divider style={{width: '100%'}}/>

                <OtherPicturesContainer>
                    <FlexRowSpaceBetween>
                        <Title>Images secondaires</Title>
                    </FlexRowSpaceBetween>

                    <Grid container spacing={2}>
                        {other_photos.map((other_photo, index) => (
                            <Grid key={(other_photo.objectid || other_photo.filename) + "_" + other_photos.length} item>
                                <Img
                                    filename={other_photo.filename}
                                    onClickDelete={() => {
                                        if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                            deleteOtherPhotoItem(index);
                                        }
                                    }}
                                    style={{width: '100%'}}
                                    imageWidth={300}
                                />
                            </Grid>
                        ))}

                        <Grid item>
                            <Img
                                key={photoItems.length}
                                onChangeLabel={"Ajouter une photo"}
                                onChange={addNewOtherPhoto}
                                style={{width: '100%', minWidth: '300px', minHeight: '300px'}}
                                imageHeight={300}
                            />
                        </Grid>
                    </Grid>

                </OtherPicturesContainer>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button onClick={() => {
                    onConfirm(photoItems);
                    onClose();
                }}>Valider</Button>
            </DialogActions>
        </Dialog>
    );
}