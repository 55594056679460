// @flow
import React from "react";

import Selector from '../index';
import type { Props } from '../index'
import { useColors } from 'redux/hooks'


export default ({ value, onChange, ...props }: Props) => {

    const data = useColors();

    return (
        <Selector
            {...props}
            label="Sélection des couleurs"
            value={value}
            onChange={onChange}
            data={data}
        />
    )
};