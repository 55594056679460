import React, { useState } from 'react';
import DataTable from 'components/DataTable'
import AuthentificationModal from 'pages/Avila/Modals/Authentification'
import { useTranslation } from 'react-i18next'
import Moment from 'moment';
import { sortDate} from 'Util/Sorting'

import {
    Edit as EditIcon,
    Delete as DeleteIcon 
} from '@material-ui/icons'

import {
    Grid, Button
} from '@material-ui/core';

import styled from 'styled-components';
import { updateOeuvre } from 'Api/artworks'

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)


export default ({ data, reloadData = () => {}, actions, onChange = () => {}, onClickEdit }) => {

    const [ editAuthentification, setEditAuthentification ] = useState(null);
    const { t } = useTranslation();
   
    async function updateOeuvreAsync(data) {
        await updateOeuvre({ id: data.objectid, data });
        onChange(data)
    }

    if ( data.authentifications ) {

        data.authentifications.sort(function (a, b) {
            return sortDate(a["date_authentification"], b["date_authentification"], false)
        });
    
    }

    return (

        <GridContainer>
            <Grid item xs={12}>
                {editAuthentification &&
                    <AuthentificationModal
                        data={editAuthentification}
                        oeuvre={data}
                        oeuvreid={data.objectid}
                        onClose={() => {
                            setEditAuthentification(false)
                        }}
                        onSave={() =>/* updateOeuvreAsync(data)*/ reloadData()}
                    />
                }

                <DataTable
                    data={data.authentifications}
                    headerActions={[
                        <Button key="add" onClick={() => setEditAuthentification({})} variant="contained">{t('ajouter_une_demande')}</Button>
                    ]}
                    config={{
                        cells: [
                            { title: 'Date', key: 'date_authentification' },
                            { title: 'Status', key: 'status' },
                            { title: 'Source', key: 'source' },
                            { title: 'Remarques', key: 'remarques' },
                        ],
                        actions: actions || [
                            { icon: <EditIcon />, title: "Edit", onClick: (id, authentification) =>  setEditAuthentification(authentification)},
                            { icon: <DeleteIcon />, title: "Delete", onClick: (id, authentification) => {  
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                    updateOeuvreAsync({
                                        ...data,
                                        authentifications: data.authentifications.filter(e => e.objectid !== authentification.objectid)
                                    })
                                }
                            }}
                        ]
                    }}
                    getCell={(item, attr_name) => {

                        if (attr_name === 'date_authentification') {
                            return Moment(item['date_authentification']).format('DD-MM-YYYY')
                        }
                        return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                    }}
                />
            </Grid>

        </GridContainer>
    )
}
