import React from "react";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
    Search
} from '@material-ui/icons'

export default ({id, onChange = () => {}, value = '', ...props}) => (
	<TextField
		id={id}
		label={false}
		placeholder="Rechercher"
		value={value}
		onChange={e => {
            onChange(e.target.value);
        }}

		InputProps={{
			startAdornment: <InputAdornment position="start"><Search /></InputAdornment>
		}}
	/>
)