
import { photoItemWithType } from 'Util/Oeuvre'
import { getImagePath, getFileExtension } from 'Util/Image'





export async function insertImages( wb, ws, data, col, row ) {
    
    const promises = data.map(async (d, i) => {

        const photo_item =  photoItemWithType(d["photo_items"], "1")
        if ( !photo_item ) {
            return 
        }
            
        const filename =  photo_item.filename
        if ( !filename ) {
            return 
        }

        var imagePath = getImagePath(filename)
        if ( !imagePath ) {
            return 
        }

        imagePath = imagePath + "?height=120"
        const extension = getFileExtension(filename)
        const ret = await fetch(imagePath)
        
        if ( ret ) {

            const bufferData = await ret.arrayBuffer()

            if ( bufferData ) {

                const imageId = wb.addImage({ buffer: Buffer.from(bufferData), extension: extension })
        
                if ( imageId ) {

                    const width = await addImageProcess(imagePath)

                    ws.addImage(imageId, {
                        tl: { col: col, row: i + 1 }, // top left
                        ext: {width:width, height:120}
                    })
                }
            }
        }
    })
    await Promise.all(promises)
}


    export async function insertImagesFilename( wb, ws, data, col, row ) {
    
        const promises = data.map(async (d, i) => {

            const filename =  d["filename"]

            if ( !filename ) {
                return 
            }

            var imagePath = getImagePath(filename)
            if ( !imagePath ) {
                return 
            }

            imagePath = imagePath + "?height=120"
            const extension = getFileExtension(filename)
            const image = await fetch(imagePath)
            const bufferData = await image.arrayBuffer()
            const imageId = wb.addImage({ buffer: Buffer.from(bufferData), extension: extension })

            const width = await addImageProcess(imagePath)

            ws.addImage(imageId, {
                tl: { col: col, row: i + 1 }, // top left
                ext: {width:width, height:120}
            })
        })
        await Promise.all(promises)
    }

    export const addImageProcess = (src) => {

        return new Promise((resolve, reject) => {

            let img = new Image()
            img.onload = () => resolve(img.width)
            img.onerror = () => { console.log("error : ", src); resolve(-1)}
            img.src = src
        })
    }

