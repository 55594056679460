import React, { useState } from 'react';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon } from '@material-ui/icons'

import DataTable from 'components/DataTable'
import OeuvreModal from 'pages/Avila/Modals/AddArtwork'
import OeuvreConsigneModal from 'pages/Avila/Modals/ConsigneOeuvre'

import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver'

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data, reloadData = () => {}, actions, onChange = () => {}, onClickEdit }) => {

    const [ addOeuvre, setAddOeuvre ] = useState(false);
    const [ editOeuvreConsigne, setEditOeuvreConsigne ] = useState(false);
    const { t } = useTranslation()


    const onClickAddOeuvre = () => {
        setAddOeuvre(true);
    }
    
    async function saveAsExcel() {
        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()
        
        ws.columns = [
            { header: 'Titre', key: 'Titre', width: 50 },
        ];

        const oeuvres = data.oeuvre_consignes

        oeuvres.forEach(oeuvre => {

            const row = ws.addRow([
                oeuvre.titre])
            row.font = { bold: false }

        })

        const buf = await wb.xlsx.writeBuffer()
        const filename = "Consignation Oeuvres.xlsx"

        saveAs(new Blob([buf]), filename)
    }

    
    if ( data.oeuvre_consignes ) {
        data.oeuvre_consignes.sort(function (a, b) {
            return a.titre.localeCompare(b.titre) === 1 ? 1 : -1 ;
        });
    }

 //   console.log("oeuvre_consignes : ", data.oeuvre_consignes)
    return (
        <Paper elevation={3}>

        {addOeuvre &&
            <OeuvreModal
                actions={[
                   {
                        key: 'add', icon: <AddIcon />, title: "Add",
                        onClick: (id, oeuvre) => {

                            setAddOeuvre(false)
                            setEditOeuvreConsigne({"id_oeuvre" : oeuvre.objectid, "titre" : oeuvre.titre})
                        }
                    }
                ]}
                filterData={data.oeuvreMeds}
                consignationId={data.objectid}
                onClose={() => {
                    setAddOeuvre(false)
                }}

            />
        }

        {editOeuvreConsigne &&
            <OeuvreConsigneModal
                data={editOeuvreConsigne}
                consignationId={data.objectid}
                onClose={() => {
                    setEditOeuvreConsigne(false)
                }}
                onSave={reloadData}
            />
        }

    <DataTable
        data={data.oeuvre_consignes}
            headerActions={[
                <div>
                <Button key="add" onClick={onClickAddOeuvre}  variant="contained">Ajouter une oeuvre</Button>
                <Button style={{marginLeft:'10px'}}  key="export" onClick={saveAsExcel}  variant="contained">Export des oeuvres</Button>
                </div>
            ]}
        config={{
            onClickRow: oeuvre => {
                window.open("/artwork/" + oeuvre.id_oeuvre)
            },
            cells: [
                { title: t('title'), key: 'titre' },
                { title: t('exemplaire'), key: 'multiple' },
                { title: t('consignation'), key: 'consignation' },
                { title: t('contrat'), key: 'contrat' },
                { title: t('depart_valide'), key: 'depart' },
                { title: t('retour_valide'), key: 'retour' },
                { title: t('vendu'), key: 'vendu' }
            ],

            actions: actions || [
                { icon: <EditIcon />, title: "Edit", onClick: (id, oeuvre_consigne) => setEditOeuvreConsigne(oeuvre_consigne) },
                { icon: <DeleteIcon />, title: "Delete", onClick: (id, oeuvre_consigne) => {  
                    if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                        onChange({
                            ...data,
                            oeuvre_consignes: data.oeuvre_consignes.filter(e => e.objectid !== oeuvre_consigne.objectid)
                        })           
                    }}
                }
            ]
        }}
        getCell={(item, attr_name) => {

            if ( attr_name === "consignation") {
                return item["consignation"] === 1 ? "Prêt" : "Vente"

            } else if ( attr_name === "contrat") {
                return item["contrat"] === 1 ? "Oui" : "Non"

            } else if ( attr_name === "depart") {
                return item["depart"] === 1 ? "Oui" : "Non"

            } else if ( attr_name === "retour") {
                return item["retour"] === 1 ? "Oui" : "Non"

            } else if ( attr_name === "vendu") {
                return item["vendu"] === 1 ? "Oui" : "Non"
            }
            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

        </Paper>
    )
}