import React from 'react';
import DataTable from 'components/DataTable'
import {
    Edit as EditIcon,
} from '@material-ui/icons'

import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import Expositions from 'data/Expos';

import { useTranslation } from 'react-i18next'
import { country } from 'Util/Listes'



const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Expositions, actions, onChange, onClickEdit, headerActions }) => {

    const { t } = useTranslation();


    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                config={{
                    cells: [
                        { title: t('archive'), key: 'archive', bold:true, width:"100px" },
                        { title: t('title'), key: 'titre' },
                        { title: t('lieu'), key: 'lieu', width:"200px" },
                        { title: t('ville'), key: 'ville', width:"100px" },
                        { title: t('pays'), key: 'pays', width:"100px" },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, expo) => {
                                window.open("/expo/" + expo.objectid)
                            }
                        }

                    ]
                }}
                getCell={(item, attr_name) => {

                    if ( attr_name === 'pays' ) {
                        return country(item[attr_name])
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}