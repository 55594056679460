import React from 'react';
import FileSaver from 'file-saver';

import Button from '@material-ui/core/Button'

export default ({ src, filename = "untitled", children, ...props}) => {

	return (
        <Button variant="contained" color="primary" {...props} onClick={() => FileSaver.saveAs(src, filename, {type: "image/jpg"})}>
            {children}
        </Button>
    )
}