import React, { useState } from 'react';
import DataTable from 'components/DataTable'
import AssuranceModal from 'pages/Avila/Modals/Assurance'

import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@material-ui/icons'

import {
    Grid, Button
} from '@material-ui/core';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next'
import { updateOeuvre } from 'Api/artworks'
import { sortNumbers } from 'Util/Sorting'


const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)


export default ({ data, reloadData = () => {}, actions, onChange = () => {}, onClickEdit }) => {

    const [ editAssurance, setEditAssurance ] = useState(null);
    const { t } = useTranslation();
    
    async function updateOeuvreAsync(data) {
        await updateOeuvre({ id: data.objectid, data });
        onChange(data)
    }

    if ( data.assurances ) {
        data.assurances.sort(function (a, b) {
            return sortNumbers(a["annee"], b["annee"], false)
        });
    }

    return (

        <GridContainer>
            <Grid item xs={12}>
                {editAssurance &&
                    <AssuranceModal
                        data={editAssurance}
                        oeuvre={data}
                        oeuvreid={data.objectid}
                        onClose={() => {
                            setEditAssurance(false)
                        }}
                        onSave={() => reloadData()}
                    />
                }

                <DataTable
                    data={data.assurances}
                    headerActions={[
                        <Button key="add" onClick={() => setEditAssurance({})} variant="contained">{t("add_assurance")}</Button>
                    ]}
                    config={{
                        cells: [
                            { title: t('annee'), key: 'annee' },
                            { title: t('devise'), key: 'devise' },
                            { title: t('valeur'), key: 'valeur' },
                            { title: t('assureur'), key: 'assureur' },
                            { title: t('remarques'), key: 'remarque' },
                        ],
                        actions: actions || [
                            { icon: <EditIcon />, title: "Edit", onClick: (id, assurance) =>  setEditAssurance(assurance)},
                            { icon: <DeleteIcon />, title: "Delete", onClick: (id, assurance) => {  
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                    updateOeuvreAsync({
                                        ...data,
                                        assurances: data.assurances.filter(e => e.objectid !== assurance.objectid)
                                    })
                                }
                            }}
                        ]
                    }}
                    getCell={(item, attr_name) => {

                        if ( attr_name === "valeur" ) {
                            return Intl.NumberFormat('fr-FR').format(item["valeur"])
                        }
                        return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                    }}
                />
            </Grid>

        </GridContainer>
    )
}
