import React, { useState } from 'react';
import {
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons'

import {
    Button
} from '@material-ui/core';

import DessinModal from 'pages/Avila/Modals/AddDessin'
import DessinListLayout from 'pages/Avila/Dessin/List/layout_oeuvre'
import { updateOeuvre } from 'Api';
import { useTranslation } from 'react-i18next';


export default ({ data, actions, reloadData = () => {}, onChange = () => {}, onClickEdit }) => {

    const [ addDessinModal, setAddDessinModal ] = useState(null);
    const { t } = useTranslation()

    const onClickAddDessin = () => {
        setAddDessinModal(true);
    }

    async function saveData(data) {
      
        await updateOeuvre({id:data.objectid, data})
        reloadData()
        setAddDessinModal(false);
    }

    return (

        <>
            {addDessinModal &&
                <DessinModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, dessin) => {

                                saveData({ ...data, dessins: [ ...data.dessins, dessin ] })

                               /* onChange({
                                    ...data,
                                    dessins: [
                                        ...data.dessins,
                                        dessin
                                    ]
                                })*/

                           //     setAddDessinModal(false);
                            }
                        }
                    ]}
                    filterData={data.dessins}
                    onClose={() => {
                        setAddDessinModal(false)
                    }}
                />
            }
            <DessinListLayout
                data={data.dessins}
                headerActions={[
                    <Button key="add" onClick={onClickAddDessin} variant="contained">{t('add_dessin')}</Button>
                ]}
                actions={[
                    {
                        key: 'delete', icon: <DeleteIcon />, title: "Delete",
                        onClick: (id, dessin) => {
                            if (window.confirm(`Supprimer ${dessin.titre} ?`)) {

                                saveData({
                                    ...data,
                                    dessins: data.dessins.filter(e => e.objectid !== dessin.objectid)
                                })
                            /*    onChange({
                                    ...data,
                                    dessins: data.dessins.filter(e => e.objectid !== dessin.objectid)
                                })*/
                            }
                        }
                    }
                ]}
            />
        </>
    )
}




