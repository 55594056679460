// @flow
import React from "react";
import { useTranslation } from 'react-i18next';

import {
    MenuItem,
    TextField,
} from '@material-ui/core';

import TYPES from './countries.json'


export type Props = {
    value?: number,
    onChange?: (value: number) => any
}
export default ({ value, onChange, ...props }: Props) => {

    const { i18n } = useTranslation();

    const valueIsValid = TYPES.find(el => el.value === value) !== undefined;

    return (
        <TextField
            {...props}
            size="small"
            select
            variant='outlined'
            defaultValue={""}
            value={valueIsValid ? value : " "}
            onChange={e => {
                onChange && onChange(e.target.value)
            }}
        >
            <MenuItem value={0}></MenuItem>
            {TYPES.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {(option.loc && option.loc[i18n.language]) || ""}
                </MenuItem>
            ))}
        </TextField>
	)
};