import React, { useState } from 'react';
import {
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next';

import {
    Button
} from '@material-ui/core';

import MediaModal from 'pages/Avila/Modals/AddMedia'
import MediaListLayout from 'pages/Avila/Media/List/layout_oeuvre'

import { deleteOeuvreMed, createOeuvreMed, updateOeuvre } from 'Api';

export default ({ data, actions, reloadData = () => {}, onChange = () => {}, onClickEdit }) => {

    const [ addMediaModal, setAddMediaModal ] = useState(null);
    const { t } = useTranslation()

    const onClickAddMedia = () => {
        setAddMediaModal(true);
    }


    async function saveData(mediaId, media) {
                
        const res = await createOeuvreMed({mediaId, data:media})
        media.objectid = res.objectId
        data = { ...data,
            oeuvre_meds: [
                ...data.oeuvre_meds,
                media
            ]
        }

        await updateOeuvre({id:data.objectid, data})
        reloadData()
        setAddMediaModal(false)
    }

    async function deleteOeuvreMedAsync(oeuvre_med) {
        await deleteOeuvreMed({ data: oeuvre_med });

        data = {
            ...data,
            oeuvre_meds: data.oeuvre_meds.filter(e => e.objectid !== oeuvre_med.objectid)
        }
        await updateOeuvre({id:data.objectid, data})
        reloadData()
    }

    return (

        <>
            {addMediaModal &&
                <MediaModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, media) => {
                                    saveData(media.objectid, {"id_oeuvre" : data.objectid, "archive" : data.archive, "titre" : data.titre})
                                }
                            }
                        ]}
                        filterData={data.medias}
                        onClose={() => {
                            setAddMediaModal(false)
                        }}
                    />
                }
                <MediaListLayout
                    data={data.oeuvre_meds}
                    headerActions={[
                        <Button key="add" onClick={onClickAddMedia} variant="contained">{t('add_media')}</Button>
                    ]}
                    actions={[
                        {
                            key: 'delete', icon: <DeleteIcon />, title: "Delete",
                            onClick: (id, oeuvre_med) => {
                                if (window.confirm(`Supprimer ${oeuvre_med.titre} ?`)) {
                                    deleteOeuvreMedAsync(oeuvre_med)
                                }
                            }
                        }
                    ]}
                />
            </>


    )
}




