import React, { useState } from 'react';
import {
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next';

import {
    Button
} from '@material-ui/core';

import MediaModal from 'pages/Avila/Modals/AddMedia'
import MediaListLayout from 'pages/Avila/Media/List/layout_subpanel'


export default ({ data, actions, onChange = () => {}, onClickEdit }) => {

    const [ addMediaModal, setAddMediaModal ] = useState(null);

    const onClickAddMedia = () => {
        setAddMediaModal(true);
    }
    const { t } = useTranslation()

    return (

        <>
            {addMediaModal &&
                <MediaModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, media) => {
                                onChange({
                                    ...data,
                                    medias: [
                                        ...data.medias,
                                        media
                                    ]
                                })

                                setAddMediaModal(false);
                                }
                            }
                        ]}
                        filterData={data.medias}
                        onClose={() => {
                            setAddMediaModal(false)
                        }}
                    />
                }
                <MediaListLayout
                    data={data.medias}
                    headerActions={[
                        <Button key="add" onClick={onClickAddMedia} variant="contained">{t('add_media')}</Button>
                    ]}
                    actions={[
                        {
                            key: 'delete', icon: <DeleteIcon />, title: "Delete",
                            onClick: (id, media) => {
                                if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {

                                    onChange({
                                        ...data,
                                        medias: data.medias.filter(e => e.objectid !== media.objectid)
                                    })
                                }
                            }
                        }
                    ]}
                />
            </>


    )
}




