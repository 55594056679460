// @flow
import React from "react";

import AutocompleteTags from 'components/AutocompleteTags'

export type Props = {
    label: string,
    data: any,
    value?: Array<string>,
    onChange?: (selectedIds: Array<string>) => any
}
export default ({ label, data, value, onChange = () => {}, ...props }: Props) => {

    const autocompleteValue = data.data.map(item => {
        return (value || []).indexOf(item.objectid) !== -1 ? ({
            label: item.name,
            value: item.objectid
        }) : null
    }).filter(item => item !== null);

	return (
        <AutocompleteTags
            {...props}
            label={label}
            onChange={values => {
                onChange(values.map(value => value.value));
            }}
            value={autocompleteValue}
            options={data.data.map(item => (
                { label: item.name, value: item.objectid }
            ))}
        />
	)

}