import React, { useState } from 'react';
import DataTableWithHeader from 'components/DataTableWithHeader'
import MutipleModal from 'pages/Avila/Modals/Multiple'
import NewMutipleModal from 'pages/Avila/Modals/NewMultiple'

import { useTranslation } from 'react-i18next'
import TYPES_ETAT_ADMINISTRATIF from 'components/Selectors/ArtWorkTypes/multiple_etat_administratif.json'
import TYPES_ETAT_CONSERVATION from 'components/Selectors/ArtWorkTypes/multiple_etat_conservation.json'

import { trierParExemplaire } from 'Util/OeuvreMultiple';
import { saveAs } from 'file-saver'
import { updateOeuvre } from 'Api/artworks'
import { sortAlphaNum, sortAlpha } from 'Util/Sorting'

import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@material-ui/icons'

import {
    Button, Checkbox
} from '@material-ui/core';

import { useCurrentPermissionProfile } from 'redux/hooks';

export default ({ data, reloadData = () => {}, actions, onChange = () => {}, onClickEdit, onSelect, selected = [] }) => {

    const [ editMutiple, setEditMultiple ] = useState(null);
    const [ newMultiple, setNewMultiple ] = useState(null);
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const permissionProfile = useCurrentPermissionProfile();
    const canDeleteExemplaire = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === null;


    const setSelectedMultiple = (id, value) => {
        onSelect({
            ...selected,
            [id]: value
        })
    }

    async function saveAsExcel() {
        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook()
        const ws = wb.addWorksheet()

        ws.columns = [
            { header: 'Numéro', key: 'Numero', width: 10 },
            { header: 'Etat administratif', key: 'Etat_administratif', width: 15 },
            { header: 'Etat de conservation', key: 'Etat_convervation', width: 15 },
            { header: 'Localisation', key: 'localisation', width: 40 },
            { header: 'Emplacement', key: 'emplacement', width: 40 },
            { header: 'Non signé', key: 'non_signe', width: 10 },
            { header: 'Non numéroté', key: 'non_numerote', width: 10 },
            { header: 'Propriétaire', key: 'proprietaire', width: 40 },
            { header: 'Conservation', key: 'conservation', width: 50 },
            { header: 'Remarques', key: 'remarques', width: 50 },
        ];
 
        const multiples = data.multiples

        multiples.forEach(multiple => {
            
            const etat_administratif = multiple.etat ? TYPES_ETAT_ADMINISTRATIF[multiple.etat].loc[i18n.language] : ""
            const etat_de_conservation = multiple.etat_de_conservation ? TYPES_ETAT_CONSERVATION[multiple.etat_de_conservation].loc[i18n.language] : ""
            const non_signe = multiple.nonsigne ? "X" : ""
            const non_numerote = multiple.nonnumerote ? "X" : ""

            const row = ws.addRow([multiple.numero,
                    etat_administratif,
                    etat_de_conservation,
                    multiple.localisation,
                    multiple.emplacement,
                    non_signe,
                    non_numerote,
                    multiple.proprietaire,
                    multiple.conservation,
                    multiple.remarques])
            row.font = { bold: false }

        })
        const buf = await wb.xlsx.writeBuffer()
        const filename = "Oeuvre #" + data.archive + " Multiples.xlsx"

        saveAs(new Blob([buf]), filename)
    }

    async function updateOeuvreAsync(data) {
        await updateOeuvre({ id: data.objectid, data });
        onChange(data)
    }
    
    function triage ( category, order ) {

        data.multiples.sort(function (a, b) {

            if ( category === "numero" ) {
                return sortAlphaNum(a[category], b[category], order)
            } else {
                return sortAlpha(a[category], b[category], order)

            }
        });
    }

  //  console.log("multiples dans la liste des multiples: ", data)
    return (

        <>
            {editMutiple &&
                <MutipleModal
                    data={editMutiple}
                    oeuvre={data}
                    oeuvreid={data.objectid}
                    onClose={() => {
                        setEditMultiple(false)
                    }}
                    onSave={ reloadData }
                />
            }

            {newMultiple &&
                <NewMutipleModal
                    data={newMultiple}
                    oeuvre={data}
                    oeuvreid={data.objectid}
                    onClose={() => {
                        setNewMultiple(false)
                    }}
                    onSave={ reloadData }
                />
            }
            <DataTableWithHeader
                data={trierParExemplaire(data)}
                onRequestSort={triage}
                headerActions={[
                    <Button key="add" onClick={setNewMultiple} variant="contained">{t("add_exemplaire")}</Button>,
                    <Button style={{marginLeft:'10px'}} key="export" onClick={saveAsExcel} variant="contained">Export</Button>
                ]}
                config={{
                    cells: [
                        { title: "filtrer", key: 'filter_checkbox' },
                        { title: t('numero'), key: 'numero' },
                        { title: t('etat_administratif'), key: 'etat' },
                        { title: t('etat_de_conservation'), key: 'etat_de_conservation' },
                        { title: t('localisation'), key: 'derniere_localisation' },
                        { title: t('proprietaire'), key: 'dernier_proprietaire' },
                    ],
                    actions: actions || [
                        { icon: <EditIcon />, title: "Edit", onClick: (id, multiple) => setEditMultiple(multiple)},
                        ...(canDeleteExemplaire ? [
                        { icon: <DeleteIcon />, title: "Delete", onClick: (id, multiple) => {
                            if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                updateOeuvreAsync({
                                    ...data,
                                    multiples: data.multiples.filter(e => e.objectid !== multiple.objectid)
                                })
                                setSelectedMultiple(multiple.numero, false);
                            }
                        }}]: [])
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === 'filter_checkbox') {
                        return (
                            <Checkbox
                                checked={!!selected[item.numero]}
                                onChange={({ target: { checked }}) => {
                                    setSelectedMultiple(item.numero, checked);
                                }}
                            />
                        )
                    }
                    if ( attr_name === "etat" ) {

                        const type = item["etat"]

                        if ( TYPES_ETAT_ADMINISTRATIF[type] ) {
                            return TYPES_ETAT_ADMINISTRATIF[type].loc[i18n.language]
                        }
                    }
                    if ( attr_name === "etat_de_conservation" ) {

                        const type = item["etat_de_conservation"]

                        if ( TYPES_ETAT_CONSERVATION[type] ) {
                            return TYPES_ETAT_CONSERVATION[type].loc[i18n.language]
                        }
                    }

                    if ( attr_name === "localisation" ) {
                        return item['localisation']
                    }
                    if ( attr_name === "proprietaire" ) {
                        return item['proprietaire']
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </>
    )
}