import { constructUrl, getResult, fetchApi as fetch } from './ApiCall';


export const search = async ({ type = 'oeuvre', field = 'archive', query = "3181" } = {}) => {

    const url = constructUrl("search");

    var formdata = new FormData();
    formdata.append("table", type);
    formdata.append("field", field);
    formdata.append("search", query);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();
    const result = getResult(responseJson, 'search');



    if (!result[type]) {
        return [];
    }

    return result[type];
}

export const searchExact = async ({ type = 'oeuvre', field = 'archive', query = "3181" } = {}) => {

    const url = constructUrl("searchexact");

    var formdata = new FormData();
    formdata.append("table", type);
    formdata.append("field", field);
    formdata.append("search", query);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();
    const result = getResult(responseJson, 'searchexact');



    if (!result[type]) {
        return [];
    }

    return result[type];
}