import React from 'react';

import AutocompleteTags from 'components/AutocompleteTags'

import ColorSelector from 'components/Selectors/Colors'
import MateriauxSelector from 'components/Selectors/Materiaux'
import ElementsSelector from 'components/Selectors/Elements'
import FamillesSelector from 'components/Selectors/Familles'
import TypeExposSelector from 'components/Selectors/Type_Expos'
import CriteresSelector from 'components/Selectors/Criteres'
import GenreContactsSelector from 'components/Selectors/Genre_Contacts'

import AutocompleteFree from 'components/AutocompleteFree';
import AutocompleteLocalTags from 'components/AutocompleteLocalTags';

export default ({ config, ...props }) => {

    if (config.type !== 'autocomplete') {
        return null;
    }

    if (!config.autocomplete) {
        return (
            <AutocompleteTags
                {...props}
            />
        )
    }

    const autocompleteConfig = config.autocomplete;

    switch(autocompleteConfig.type) {
        case 'free':
            return (
                <AutocompleteFree options={autocompleteConfig.options} {...props} />
            );
        case 'colors':
            return (
                <ColorSelector {...props} />
            )
        case 'elements':
            return (
                <ElementsSelector {...props} />
            )
        case 'materiaux':
            return (
                <MateriauxSelector {...props} />
            )
        case 'familles':
            return (
                <FamillesSelector {...props} />
            )
        case 'type_expos':
            return (
                <TypeExposSelector {...props} />
            )
        case 'criteres':
            return (
                <CriteresSelector {...props} />
            )
        case 'genre_contacts':
            return (
                <GenreContactsSelector {...props} />
            )
        case 'local_tags':
            return (
                <AutocompleteLocalTags options={autocompleteConfig.options} {...props} />
            )
        default:
            return (
                <AutocompleteTags
                    {...props}
                />
            )
    }
}