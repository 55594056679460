import React, {  } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import {
    Grid,
    Paper
} from '@material-ui/core';

import Image from 'components/Img'

import Tabs, {
    TabsContainer, TabsPanels as OriginalTabsPanels
} from 'components/Tabs'

import Form from 'components/Form';


import ArtworksPanel from './Artworks';
import BibliosPanel from '../../SubPanel';
import ExposPanel from '../../../Expo/SubPanel';
import MediasPanel from '../../../Media/SubPanel';
import PhotosPanel from '../../../Photo/SubPanel';

import { getEmplacements, getRangements } from "Util/BiblioRangement"
import { updateBiblio } from 'Api/biblios'


const TabsPanels = styled(OriginalTabsPanels)`
    padding:20px;
    padding-top: 0px;
`

const FormContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const Box = styled(Paper)`
    padding:20px;
`

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)

export default ({ data, reloadData = () => {}, onChange = () => {}, layout = 'editcreate' }) => {

    const { t } = useTranslation();

    const layoutIsSearch = layout === 'search';
    const layoutIsEdit = layout === 'edit';

    const isDateInvalid = !data["date_annee_invalid"]?0:1
    
    if ( data.bibliographie ) {

        if ( data.bibliographie.startsWith("<p>") ) {
        } else {
            var newString = data.bibliographie
            data.bibliographie = "<p>" + newString.replaceAll('\r', '') +  "</p>" 
        }
        data.bibliographie_old = data.bibliographie
    }

  //  console.log("biblio : ", data)
    
    const formLeft = [

        { label: t("nb_archive"), attr: "archive", type: "text", size: { label: 150, field:100 }},

        { label: t("type_de_document"), attr: "type_document", type: "autocomplete",
                autocomplete: {
                    type: 'free',
                    options: ["Affiche", "Carton d'invitation", "Catalogue d'exposition individuelle", "Catalogue d'exposition collective", "Catalogue vente publique", "Correspondance", "Dossier de presse", "Foire", "Livre", "Livre d'artiste", "Matériel promotionnel", "Mémoire/Thèse", "Pochette de disque", "Presse", "Presse Museo Soto", "Revue", "Textes originaux"]
                }, size: { label: 150, field:400 }
            },


        ...(!layoutIsSearch ? [
            { label: t("bibliographie"), attr: "bibliographie", type: "richtext_biblio", multi:'4', size: { label: 150, field:400 } },
        ] : [
            { label: t("bibliographie"), attr: "bibliographie_search", type: "text", multi:'4', size: { label: 150, field:400 } },
        ]),

        { label: t("auteurs"), attr: "auteurs", type: "text", multi:'3', size: { label: 150, field:400 } },
        { label: t("title"), attr: "titre", type: "text", size: { label: 150, field:400 }},
        
        ...(!layoutIsSearch ? [
            [
                { label: t("annee"), attr: "annee", type: "text", size: { label: 150, field:50 }},
                { label: "Circa", attr: "circa", type: "checkbox", size: { label: 10, left:30 } },
            ],
            ...(isDateInvalid ? [ { label: "année invalide:", attr: "date_annee_invalid", type: "display", size: { label: 150, field:300 }},]:[])
        ] : [
            [{ label: t("annee"), attr: "annee", type: "text", size: { label: 150, field:50 }},
            { label: " - " , attr: "annee_max", type: "text", size: { label: 10, field:50 }},
            { label: t("circa"), attr: "circa", type: "checkbox", size: { label:20, field:100 } },]

        ]),
        { label: t("editeur"), attr: "editeur", type: "text", size: { label: 150, field:400 }},
    
        
        { label: t("ville"), attr: "ville", type: "text", size: { label: 150, field:400 }},
        {
            label: t("pays"), attr: 'pays', type: "select",
            selectType: 'countries', size: { label: 150, field: 400 }
        },
        { label: t("source_internet"), attr: "isbn", type: "text", size: { label: 150, field:400 }},
        { label: t("emplacement"), attr: "emplacement", type: "autocomplete", autocomplete: {
                    type: 'free', options: getEmplacements()}
                , size: { label: 150, field:400 }
        },
        { label: t("rangement"), attr: "rangement", type: "autocomplete", autocomplete: {
                    type: 'free', options: getRangements()}
                , size: { label: 150, field:400 }
        },

         { label: t("exemplaires"), attr: "nb_exemplaires", type: "text", size: { label: 150, field:400 }},

     ]


    const formRight = [
    
        [{ label: t("texte_sur_soto"), attr: "texte_sur_soto", type: "checkbox", size: { label: 100, left:150, field:100 } },
        { label: t("texte_de_soto"), attr: "texte_de_soto", type: "checkbox", size: { label: 0, left:150, field:100 } },],
        { label: t("entretien_avec_soto"), attr: "entretien_avec_soto", type: "checkbox", size: { label: 100, left:150, field:100 } },
        
        { label: t("mots_clefs"), attr: "mots_clefs", type: "text", multi:'4', size: { label: 100, field:300 } },
        { label: t("remarques"), attr: "remarques", type: "text", multi:'4', size: { label: 100, field:300 } },
        { label: t("oeuvre_non_identifiee"), attr: "oeuvre_non_identifiee", type: "checkbox", size: { label: 100, left:100, field:150 } },
        { label: t("nouvelle_info"), attr: "nouvelle_info", type: "checkbox", size: { label: 100, field:100 } },
    ]

    var photo_items = data.photo_items
    var photo_main = null
    let photo_filename = null;
    let exportFilename = null;

    if (data.photo_items && photo_items.length > 0) {
        photo_main = photo_items[0]
        photo_filename = photo_main.filename
        exportFilename = "Biblio " + data.archive + ".jpg"
    }

    async function updateBiblioAsync(data) {
        await updateBiblio({ id: data.objectid, data });
        reloadData(data)
    }

    return (
        
        <GridContainer>


            <Grid item xs={7}>
                <Box elevation={1} style={{position: 'relative', height: '100%'}}>
                    <FormContainer>
                        <Form form={formLeft} data={data}
                            onChange={ retour => {
                                console.log("retour : ", retour)
                                if ( retour.bibliographie !== data.bibliographie_old ) {
                                }
                                onChange(retour)
                                }
                            }                           
                        />
                    </FormContainer>
                </Box>
            </Grid>


            <Grid item xs={5}>
                <Box elevation={1} style={{position: 'relative', height: '100%'}}>
                    {!layoutIsSearch && <Image
                        onClickDelete={() => {
                            if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                const [ main_item, ...photo_items ] = data.photo_items
                                if (!main_item) {
                                    return;
                                }
                                onChange({
                                    ...data,
                                    photo_items
                                })
                            }
                        }}
                        onChange={filename => {
                            const photo_items = data.photo_items.slice(1);
                            onChange({
                                ...data,
                                photo_items: [
                                    {
                                        filename,
                                        private: 0,
                                        type: "20"
                                    },
                                    ...photo_items
                                ]
                            })
                        }}
                        alt="test"
                        subdir="photos"
                        filename={photo_filename}
                        downloadFile={exportFilename}
                        style={{width: '100%'}}
                        imgStyle={{maxWidth: '100%', maxHeight: '330px'}}
                        actionPosition='outside'
                    />}

                    <FormContainer>
                        <Form form={formRight} data={data} onChange={onChange}/>
                    </FormContainer>
                </Box>
            </Grid>


            <Grid item xs={12}>

                <TabsContainer orientation={"horizontal"}>

                    <Tabs
                        orientation={"horizontal"}
                        variant="scrollable"
                        tabs={[
                            {
                                label: t("transcription"),
                                panel: (
                                    <TabsPanels>
                                        <Paper elevation={3}>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Form form={[
                                                    { label: t(""), attr: "transcription", type: "text", multi: 16, size:{label :0, field:500} }
                                                ]} data={data} onChange={onChange}/>
                                            </Grid>
                                        </Grid>
                                         </Paper>
                                    </TabsPanels>
                                )
                            },
                            
                        ...(layoutIsEdit ? [

                            {
                                label: t("expos"),
                                panel: (
                                    <TabsPanels>
                                        <ExposPanel layout={layout} reloadData={reloadData} data={data} onChange={updateBiblioAsync} />
                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("oeuvres"),
                                panel: (
                                    <TabsPanels>
                                        <ArtworksPanel data={data} layout={layout} reloadData={reloadData}  onChange={updateBiblioAsync} />
                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("photos"),
                                panel: (
                                    <TabsPanels>
                                        <PhotosPanel layout={layout} reloadData={reloadData} data={data} onChange={updateBiblioAsync} />
                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("medias"),
                                panel: (
                                    <TabsPanels>
                                        <MediasPanel layout={layout} reloadData={reloadData} data={data} onChange={updateBiblioAsync} />
                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("biblios"),
                                panel: (
                                    <TabsPanels>
                                        <BibliosPanel layout={layout} reloadData={reloadData} data={data} onChange={updateBiblioAsync} />
                                    </TabsPanels>
                                )
                            },
                        ]: []),

                        ]}
                    />

                </TabsContainer>

            </Grid>


        </GridContainer>

    )
}