import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom'

import {
    CircularProgress,
} from '@material-ui/core'

import ArtWorkLayout from './index';
import SearchNav from 'components/SearchNav'
import { getOeuvreById, updateOeuvre } from 'Api'
import { useHistory } from 'react-router-dom'

export default () => {

    const params = useParams();
    const location = useLocation();
    const id = params.id;
    const [ artwork, setArtwork ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const history = useHistory();

    const context = (location.state && location.state.context) || null;

    const loadData = async (id) => {
        const artwork = await getOeuvreById({ id })

        setArtwork(artwork.oeuvre);
        setLoading(false);
    }

    useEffect(() => {
        loadData(id);
    }, [ id ])


    let content = null;
    
    if (loading) {
        content = <CircularProgress />
    } else if (!id || !artwork) {
        content = "NOT FOUND"
    } else {
        content = (

            <ArtWorkLayout
                key={artwork.objectid}
                initialData={artwork}
                reloadData={() => loadData(id)}
                saveData={async data => {
                    await updateOeuvre({ id: data.objectid, data });
                    return true;
                }}
                onSaved={() => {
                    loadData(id);
                }}
                onDelete={() => {
                    history.push("/artwork");
                }}
            />
        )
    }

    return (
        <>

            {context === 'search_result' &&
                <SearchNav
                    search_type={"artwork"}
                    constructPath={artwork => ("/artwork/" + artwork.objectid)}
                    searchPath={"/artwork/search"}
                />
            }

            {content}

        </>
    )
}