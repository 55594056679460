
import Moment from 'moment';

export const consignationsTerminentDansXJours = (consignations, nbJours) => {
   const matchConsignations = []

   if ( consignations ) {
        consignations.forEach( consignation => {

            let date_fin = Moment(consignation.date_fin, "YYYY-MM-DD")
            let today = Moment(Date())
            let days = Moment.duration(date_fin.diff(today)).asDays();

            if ( (days>0) && (days < nbJours) ) {
                matchConsignations.push( consignation)
            }
        })
    }

    return matchConsignations
}

export const consignationsEnCours = data => {

    const encours = []

    data.forEach( consignation => {

        if ( consignationTerminee(consignation) ) {
        } else {
            encours.push( consignation)
        }
     /*   let oeuvres = consignation.oeuvre_consignes
        console.log("consignation: ", consignation.motif)

        if ( oeuvres != null ) {
             var fini = false
        
         //   if ( consignation.date_fin < today )
         //   {
                fini = true

                oeuvres.forEach( oeuvre => {
                 //   console.log("oeuvre: ", oeuvre)

                    if ( (oeuvre.retour === 1) || (oeuvre.vendu === 1) || (oeuvre.consignation_terminee === 1) ) {
                    //    console.log("oeuvre terminee")
                    } else {
                    //   console.log("oeuvre en cours")
                        fini = false
                    }
                })
        //    }
            if ( fini === false ) {
                console.log("consignation en cours")
                encours.push( consignation)
            } else {
                console.log("consignation terminee")
            }
        }*/
        
    })

    return encours

}

export const consignationsTerminees = data => {

    const terminees = []

    data.forEach( consignation => {

        if ( consignationTerminee(consignation) ) {
            terminees.push( consignation)

        } else {

        }
       /* let oeuvres = consignation.oeuvre_consignes

        if ( oeuvres != null ) {

            var fini = false ;
            let date_fin = Moment(consignation.date_fin, "YYYY-MM-DD").format('YYYY-MM-DD')
            let today = Moment(Date()).format('YYYY-MM-DD') 

            if ( date_fin > today ) {

            } else {
                fini = true

                oeuvres.forEach( oeuvre => {
                //    console.log("oeuvre: ", oeuvre)

                    if ( (oeuvre.retour === 1) || (oeuvre.vendu === 1) || (oeuvre.consignation_terminee === 1) ) {
                        //console.log("oeuvre Terminee")
                    } else {
                        //console.log("oeuvre pas Terminee")
                        fini = false
                    }
                })
            }

            if ( fini === true ) {
                terminees.push( consignation)
                //console.log("consignation Terminee")
           } else {
                //console.log("consignation pas Terminee")
           }
        }*/
        
    })
    return terminees
}



export const consignationTerminee = consignation => {


    let oeuvres = consignation.oeuvre_consignes

    if ( oeuvres != null ) {

        var fini = false ;
        let date_fin = Moment(consignation.date_fin, "YYYY-MM-DD").format('YYYY-MM-DD')
        let today = Moment(Date()).format('YYYY-MM-DD') 

          /*  console.log("consignation : ", consignation.motif)
            console.log("consignation dat_fin: ", consignation.date_fin)
            console.log("date de fin : ", date_fin)
            console.log("today : ", today)*/


        if ( date_fin > today ) {
        } else {
            fini = true
            oeuvres.forEach( oeuvre => {
            //    console.log("oeuvre: ", oeuvre)

                if ( (oeuvre.retour === 1) || (oeuvre.vendu === 1) || (oeuvre.consignation_terminee === 1) ) {
                        //console.log("oeuvre Terminee")
                } else {
                    //console.log("oeuvre pas Terminee")
                    fini = false
                }
            })
        }

        if ( fini === true ) {
            return true
       } else {
            return false
        }
    }
        
    return false
}



