import React from 'react';

import ConsignationListLayout from 'pages/Avila/Consignation/List/layout_oeuvre'


export default ({ data, actions, onChange = () => {}, onClickEdit }) => {
    
  //  console.log("oeuvre : ", data.consignations)

    return (
        <>
            <ConsignationListLayout
                data={data.consignations}
                headerActions={[
                ]}
                actions={[
                ]}
            />
        </>
    )
}











