// @flow
import React from 'react';
import Form from 'components/Form';
import { useTranslation } from 'react-i18next'

type Props = {
    data: any,
    onChange: any => any
}
export default ({ data, onChange }: Props) => {
    
    const { t } = useTranslation();

    console.log("Authentification :", data)

    return (
        <React.Fragment>

            <Form
                form={[
                    { label: t("date"), attr: "date_authentification", type: "dateselector", size: { label: 150, field: 150 } },
                    { label: t("source"), attr: "source", type: "text", size: { label: 150, field: 500 } },
                    { label: t("remarques"), attr: "remarques", type: "text", multi:3 , size: { label: 150, field: 500 } },
                    { label: t("caisse"), attr: "caisse", type: "text", multi:2 , size: { label: 150, field: 500 } },


                    { label: t("status"), attr: 'status', type: "select", options : [
                        { label: t("en_cours"), value: "en_cours"},
                        { label: t("sans_suite"), value: "sans_suite"},
                        { label: t("authentifie"), value: "authentifie"},
                        { label: t("refuse"), value: "refuse"},
                        ], size: { label: 150, field: 300 } },

                    [
                        { label: t("contrat_envoye"), attr: "contrat_envoye", type: "dateselector", size: { label: 150, field: 150 } },
                        { label: t("contrat_recu"), attr: "contrat_recu", type: "dateselector", size: { label: 150, field: 150 } },
                    ],
                    [
                        { label: t("paiement"), attr: "paiement", type: "dateselector", size: { label: 150, field: 150 } },
                        { label: t("document_didentite"), attr: "document_didentite", type: "dateselector", size: { label: 150, field: 150 } },
                        { label: t("date_photo_hd"), attr: "date_photo_hd", type: "dateselector", size: { label: 150, field: 150 } },
                    ],
                    [
                        { label: t("arrivee_oeuvre"), attr: "arrivee_oeuvre", type: "dateselector", size: { label: 150, field: 150 } },
                        { label: t("retour_oeuvre"), attr: "retour_oeuvre", type: "dateselector", size: { label: 150, field: 150 } },
                    ],
                    [
                        { label: t("certificat"), attr: "certificat", type: "dateselector", size: { label: 150, field: 150 } },
                        { label: t("certificat_signe"), attr: "signature", type: "dateselector", size: { label: 150, field: 150 } },
                    ],
                    [
                        { label: t("envoi_certificat"), attr: "envoi_certificat", type: "dateselector", size: { label: 150, field: 150 } },
                        { label: t("reception_certificat"), attr: "reception_certificat", type: "dateselector", size: { label: 150, field: 150 } },
                    ],

                ]}
                data={data}
                onChange={onChange}
            />

        </React.Fragment>

    )
}
