import React, { useEffect, useState } from 'react';

import {
    CircularProgress,
} from '@material-ui/core'

import { getAllOeuvres, getAllConsignations } from 'Api';

import PageLayout from 'layouts/Helpers/PageLayout'

import Layout from './layout'
import { Alert, AlertTitle} from "@material-ui/lab";
import { consignationsTerminentDansXJours } from 'Util/Consignations'
import Moment from 'moment';

export default ({ actions }) => {

    const [ artworks, setArtworks ] = useState([]);
    const [ consignations, setConsignations ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [show, setShow] = useState(true);

    useEffect(() => {
        getAllOeuvres().then(artworks => {
    
            var oeuvres = artworks.oeuvres

            oeuvres.sort(function (a, b) {
                return a.archive - b.archive;
            });
            setArtworks(oeuvres || []);

            getAllConsignations().then(consignations => {
                setConsignations(consignations.consignations || []);
                setLoading(false);
            });

        })
    }, [])


    if (loading) {
        return <CircularProgress />
    }

    let foundConsignations = consignationsTerminentDansXJours(consignations, 30)
    var stringConsignation = ""

    if ( foundConsignations.length > 0 ) {
        let consignation = foundConsignations[0]
        let date_fin = Moment(consignation.date_fin, "YYYY-MM-DD").format("DD-MM-YYYY")

        stringConsignation = "La consignation " + consignation.emprunteur + " se termine le " + date_fin + "."
    }

    return (
        <PageLayout title={"Oeuvres"} actions={(
            <div>
                {show && foundConsignations.length>0 &&
                    <Alert severity="info" color="error" onClose={() => setShow(false)}>
                        <AlertTitle>Alerte consignation</AlertTitle>
                        <p>{stringConsignation}</p>
                    </Alert>
                }

            </div>
        )}>
            <Layout data={artworks} onChange={setArtworks} actions={actions} />
        </PageLayout>
    )
}