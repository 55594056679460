// @flow
import React from 'react';
import Helmet from 'react-helmet';

import {
    Grid
} from '@material-ui/core'


import {
    Title, Divider,
    Container
} from 'layouts/Helpers/page'

export default ({ title, actions, children }: { title?: string, actions?: any, children: any }) => (
    <React.Fragment>
        <Helmet title={title} />

        <div>
            {actions}
            <Title style={{marginTop:'50px', marginBottom:'50px'}}>
                {title}
            </Title>

        </div>

        <Divider />

        <Grid container spacing={2} style={{ width: '100%' }}>
            <Grid item xs={12}>
                <Container>{children}</Container>
            </Grid>
        </Grid>

    </React.Fragment>
)