import React, { useState } from 'react';
import DataTable from 'components/DataTable'
import LocalisationModal from 'pages/Avila/Modals/Localisation'
import { useTranslation } from 'react-i18next'
import Moment from 'moment';
import { isSelectedMultiples  } from 'Util/OeuvreMultiple';
import { filterLocalisations } from 'Util/OeuvreLocalisation';
import { updateOeuvre } from 'Api/artworks'
import { country } from "Util/Listes"
import { sortDate } from "Util/Sorting"

import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@material-ui/icons'

import {
    Button
} from '@material-ui/core';


export default ({data, isMultiple, selectedMultiples, reloadData = () => {}, actions, onChange = () => {}, onClickEdit }) => {

    const [ editLocalisation, setEditLocalisation ] = useState(null);
    const { t } = useTranslation();
    
    const isFiltered = isSelectedMultiples(selectedMultiples)
    
    var filteredLocs = []

    if ( isFiltered === true ) {
        filteredLocs = filterLocalisations(data.localisations, selectedMultiples)
    } else {
        filteredLocs = data.localisations
    }
    
    
    async function updateOeuvreAsync(data) {
        await updateOeuvre({ id: data.objectid, data });
        onChange(data)
    }

    filteredLocs.sort(function (a, b) {
        return sortDate(a["date_localisation"], b["date_localisation"], false)
    });
    

    return (

        <>
            {editLocalisation &&
                <LocalisationModal
                    data={editLocalisation}
                    oeuvre={data}
                    oeuvreid={data.objectid}
                    isMultiple={isMultiple}
                    onClose={() => {
                        setEditLocalisation(false)
                    }}
                    onSave={() => reloadData()}
                />
            }

            <DataTable
                data={filteredLocs}
                 headerActions={[
                    <Button key="add" onClick={() => setEditLocalisation({})} variant="contained">{t("add_localisation")}</Button>
                ]}
                config={{
                    cells: [
                        { title: t('date'), key: 'date_localisation', width:'100px' },
                        ...(isMultiple ? [
                            { title: t('exemplaire'), key: 'multiple' },
                        ] : [] ),
                        { title: t('lieu'), key: 'localisation' },
                        { title: t('emplacement'), key: 'emplacement' },
                        { title: t('ville'), key: 'ville', width:'100px' },
                        { title: t('pays'), key: 'pays', width:'100px' },
                        { title: t('remarques'), key: 'remarque' },
                    ],
                    actions: actions || [
                        { icon: <EditIcon />, title: "Edit", onClick: (id, localisation) =>  setEditLocalisation(localisation)},
                        { icon: <DeleteIcon />, title: "Delete", onClick: (id, localisation) => {  
                            if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                updateOeuvreAsync({
                                    ...data,
                                    localisations: data.localisations.filter(e => e.objectid !== localisation.objectid)
                                })
                            }
                        }}
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === 'date_localisation') {
                        return Moment(item['date_localisation']).format('DD-MM-YYYY')

                    } else if ( attr_name === 'pays' ) {
                        return country(item['pays'])
                    }

                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </>
    )

}