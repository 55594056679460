import React from 'react';
import DataTable from 'components/DataTable'
import {
    Edit as EditIcon,
} from '@material-ui/icons'

import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import Image from 'components/Img'
import Dessins from 'data/Dessins';

import { sortAlphaNum} from 'Util/Sorting'

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Dessins, actions, onChange, onClickEdit, headerActions }) => {

    const { t } = useTranslation();


    data.sort(function (a, b) {

        if ( a.archive && b.archive ) {
            return sortAlphaNum(a.archive.toLowerCase(), b.archive.toLowerCase())
        }
        return -1
    });
    

    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                config={{
                    cells: [
                        { title: t('nb_archive'), key: 'archive', bold:true },
                        { title: t('photo'), key: 'filename' },
                        { title: t('annee'), key: 'annee' },
                        { title: t('oeuvres'), key: 'oeuvres' },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, dessin) => {
                                window.open("/dessin/" + dessin.objectid)
                            }
                        }
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === "filename") {
                        return (
                            <Image
                                imageWidth={60}
                            style={{justifyContent: 'flex-start'}}
                                alt="" filename={item.filename}
                            />
                        )
                    }
                    if (attr_name === "oeuvres") {
                        var stringArchives = ""
                        const oeuvre_archives = item["oeuvre_archive"]

                        if ( oeuvre_archives ) {

                            oeuvre_archives.forEach(archive => {
                                stringArchives = "#" + stringArchives + archive +", "
                            })
                        }
                        return stringArchives
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}