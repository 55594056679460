import { apiCall, constructUrl, getResult, fetchApi as fetch } from './ApiCall';
import { getOeuvreElements, getOeuvreMateriaux, getOeuvreCouleurs, getOeuvreFamilles, critereArrayFromString, critereStringFromArray } from 'Util/OeuvreCriteres';

const constructDessin = dessin => ({
    ...dessin,
    couleurs: (dessin.couleurs || []).map(item => item.objectid),
    materiaux: (dessin.materiaux || []).map(item => item.objectid),
    elements: (dessin.elements || []).map(item => item.objectid),
    familles: (dessin.familles || []).map(item => item.objectid),

    elements_array: critereArrayFromString(dessin.elements_string, getOeuvreElements()),
    materiaux_array: critereArrayFromString(dessin.materiaux_string, getOeuvreMateriaux()),
    familles_array: critereArrayFromString(dessin.familles_string, getOeuvreFamilles()),
    couleurs_array: critereArrayFromString(dessin.couleurs_string, getOeuvreCouleurs()),
})

export const getAllDessins = async ({ cursor, limit } = {}) => {
    return await apiCall("getalldessins")
}

export const getDessinById = async ({ id }) => {
    const rs = await apiCall("getdessin", "?dessin_id=" + id)
    if (rs.dessin && rs.dessin.length > 0) {
        return {
            dessin: constructDessin(rs.dessin[0])
        }
    }

    return null;
}

export const deleteDessin = async ({ dessin }) => {

    await apiCall("deletedessin", "?dessin_id=" + dessin.objectid)

}

export const createDessin = async ({ data }) => {
    const url = constructUrl("createdessin");
  
    data['elements_string'] = critereStringFromArray(data['elements_array'], getOeuvreElements())
    data['materiaux_string'] = critereStringFromArray(data['materiaux_array'], getOeuvreMateriaux())
    data['familles_string'] = critereStringFromArray(data['familles_array'], getOeuvreFamilles())
    data['couleurs_string'] = critereStringFromArray(data['couleurs_array'], getOeuvreCouleurs())
    
    var formdata = new FormData();
    formdata.append("dessin", JSON.stringify({dessinData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createdessin');
}

export const updateDessin = async ({ id, data }) => {
    const url = constructUrl("updatedessin");

    [ 'oeuvres' ].forEach(attr => {
        if (data[attr]) {
            data[attr] = data[attr].map(item => item.objectid);
        }
    })
    data['elements_string'] = critereStringFromArray(data['elements_array'], getOeuvreElements())
    data['materiaux_string'] = critereStringFromArray(data['materiaux_array'], getOeuvreMateriaux())
    data['familles_string'] = critereStringFromArray(data['familles_array'], getOeuvreFamilles())
    data['couleurs_string'] = critereStringFromArray(data['couleurs_array'], getOeuvreCouleurs())
    
    var formdata = new FormData();
    formdata.append("dessin_id", id);
    formdata.append("dessin", JSON.stringify({dessinData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updatedessin');
}


export const getAllMateriaux = () => apiCall("getallmateriaux");
export const getAllElements = () => apiCall("getallelements");
export const getAllFamilles = () => apiCall("getallfamilles");