import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom'

import {
    CircularProgress
} from '@material-ui/core'

import ConsignationLayout from './index';
import SearchNav from 'components/SearchNav'

import { getConsignationById, updateConsignation } from 'Api'
import { useHistory } from 'react-router-dom'

export default () => {

    const params = useParams();
    const id = params.id;
    const [ consignation, setConsignation ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const location = useLocation();
    const history = useHistory();

    const context = (location.state && location.state.context) || null;

    const loadData = async (id) => {

        const consignation = await getConsignationById({ id })

        setConsignation(consignation.consignation);
        setLoading(false);
    }

    useEffect(() => {
        loadData(id);
    }, [ id ])

    let content = null;

    if (loading) {
        content = <CircularProgress />
    } else if (!id || !consignation) {
        content = "NOT FOUND"
    } else {
        content = (

            <ConsignationLayout
                initialData={consignation}
                reloadData={() => loadData(id)}
                saveData={async data => {
                    await updateConsignation({ id: data.objectid, data });
                    return true;
                }}
                onSaved={() => {
                    loadData(id);
                }}
                onDelete={() => {
                    history.push("/consignation");
                }}
            />
        )
    }
    return (
        <>
            {context === 'search_result' &&
                <SearchNav
                    search_type={"consignation"}
                    constructPath={consignation => ("/consignation/" + consignation.objectid)}
                    searchPath={"/consignation/search"}
                />
            }

            {content}

        </>
    )
}