import { normalizeSync as normalizeDiacritics } from 'normalize-diacritics';


export default obj => {
    return normalize(obj)
}


function normalize(thing) {
    switch (true) {
        case thing === null:
        case typeof thing === 'number':
            return thing;
        case typeof thing === 'string':
            return normalizeString(thing);
        case (typeof thing === 'object' && typeof thing.length === 'number'):
            return normalizeArray(thing);
        case (typeof thing === 'object'):
            return normalizeObject(thing);
        default:
            return thing;
    }
}


function normalizeString(str) {
    return normalizeDiacritics(str.toLowerCase().replaceAll("-", " ").replaceAll("/", " ")).replace(/[^a-zA-Z0-9]{1}/g, " ");
}

function normalizeArray(arr) {
    return arr.map(normalize);
}

function normalizeObject(obj) {
    const newObj = {};
    Object.keys(obj).forEach(key => {
        newObj[key] = normalize(obj[key]);
    })

    return newObj
}