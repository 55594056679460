import React, { useState } from 'react';
import {
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next';

import {
    Button
} from '@material-ui/core';

import ExpoModal from 'pages/Avila/Modals/AddExpo'
import ExpoListLayout from 'pages/Avila/Expo/List/layout_subpanel'


export default ({ data, actions, onChange = () => {}, onClickEdit }) => {

    const [ addExpoModal, setAddExpoModal ] = useState(null);

    const onClickAddExpo = () => { setAddExpoModal(true); }
    const { t } = useTranslation()

    return (

        <>
            {addExpoModal &&
                <ExpoModal
                    actions={[
                        {
                            key: 'add', icon: <AddIcon />, title: "Add",
                            onClick: (id, exposition) => {
                                onChange({
                                    ...data,
                                    expositions: [
                                        ...data.expositions,
                                        exposition
                                    ]
                                })

                                setAddExpoModal(false);
                            }
                        }
                    ]}
                    filterData={data.expositions}
                    onClose={() => {
                        setAddExpoModal(false)
                    }}
                />
            }

            <ExpoListLayout
                data={data.expositions}
                headerActions={[
                    <Button key="add" onClick={onClickAddExpo} variant="contained">{t('add_expo')}</Button>
                ]}
                actions={[
                    {
                        key: 'delete', icon: <DeleteIcon />, title: "Delete",
                        onClick: (objectid, exposition) => {
                            if (window.confirm(`Supprimer ${exposition.titre} ?`)) {
                                onChange({
                                    ...data,
                                    expositions: data.expositions.filter(e => e.objectid !== exposition.objectid)
                                })
                            }
                        }
                    }
                ]}
            />
        </>
    )
}











