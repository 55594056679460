import { SET_GENRE_CONTACTS as SET_TYPE } from 'redux/constants';
import { getAllGenre_Contacts as getAll } from 'Api'

const setData = data => ({ type: SET_TYPE, data })

export const loadGenre_Contacts = () => async (dispatch, getState) => {
    const data = await getAll();

    if (data.genre_contacts) {
        return dispatch(setData(data.genre_contacts));
    }
}
