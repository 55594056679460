import React, { useEffect, useState } from 'react';

import {
    CircularProgress,
} from '@material-ui/core'

import { getAllBiblios } from 'Api';

import PageLayout from 'layouts/Helpers/PageLayout'
import Layout from './layout'


export default ({ actions }) => {

    const [ biblios, setBiblios ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getAllBiblios().then(biblios => {

            var allBiblios = biblios.biblios

            allBiblios.sort(function (a, b) {
                return a.archive - b.archive;
            });
            setBiblios(allBiblios || []);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <CircularProgress />
    }


    return (
        <PageLayout title={"Biblios/Presse"} actions={(
            <div>
            </div>
        )}>
            <Layout data={biblios} actions={actions} onChange={setBiblios} />
        </PageLayout>
    )
}