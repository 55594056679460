let list = []
for (let index = 0; index < 100; index++) {
    list.push({
        id: "expo-"+index,
        type: 'Foire',
        titre: "Expo " + index,
        year: "2020",
        lieu: "Drouot",
        ville: "Paris",
    })
}

export const getRandom = () =>
    list[Math.floor(Math.random()*list.length)]

export const search = layout => {
    return list.filter(item => {
        if (layout.title && item.title.toLowerCase().indexOf(layout.title.toLowerCase()) !== -1) {
            return true;
        }

        return false;
    })
}

export default list;