

import Moment from 'moment';


export const consignationsActives = (oeuvre) => {

     const consignations = oeuvre.consignations
     var filteredConsignations = []

     if ( consignations ) {
         consignations.forEach(consignation => {

        //    console.log("consignation : ", consignation)

            let date_debut = Moment(consignation.date_debut, "YYYY-MM-DD").format('YYYY-MM-DD')
            let date_fin = Moment(consignation.date_fin, "YYYY-MM-DD").format('YYYY-MM-DD')
            let today = Moment(Date()).format('YYYY-MM-DD') 

            if ( (today > date_debut) && (today < date_fin) ) {
                filteredConsignations.push(consignation)
            }
        })
    }
    return filteredConsignations
}

