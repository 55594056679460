import React from 'react';
import { useHistory } from 'react-router-dom'
import { createOeuvre } from 'Api'
import ArtWorkLayout from './index';

export default () => {

    const history = useHistory();

    return (
        <ArtWorkLayout
            layout="create"
            saveData={async data => {
                const result = await createOeuvre({ data });
                return result;
            }}
            onSaved={artwork => {
                history.push("/artwork/" + artwork.objectId);
            }}
        />
    )
}