import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import {
    CircularProgress
} from '@material-ui/core'

import ExpoLayout from './index';
import SearchNav from 'components/SearchNav'

import { getExpoById, updateExpo } from 'Api'

export default () => {

    const params = useParams();
    const id = params.id;
    const [ exposition, setExposition ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const location = useLocation();
    const context = (location.state && location.state.context) || null;
    const history = useHistory();


    const loadData = async (id) => {

        const exposition = await getExpoById({ id })

        setExposition(exposition.exposition);
        setLoading(false);
    }

   useEffect(() => {
        loadData(id);
    }, [ id ])

    let content = null;

    if (loading) {
        content = <CircularProgress />
    } else if (!id || !exposition) {
        content = "NOT FOUND"
    } else {
        content = (

            <ExpoLayout
                initialData={exposition}
                reloadData={() => loadData(id)}
                saveData={async data => {
                    await updateExpo({ id: data.objectid, data });
                    return true;
                }}
                onSaved={() => {
                    loadData(id);
                }}
                onDelete={() => {
                    history.push("/expo");
                }}            
            />
        )
    }
    return (
        <>
            {context === 'search_result' &&
                <SearchNav
                    search_type={"expo"}
                    constructPath={exposition => ("/expo/" + exposition.objectid)}
                    searchPath={"/expo/search"}
                />
            }

            {content}

        </>
    )

}