// @flow
import React from 'react';

import { createContact } from 'Api';
import CreateContactForm from 'pages/Avila/CreateContact/Form'

import FormModal from './FormModal'
import { useTranslation } from 'react-i18next'

type Props = {
    onClose: any => any,
    actions: Array<any>,
    data: any,
    onSave: any => any
}
export default ({
    onClose,
    actions = [],
    data,
    onSave = () => {}
}: Props) => {
    
    const { t } = useTranslation();

    return (

        <FormModal
            title={t("create_new_contact")}
            data={data}
            onClose={onClose}
            FormComponent={CreateContactForm}

            onSave={onSave}
            createMethod={({ data }) => createContact({ data })}
        />
    )
}