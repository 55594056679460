import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'

import {
    Grid,
    Paper
} from '@material-ui/core';

import Form from 'components/Form';
import Image from 'components/Img'

import ArtworksPanel from '../../../ArtWork/SubPanel/index'
import ExposPanel from '../../../Expo/SubPanel/'
import BibliosPanel from '../../../Biblio/SubPanel/'
import MediasPanel from '../../../Media/SubPanel/'
import { getEmplacements, getRangements } from "Util/PhotoRangement"

import Tabs, {
    TabsPanels as OriginalTabsPanels
} from 'components/Tabs'

import { updatePhoto } from 'Api'
import { getPhotoCriteres } from 'Util/PhotoCriteres'

const TabsPanels = styled(OriginalTabsPanels)`
    padding:20px;
    padding-top: 0px;
`

const FormContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const Box = styled(Paper)`
    padding:20px;
`

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)



export default ({ data = {},  reloadData = () => {}, onChange = () => {}, layout = 'editcreate' }) => {


    const { t } = useTranslation();
    const layoutIsSearch = layout === 'search';
    const layoutIsEdit = layout === 'edit'

    //console.log("Photo : ", data)

    const form = [

        { label: t("archive"), attr: "archive", type: "text", size: { label: 150, field:100 }},

        ...(!layoutIsSearch ? [
            [
               { label: t("annee"), attr: "annee", type: "text", size: { label: 150, field:100 }},
               { label: "Circa", attr: "circa", type: "checkbox", size: { label: 20, left:20 } },
            ]   
        ] : [
            [ { label: t("annee"), attr: "annee_min", type: "text", size: { label: 150, field:100 }},
            { label: "", attr: "annee_max", type: "text", size: { left :20, label: 0, field:100 }},]
        ]),

        { label: t("photographe"), attr: "photographe", type: "text", size: { label: 150, field:350 }},
        { label: t("copyright"), attr: "copyright", type: "text", multi:"2", size: { label: 150, field:350 }},
        
        { label: t("criteres"), attr: "criteres_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getPhotoCriteres()
            },
            size: { label: 150, field: 350 }
        },


        { label: t("identification personnes / oeuvres"), attr: "identification", type: "text", multi:"5", size: { label: 150, field:350 }},
        { label: t("localisation / exposition"), attr: "localisation", type: "text", multi:"5", size: { label: 150, field:350 }},

        { label: t("nb_exemplaire"), attr: "nb_exemplaire", type: "text", size: { label: 150, field:350 }},

        { label: t("emplacement"), attr: "emplacement", type: "autocomplete", autocomplete: {
                    type: 'free', options: getEmplacements()}
                , size: { label: 150, field:400 }
        },
        { label: t("rangement"), attr: "rangement", type: "autocomplete", autocomplete: {
                    type: 'free', options: getRangements()}
                , size: { label: 150, field:400 }
        },

        { label: t("caracteristiques_techniques"), attr: "caracteristique", type: "autocomplete", autocomplete: {
            type: 'free',
            options: [ "Tirage papier", "Diapositive", "Négatif", "Ektachrome", "Numérique", "CD"]
        }, size: { label: 150, field:350 } },
        
        { label: t("code_photo"), attr: 'code_photo', type: "text", size: { label: 150, field:350 }},
   ]

     async function updatePhotoAsync(data) {
        await updatePhoto({ id: data.objectid, data });
        reloadData(data)
    }

    let exportFilename = "Photo " + data.archive + ".jpg"

    return (

        <GridContainer>

            <Grid item xs={6}>
                <Box elevation={1} style={{position: 'relative', height: '100%'}}>
                    <FormContainer>
                        <Form form={form}  data={data} onChange={onChange} />
                    </FormContainer>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box elevation={1} style={{position: 'relative', height: '100%'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Image
                                onClickDelete={() => {
                                    if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                        onChange({
                                            ...data,
                                            filename: null
                                        })
                                    }
                                }}
                                onChange={filename => {
                                    onChange({
                                        ...data,
                                        filename
                                    })
                                }}
                                alt="test"
                                filename={data["filename"]}
                                downloadFile={exportFilename}
                                style={{width: '100%'}}
                                imgStyle={{maxWidth: '100%', maxHeight: '800px'}}
                                actionPosition='outside'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            {layoutIsEdit  && <Grid item xs={12}>
            <Tabs
                orientation={"horizontal"}
                variant="scrollable"
                initialIndex={0}
                tabs={[

                    {
                        label: t("oeuvres"),
                        panel: (
                            <TabsPanels>
                                <ArtworksPanel layout={layout} data={data} reloadData={reloadData} onChange={updatePhotoAsync} />
                            </TabsPanels>
                        )
                    },
                    {
                        label: t("expos"),
                        panel: (
                            <TabsPanels>
                                <ExposPanel layout={layout} data={data} reloadData={reloadData} onChange={updatePhotoAsync}/>
                            </TabsPanels>
                        )
                    },
                    {
                        label: t("biblio_presse"),
                        panel: (
                            <TabsPanels>
                                <BibliosPanel layout={layout} data={data} reloadData={reloadData} onChange={updatePhotoAsync}/>
                            </TabsPanels>
                        )
                    },
                    {
                        label: t("medias"),
                        panel: (
                            <TabsPanels>
                                <MediasPanel layout={layout} data={data} reloadData={reloadData} onChange={updatePhotoAsync}/>
                            </TabsPanels>
                        )
                    },
                ]}
            />
            </Grid>}

        </GridContainer>
    )
}