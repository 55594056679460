import React from 'react';
import DataTable from 'components/DataTable'

import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';

import Consignations from 'data/Consignations';
import Moment from 'moment';

const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = Consignations, actions, onChange = () => {}, onClickEdit, headerActions }) => {

    const history = useHistory();

    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}

            <DataTable
                data={data}
                config={{
                    onClickRow: (consignation, index) => {
                        history.push("/consignation/" + consignation.objectid, {
                            context: "search_result",
                            search: {
                                index
                            }
                        });
                    },
                    cells: [
                        { title: 'Emprunteur', key: 'emprunteur' },
                        { title: 'Date de début', key: 'date_debut' },
                        { title: 'Date de fin', key: 'date_fin' },
                        { title: 'Ville', key: 'ville' },
                        { title: 'Pays', key: 'pays' },
                        { title: 'Motif', key: 'motif' },
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, consignation) => {
                                window.open("/consignation/" + consignation.objectid)
                            }
                        },
                        { icon: <DeleteIcon />, title: "Delete", onClick: (id, consignation) => {
                            if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                            }
                        }}
                    ]
                }}
                getCell={(item, attr_name) => {

                    if ( attr_name === "date_debut") {
                        return Moment(item['date_debut']).format('DD-MM-YYYY')

                    } else if ( attr_name === "date_fin") {
                        return Moment(item['date_fin']).format('DD-MM-YYYY')
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>


    )
}