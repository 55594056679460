


export const globalLocalisations = (data, selectedMultiples) => {

 // console.log("globalLocalisations : ", data)
    const localisations = data.localisations;
    const expositions = data.oeuvre_expos;
    const encheres = data.encheres;

    var globalLocalisations = []

    if ( expositions ) {
        localisations.forEach( localisation => {
            localisation["type"] = "localisation"
            globalLocalisations.push(localisation)
        })
    }

    if ( expositions ) {
        expositions.forEach( exposition => {
            exposition["type"] = "exposition"
            exposition["date_localisation"] = exposition["date_debut"]
            globalLocalisations.push(exposition)
      })
    }

    if ( encheres ) {
        encheres.forEach( enchere => {
            enchere["lieu"] = enchere["source"]
          globalLocalisations.push(enchere)
      })
    }
  //   globalLocalisations = [].concat(localisations)
  //   globalLocalisations = globalLocalisations.concat(data.historiques)

  /*   const keys = Object.keys(selectedMultiples)

     keys.forEach(multiple => {

        if ( selectedMultiples[multiple] === true ) {

            localisations.forEach(localisation => {
                if ( localisation.multiple === multiple ) {
                    globalLocalisations.push(localisation)
                }
            })
        }
     })*/

  //   return localisations
     return globalLocalisations
}

