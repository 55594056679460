// @flow
import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import Modal from './BaseModal';
import { useTranslation } from 'react-i18next';

type Props = {
    title: string,
    onClose: any => any,
    data: any,
    onSave: any => any,
    createMethod: any => any,
    updateMethod: any => any,
    FormComponent: any
}
export default ({
    title,
    onClose,
    data: initialData = [],
    createMethod = () => {},
    updateMethod = () => {},
    onSave = () => {},
    FormComponent

}: Props) => {

    const [ data, setData ] = useState(initialData);
    const [ loading, setLoading ] = useState(false);
    const { t } = useTranslation();

    const onClickSave = async () => {
        setLoading(true);
        const object = (data.objectid || data.id)
            ? await updateMethod({ data })
            : await createMethod({ data });

        if ( object === false ) {
            setLoading(false);
            return;
        }

        await onSave(object);
        onClose();
    }


    return (
        <Modal
            title={title}
            data={data}
          //  onClose={onClose}
            actions={[
                <Button key="annuler" disabled={loading} onClick={onClose}>{t("cancel")}</Button>,
                <Button key="save" disabled={loading} onClick={onClickSave}>{t("save")}</Button>
            ]}
        >
            <FormComponent
                data={data}
                onChange={setData}
            />
        </Modal>
    );
}