import { apiCall, constructUrl, getResult, fetchApi as fetch } from './ApiCall';

const constructConsignation = consignation => ({
    ...consignation,

})


export const getAllConsignations = async ({ cursor, limit } = {}) => {
    return await apiCall("getallconsignations")
}

export const getConsignationById = async ({ id }) => {

    const rs = await apiCall("getconsignation", "?consignation_id=" + id)
    if (rs.consignation && rs.consignation.length > 0) {

        return {
            consignation: constructConsignation(rs.consignation[0])
        }
    }
    console.log("getConsignationById return null")

    return null;
}


export const deleteConsignation = async ({ consignation }) => {

    await apiCall("deleteconsignation", "?consignation_id=" + consignation.objectid)

}

export const createConsignation = async ({ data }) => {
    const url = constructUrl("createconsignation");

    var formdata = new FormData();
    formdata.append("consignation", JSON.stringify({consignationData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createconsignation');
}

export const updateConsignation = async ({ id, data }) => {

    const url = constructUrl("updateconsignation");

    [ 'oeuvres', 'oeuvre_consignes' ].forEach(attr => {
        if (data[attr]) {
            data[attr] = data[attr].map(item => item.objectid);
        }
    })

    var formdata = new FormData();
    formdata.append("consignation_id", id);
    formdata.append("consignation", JSON.stringify({consignationData: data}));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updateconsignation');
}

//
//
//

export const createOeuvreConsigne = async ({ consignationId, data }) => {
    
    const url = constructUrl("createoeuvreconsigne");
    
    var formdata = new FormData();

    data["consignations"] = [consignationId]

    formdata.append("oeuvre_consigne", JSON.stringify({ oeuvre_consigneData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'createoeuvreconsigne');
}


export const updateOeuvreConsigne = async ({consignationId, data }) => {
    const url = constructUrl("updateoeuvreconsigne");

    var formdata = new FormData();
    formdata.append("oeuvre_consigne_id", data.objectid);

    data["consignations"] = [consignationId]
    delete data['objectid']
    formdata.append("oeuvre_consigne", JSON.stringify({ oeuvre_consigneData: data }));

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    const responseJson = await response.json();

    return getResult(responseJson, 'updateoeuvreconsigne');
}
