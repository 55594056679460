// @flow
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ExportListSelector from 'components/Selectors/ExportList'

import {
    Grid,
    Button, CircularProgress
} from '@material-ui/core'

import {
    Edit as EditIcon
} from '@material-ui/icons'

import SearchOeuvre from 'Api/Search/oeuvre';

import {
    FlexRowSpaceBetween
} from 'layouts/Helpers/flexbox'
import {
    Title, Divider,
    Container
} from 'layouts/Helpers/page'

import { useHistory } from 'react-router-dom';
import { useSearch } from 'redux/hooks';

const ListLayout = () => (<div/>)
const DescriptionPanel = () => (<div/>)

export const ExamplePage = () => {

    const history = useHistory();

    return (
        <SearchLayout
            objectType='artwork'
            title={"Rechercher une oeuvre"}
            search={data => SearchOeuvre({ oeuvre: data })}
            SearchFormComponent={({ data, onChange }) => (
                <DescriptionPanel layout="search" data={data} onChange={onChange} />
            )}
            ResultListComponent={({ data }) => (
                <ListLayout
                    data={data}
                    actions={[
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, artwork, index) => {
                                history.push("/artwork/" + artwork.objectid, {
                                    context: "search_result",
                                    search: {
                                        index
                                    }
                                });
                            }
                        }
                    ]}
                />
            )}

            onFeelingLucky={oeuvre => history.push("/artwork/"+oeuvre.objectid)}
        />
    )
}



const SearchLayout = ({
    objectType,
    search,
    title,
    SearchFormComponent,
    ResultListComponent,
    onFeelingLucky,
    onClickExport,
    exportList,
    onChange,

}: {
    objectType: string,
    search: ({ data: any }) => Promise<{ data: Array<any> } | { error: any }>,
    title: string,
    SearchFormComponent: any,
    ResultListComponent: any,
    onFeelingLucky: Object => any,
    onClickExport: any => any,
}) => {

    const [ loading, setLoading ] = useState(false);
    const { t } = useTranslation();
    const [ searchObject, setSearchForm, setSearchResults ] = useSearch(objectType);
    const [ selectedList, setSelectedList] = useState(null)

    const _clean = () => {
        
        setSearchForm({})

    }

    const _search = async () => {

        if (!searchObject.form) {
            window.alert("Entrez un critère");
            return;
        }

        setLoading(true);

        const searchResult = await search({ data: searchObject.form })

        if (searchResult.error) {
            window.alert(searchResult.error);
            setLoading(false);
            return;
        }

        const result = searchResult.data.filter(o => !!o.objectid);

        setLoading(false);

        if (result.length === 1) {
            onFeelingLucky(result[0]);
            return;
        }

        setSearchResults(result);
    }

    if (searchObject && searchObject.results) {
        return (
            <React.Fragment>
                <Helmet title={"Resultats"} />

                <FlexRowSpaceBetween>

                    <Button
                        variant="contained" color="primary"
                        onClick={() => {setSearchResults(null)}}
                    >{t("back").toUpperCase()}</Button>

                    <div>
                    {exportList && <ExportListSelector
                        style={{ width: '200px', marginRight:'50px' }}
                        size="small"
                        variant='outlined'
                        list={exportList}
                        value={selectedList}
                        onChange={setSelectedList}
                     />}

                    {onClickExport && <Button
                        variant="contained" color="primary"
                        onClick={() => onClickExport(selectedList, searchObject.results)}
                    >{t("export").toUpperCase()}</Button>}
                    </div>

                </FlexRowSpaceBetween>

                <Divider my={6} />

                <Title>
                    {searchObject.results.length} {t('results')}
                </Title>

                <Grid container spacing={2} style={{ width: '100%' }}>
                    <Grid item xs={12}>
                        <Container>
                            <ResultListComponent
                                data={searchObject.results}
                            />
                        </Container>
                    </Grid>
                </Grid>

            </React.Fragment>

        )
    }

    return (
        <React.Fragment>
            <Helmet title={title} />

                <div>
                    <Button
                        variant="contained" color="primary" style={{marginRight:'20px'}}
                        onClick={ _clean }
                    >{"RESET"}</Button>
                    <Button
                        variant="contained" color="primary" style={{marginRight:'50px'}}
                        onClick={_search}
                    >{t("search").toUpperCase()}</Button>

                <Title style={{verticalAlign:'middle'}}>
                    {title}
                </Title>
            </div>
            <Divider my={6} />

            <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12}>
                    <Container>
                        {!loading &&
                            <SearchFormComponent data={(searchObject && searchObject.form) || {}} onChange={setSearchForm} />
                        }
                        {loading && <CircularProgress />}
                    </Container>
                </Grid>
            </Grid>

        </React.Fragment>

    )
}

export default SearchLayout;