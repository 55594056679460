



export const filterHistoriques = (historiques, selectedMultiples) => {

     var filteredHistoriques = []
     const keys = Object.keys(selectedMultiples)

     keys.forEach(multiple => {

        if ( selectedMultiples[multiple] === true ) {

            historiques.forEach(historique => {
                if ( historique.multiple === multiple ) {
                    filteredHistoriques.push(historique)
                }
            })
        }
     })

     return filteredHistoriques
}

