import React, { useState } from 'react';

import PageLayout from 'layouts/Helpers/PageLayout'

import {
    Button,
    CircularProgress
} from '@material-ui/core';

import { getAllOeuvres, getFullOeuvres, getOeuvreById, updateOeuvre, updateMultiple } from 'Api';

import { displayOeuvreLastLocalisation } from 'Util/OeuvreLocalisation';
import { displayOeuvreLastProprio } from 'Util/OeuvreProprietaire';
import { calcMultipleDatas } from 'Util/OeuvreMultiple';
import { getSurfaceDiameter, getSurfaceRect } from 'Util/Oeuvre';
import { isOeuvreMultiple } from 'Util/Oeuvre'

const Layout = ({ initialData, reloadData = () => {}, onSaved = async () => {}, saveData = async () => {}, layout = 'edit' }: Props) => {

    const [ loading, setLoading ] = useState(false);

    function checkOeuvres() {
        setLoading(true);
        getAllOeuvres().then(artworks => {
            var oeuvres = artworks.oeuvres

            oeuvres.sort(function (a, b) {
                return a.archive - b.archive;
            });

            processOeuvres(oeuvres)

            setLoading(false);
        })

    }

    const processOeuvres  = async (oeuvres) => {
        
        console.log("start all")

        const promises = oeuvres.map(async (oeuvre, i) => {
            
       //     console.log("start oeuvre : ", oeuvre.archive)
            const id =  oeuvre.objectid
            const oeuvreFetch = await getOeuvreById({id})
            var data = oeuvreFetch.oeuvre

            if ( isOeuvreMultiple(data) ) {
                data.dernier_proprietaire = ""
                data.derniere_localisation = ""

            } else {
                data.dernier_proprietaire = displayOeuvreLastProprio(data)
                data.derniere_localisation = displayOeuvreLastLocalisation(data)
            }
          /*  if ( data.archive == 197 ) {
                console.log("197 : ", data.derniere_localisation)
            } */
            data.surface = data.diametre ? getSurfaceDiameter(data) : getSurfaceRect(data)
          //  console.log("surface : ", data.surface)

            await updateOeuvre({ id: data.objectid, data });

            console.log("finish oeuvre : ", data.archive)

        })
        console.log("wait for promise all")

        await Promise.all(promises)
        console.log("finished all")
    }

    function checkMultiples() {
        setLoading(true);
        getFullOeuvres().then(artworks => {
            var oeuvres = artworks.oeuvres

            oeuvres.sort(function (a, b) {
                return a.archive - b.archive;
            });


            processMultiples(oeuvres)

            setLoading(false);
        })

    }

    const processMultiples = async (oeuvres) => {
        
        console.log("start")
        var allMultiples = []

        oeuvres.forEach( oeuvre => {
            console.log("oeuvre : ", oeuvre.archive)

            if ( oeuvre.multiples.length > 0 ) {
                calcMultipleDatas(oeuvre)

                oeuvre.multiples.forEach( multiple => {
                    multiple.oeuvre_id = oeuvre.objectid
                    allMultiples.push(multiple)
                })
            }
        })

        const promises = allMultiples.map(async (multiple, i) => {
            
            await updateMultiple({ oeuvreid: multiple.oeuvre_id, data:multiple });

            console.log("finish : ", multiple.objectid)

        })
        console.log("wait for promise all")

        await Promise.all(promises)
        console.log("finished")
    }


    if (loading) {
        return <CircularProgress />
    }


    return (

        <PageLayout title="Maintenance">

            <div>
            <Button key="add" onClick={() => checkOeuvres()} variant="contained">Dernier proprio / Dernière loc / Surface</Button>
            <Button key="multiple" onClick={() => checkMultiples()} variant="contained">Multiples</Button>
            </div>
        </PageLayout>

    )
}


export default ({ initialData, ...props } : Props) => {

    const key = initialData ? initialData.objectid : "create";
    return (
        <Layout {...props} initialData={initialData} key={key} />
    )
}